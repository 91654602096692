import React, { useMemo, useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { connect } from "react-redux";
import Codex from "@blumira/blu_constants";
import Schema from "lib/schema";
import {
  useQueryControls,
  useResultControls,
  SaveRequestModal,
  decodeKey,
} from "views/Components/QueryControls";
import PropTypes from "prop-types";

import DataSourcePicker from "views/Components/Reporting/DataSourcePicker";
import FilterEditor from "views/Components/Reporting/FilterEditor";
import OutputFieldsEditor from "views/Components/Reporting/OutputFieldsEditor";
import SavedQueryModal from "views/Components/Reporting/SavedQueryModal";
import DataTable from "views/Components/DataTable";
import { TimeControls } from "views/Components/TimespanPicker";
import LicenseRestriction from "views/Components/License";

import {
  Card,
  Grid,
  Menu,
  Select,
  Switch,
  Button,
  Tooltip,
  Collapse,
  MenuItem,
  FormGroup,
  Typography,
  IconButton,
  FormControl,
  CardContent,
  ButtonGroup,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import UpdateOutlinedIcon from "@mui/icons-material/UpdateOutlined";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import CloseIcon from "@mui/icons-material/Close";

import Alert from "@mui/material/Alert";

// ** Redux ***********************
import { loadPageData } from "redux/actions/Request";
import { updateUser } from "redux/actions/Users";

import _ from "lodash";
import Request from "../../../lib/api/Request";
import { useDeepCompareEffect } from "react-use";
import { getValue } from "utils";

import { copyTextToClipboard } from "utils";
import { isDateTimeField } from "views/Components/Reporting/FilterField";

const PREFIX = "ReportBuilder";
const classes = {
  formControl: `${PREFIX}-formControl`,
  formControlContainer: `${PREFIX}-formControlContainer`,
  outlinedButton: `${PREFIX}-outlinedButton`,
  card: `${PREFIX}-card`,
  dFlex: `${PREFIX}-dFlex`,
  dataSource: `${PREFIX}-dataSource`,
  rowContainer: `${PREFIX}-rowContainer`,
  rowButtonContainer: `${PREFIX}-rowButtonContainer`,
  formControlLabel: `${PREFIX}-formControlLabel`,
  marginRight: `${PREFIX}-marginRight`,
  fieldsMultiSelectAlert: `${PREFIX}-fieldsMultiSelectAlert`,
  exportAlert: `${PREFIX}-exportAlert`,
  disabledMenuItem: `${PREFIX}-disabledMenuItem`,
  tooltip: `${PREFIX}-tooltip`,
  savedReportsGrid: `${PREFIX}-savedReportsGrid`,
  viewAllSavedReportsBtn: `${PREFIX}-viewAllSavedReportsBtn`,
  recentAndSavedButtonRowContainer: `${PREFIX}-recentAndSavedButtonRowContainer`,
  recentAndFavoriteReportButton: `${PREFIX}-recentAndFavoriteReportButton`,
  selectIcon: `${PREFIX}-selectIcon`,
  savedReportNameText: `${PREFIX}-savedReportNameText`,
  favoriteSelectPaper: `${PREFIX}-favoriteSelectPaper`,
};

const menuClasses = {
  favoriteListItem: `${PREFIX}-favoriteListItem`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.formControlContainer}`]: {
    width: "100%",
  },
  [`& .${classes.formControl}`]: {
    padding: 0,
    marginRight: theme.shape.padding,
  },
  [`& .${classes.outlinedButton}`]: {
    width: 200,
    borderWidth: 2,
    fontWeight: "bold",
    textTransform: "none",
    marginRight: theme.shape.padding,
  },
  [`& .${classes.card}`]: {
    marginBottom: theme.spacing(2),
  },
  [`& .${classes.dFlex}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.shape.padding,
  },
  [`& .${classes.dataSource}`]: {
    "& input": {
      height: 28,
    },
  },
  [`& .${classes.rowContainer}`]: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.shape.padding,
    marginBottom: theme.shape.padding * 2,
  },
  [`& .${classes.rowButtonContainer}`]: {
    display: "flex",
    marginRight: theme.shape.padding,
  },
  [`& .${classes.formControlLabel}`]: {
    marginBottom: 0,
  },
  [`& .${classes.marginRight}`]: {
    marginRight: theme.shape.padding,
  },
  [`& .${classes.fieldsMultiSelectAlert}`]: {
    marginTop: theme.spacing(2),
  },
  [`& .${classes.exportAlert}`]: {
    marginBottom: theme.shape.padding,
  },
  [`& .${classes.disabledMenuItem}`]: {
    color: theme.palette.text.secondary,
  },
  [`& .${classes.tooltip}`]: {
    boxShadow: theme.shadows[4],
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
  },
  [`& .${classes.savedReportsGrid}`]: {
    display: "flex",
    marginBottom: theme.shape.padding,
  },
  [`& .${classes.viewAllSavedReportsBtn}`]: {
    width: "60%",
    height: "100%",
    fontWeight: "bold",
    color: theme.palette.text.primary,
  },
  [`& .${classes.recentAndSavedButtonRowContainer}`]: {
    marginBottom: theme.shape.padding,
  },
  [`& .${classes.selectIcon}`]: {
    marginRight: 10,
  },
  [`& .${classes.savedReportNameText}`]: {
    marginBottom: theme.spacing(2),
  },
  [`& .${classes.recentAndFavoriteReportButton}`]: {
    width: "100%",
    "& .MuiSelect-select": {
      padding: "10px 32px 10px 15px",
      backgroundColor: theme.palette.background.paper,
    },
    "& .MuiSelect-icon": {
      color: theme.palette.text.primary,
    },
  },
  [`& .${classes.favoriteSelectPaper}`]: {
    maxHeight: 600,
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  [`& .${menuClasses.favoriteListItem}`]: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  "& p": {
    marginBottom: 0,
  },
}));

const generateLogTypesObject = (orgLogTypes, allLogTypes) => {
  const orgLogTypesList = orgLogTypes.map((obj) => obj.name);
  const newLogTypesObj = {};
  for (let [key, value] of Object.entries(allLogTypes)) {
    if (orgLogTypesList.includes(key)) {
      newLogTypesObj[key] = value;
    }
  }
  return newLogTypesObj;
};

const isThereDataSourcesSelected = (queryControls) =>
  Object.values(queryControls).find((q) => q.field === "type") &&
  Object.values(queryControls).find((q) => q.field === "type").value.length > 0;

const isThereActiveQueryData = (activeQuery) =>
  activeQuery && activeQuery.length > 0;

const getNumberOfDataSources = (queryControls) =>
  Object.values(queryControls).find((q) => q.field === "type")
    ? Object.values(queryControls).find((q) => q.field === "type").value.length
    : 0;

export const populateAvailableFieldsList = (typeControl, logTypes) => {
  if (_.isNil(logTypes)) {
    return [];
  }
  if (_.isNil(typeControl) || typeControl.value.length === 0) {
    return _.flow(
      _.toPairs,
      (arr) => _.map(arr, (obj) => _.get(obj, ["1", "all_columns"], [])),
      _.flatten,
      _.uniq,
      (arr) => _.orderBy(arr, (elem) => elem.toLowerCase())
    )(logTypes);
  }

  return _(typeControl.value)
    .filter((obj) => obj in logTypes)
    .map((obj) => logTypes[obj].all_columns)
    .flatten()
    .uniq()
    .value();
};

const getColumnValue = (params, path) => _.get(params, path, []).join();

export const arrayReportColumnsMap = {
  additional_fields: {
    valueGetter: (params) =>
      getColumnValue(params, ["row", "additional_fields"]),
  },
  agent_name: {
    valueGetter: (params) => getColumnValue(params, ["row", "agent_name"]),
  },
  alliance_info: {
    valueGetter: (params) => getColumnValue(params, ["row", "alliance_info"]),
  },
  class: { valueGetter: (params) => getColumnValue(params, ["row", "class"]) },
  dns_ip: {
    valueGetter: (params) => getColumnValue(params, ["row", "dns_ip"]),
  },
  dns_ip_ipv4: {
    valueGetter: (params) => getColumnValue(params, ["row", "dns_ip_ipv4"]),
  },
  dns_ip_ipv6: {
    valueGetter: (params) => getColumnValue(params, ["row", "dns_ip_ipv6"]),
  },
  event_tags: {
    valueGetter: (params) => getColumnValue(params, ["row", "event_tags"]),
  },
  groups: {
    valueGetter: (params) => getColumnValue(params, ["row", "groups"]),
  },
  info: { valueGetter: (params) => getColumnValue(params, ["row", "info"]) },
  ip: { valueGetter: (params) => getColumnValue(params, ["row", "ip"]) },
  ip_flags: {
    valueGetter: (params) => getColumnValue(params, ["row", "ip_flags"]),
  },
  ip_ipv4: {
    valueGetter: (params) => getColumnValue(params, ["row", "ip_ipv4"]),
  },
  ip_ipv6: {
    valueGetter: (params) => getColumnValue(params, ["row", "ip_ipv6"]),
  },
  logged_on_user: {
    valueGetter: (params) => getColumnValue(params, ["row", "logged_on_user"]),
  },
  mac: { valueGetter: (params) => getColumnValue(params, ["row", "mac"]) },
  parameter: {
    valueGetter: (params) => getColumnValue(params, ["row", "parameter"]),
  },
  parts: { valueGetter: (params) => getColumnValue(params, ["row", "parts"]) },
  privileges: {
    valueGetter: (params) => getColumnValue(params, ["row", "privileges"]),
  },
  recipients: {
    valueGetter: (params) => getColumnValue(params, ["row", "recipients"]),
  },
  ref: { valueGetter: (params) => getColumnValue(params, ["row", "ref"]) },
  registry: {
    valueGetter: (params) => getColumnValue(params, ["row", "registry"]),
  },
  service_principal_names: {
    valueGetter: (params) =>
      getColumnValue(params, ["row", "service_principal_names"]),
  },
  sha256: {
    valueGetter: (params) => getColumnValue(params, ["row", "sha256"]),
  },
  targets: {
    valueGetter: (params) => getColumnValue(params, ["row", "targets"]),
  },
  tcp_flags: {
    valueGetter: (params) => getColumnValue(params, ["row", "tcp_flags"]),
  },
  threatInfo_indicators_applicationName: {
    valueGetter: (params) =>
      getColumnValue(params, ["row", "threatInfo_indicators_applicationName"]),
  },
  threatInfo_indicators_indicatorName: {
    valueGetter: (params) =>
      getColumnValue(params, ["row", "threatInfo_indicators_indicatorName"]),
  },
  threatInfo_indicators_sha256Hash: {
    valueGetter: (params) =>
      getColumnValue(params, ["row", "threatInfo_indicators_sha256Hash"]),
  },
  threats: {
    valueGetter: (params) => getColumnValue(params, ["row", "threats"]),
  },
  unknown_field: {
    valueGetter: (params) => getColumnValue(params, ["row", "unknown_field"]),
  },
  user_account_control: {
    valueGetter: (params) =>
      getColumnValue(params, ["row", "user_account_control"]),
  },
  zone: { valueGetter: (params) => getColumnValue(params, ["row", "zone"]) },
};
export const getColumns = (arrayReportColumnsMap = {}, resultControls) => {
  if (!("outputFields" in resultControls)) {
    return [];
  }

  return resultControls.outputFields.map((c) => {
    const baseColumnAttributes = {
      title: c,
      field: c,
      searchable: true,
      minWidth: 150,
    };
    if (c in arrayReportColumnsMap) {
      return { ...baseColumnAttributes, ...arrayReportColumnsMap[c] };
    }
    return baseColumnAttributes;
  });
};

export const filterColumns = (activeQuery = [], columns = []) =>
  activeQuery.length > 0
    ? columns.filter((column) =>
        activeQuery.some((obj) => !_.isNil(obj[column.field]))
      )
    : columns;

const populateOutputFieldsList = (
  firstElement,
  typeControl,
  logTypes,
  availableFieldsList,
  extraOutputFieldsList
) => {
  if (_.isNil(typeControl) || _.isNil(logTypes)) {
    return [];
  }

  let arr = _(typeControl.value)
    .filter((obj) => obj in logTypes)
    .map((obj) => logTypes[obj].core_columns)
    .flatten()
    // .push(...extraOutputFieldsList)
    .uniq()
    .filter((obj) => availableFieldsList.includes(obj))
    .value();

  if (arr.includes(firstElement)) {
    arr = arr.filter((elem) => elem !== firstElement);
  }

  return [firstElement, ...arr];
};

export const sortColumns = (sortByList, columnsList) => {
  if (Array.isArray(sortByList) && sortByList.length === 0) {
    return columnsList;
  }
  const differenceColumnsList = _.difference(
    columnsList.map((obj) => obj.field),
    sortByList
  );
  const newColumnsObj = _(differenceColumnsList)
    .groupBy((str) => str)
    .value();
  const [newColumnsList, oldColumnsList] = _(columnsList)
    .partition((obj) => obj.field in newColumnsObj)
    .value();

  return _(oldColumnsList)
    .sortBy((obj) => sortByList.indexOf(obj.field))
    .value()
    .concat(newColumnsList);
};

const Search = (props) => {
  const [isExporting, setIsExporting] = useState(false);
  const [delta, setDelta] = useState("custom");
  const [selectedModel, setSelectedModel] = useState(false);
  const [queryControls, queryControlInterface] =
    useQueryControls("activeQuery");
  const [resultControls, resultControlInterface] =
    useResultControls("activeQuery");
  const [addSuggestedFields, setAddSuggestedFields] = useState(true);
  const [saveOpen, setSaveOpen] = useState(false);
  const [showSaved, setShowSaved] = useState(false);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [formError, setFormError] = useState(false); // eslint-disable-line no-unused-vars
  const [anchorEl, setAnchorEl] = useState(false);
  const [extraOutputFieldsList, setExtraOutputFieldsList] = useState([]);
  const [logTypes, setLogTypes] = useState({});
  const [activeQuery, setActiveQuery] = useState([]);
  const [numberOfDataSources, setDataSourceNumber] = useState(0);

  const [errorMessage, setErrorMessage] = useState("");
  const [isAlertVisible, setIsAlertVisible] = useState(true);
  const [availableFieldsList, setAvailableFieldsList] = useState([]);
  const [columnOrderList, setColumnOrderList] = useState([]);
  const [savedQueries, setSavedQueries] = useState([]);
  const [recentReports, setRecentReports] = useState([]);
  const [favoriteReports, setFavoriteReports] = useState([]);
  const [isRemovingFromFavorites, setIsRemovingFromFavorites] = useState(null);

  const availableFieldsObj = Schema.getSchema("/bq").schema.properties;
  const typeControl = Object.values(queryControls).find(
    (control) => control.field === "type" && control.operator === "in"
  );

  useEffect(() => {
    const newNumberofDataSources = getNumberOfDataSources(queryControls);
    setDataSourceNumber(newNumberofDataSources);

    // props.query is populated on click through actions
    // do not reset the active query in this instance
    if (!props.query && numberOfDataSources !== newNumberofDataSources) {
      setActiveQuery([]);
    } else {
      setActiveQuery(props.activeQuery);
    }
  }, [queryControls, props.activeQuery]);

  useEffect(() => {
    if (props.ready && props.savedQueries) {
      setSavedQueries(props.savedQueries);
    }
  }, [props.ready, props.savedQueries]);

  useEffect(() => {
    const getRecentReports = () => {
      // Check if savedQueries exists, has items, and recent_report_ids is available in user's config
      if (
        savedQueries &&
        savedQueries.length > 0 &&
        props.currentUser.config_user?.recent_report_ids
      ) {
        const recentReportIds = props.currentUser.config_user.recent_report_ids;

        // Map over recentReportIds to find the corresponding query objects from savedQueries
        const recentReports = recentReportIds.map((reportId) =>
          savedQueries.find((query) => query.id === reportId)
        );

        // Filter out any undefined values from the recentReports array
        // The filter(Boolean) method removes any falsy values (including undefined)
        // This ensures that only valid query objects are included in the final array
        const filteredRecentReports = recentReports.filter(Boolean);

        return filteredRecentReports;
      }

      // If the conditions are not met or there are no matching reports, return an empty array
      return [];
    };

    // Get the recent reports based on the user's recent_report_ids
    const recentReports = getRecentReports();

    // Update the recentReports state variable with the retrieved recent reports
    setRecentReports(recentReports);

    // Re-run this effect whenever savedQueries or recent_report_ids change
  }, [savedQueries, props.currentUser.config_user?.recent_report_ids]);

  useEffect(() => {
    const getFavoriteReports = () => {
      // Check if savedQueries exists, has items, and saved_report_ids is available in user's config
      if (
        savedQueries &&
        savedQueries.length > 0 &&
        props.currentUser.config_user?.saved_report_ids
      ) {
        const savedReportIds = props.currentUser.config_user.saved_report_ids;

        // Map over savedReportIds to find the corresponding query objects from savedQueries
        const savedReports = savedReportIds.map((reportId) =>
          savedQueries.find((query) => query.id === reportId)
        );

        // Filter out any undefined values from the savedReports array
        // The filter(Boolean) method removes any falsy values (including undefined)
        // This ensures that only valid query objects are included in the final array
        const filteredSavedReports = savedReports.filter(Boolean);

        return filteredSavedReports;
      }

      // If the conditions are not met or there are no matching reports, return an empty array
      return [];
    };

    // Get the favorite reports based on the user's saved_report_ids
    const favoriteReports = getFavoriteReports();

    // Update the favoriteReports state variable with the retrieved favorite reports
    setFavoriteReports(favoriteReports);

    // Re-run this effect whenever savedQueries or saved_report_ids change
  }, [savedQueries, props.currentUser.config_user?.saved_report_ids]);

  useEffect(() => {
    const matchedQuery = savedQueries.find(
      (q) => q.id === Number(props.queryID)
    );
    if (matchedQuery) {
      if (!selectedModel || selectedModel.id !== matchedQuery.id) {
        setAddSuggestedFields(false);
        setSelectedModel(matchedQuery);
      }
    }
  }, [props.queryID, savedQueries]);

  useEffect(() => {
    if (props.orgLogTypes) {
      const orgLogTypes = generateLogTypesObject(
        props.orgLogTypes,
        props.logTypes
      );
      setLogTypes(orgLogTypes);
    }
  }, [props.orgLogTypes]);

  useEffect(() => {
    if (selectedModel) {
      const updatedUUIDs = [];
      const toAddQPs = [];
      const loadingQueryParams = [
        ...selectedModel.queryParams.filter((qp) => !Array.isArray(qp)),
        { field: "query.id", value: selectedModel.id },
      ];
      const loadingOrQueryParams = [
        ...selectedModel.queryParams.filter((qp) => Array.isArray(qp)),
      ];
      const overloadFields = [];

      if (selectedModel.id === Number(props.queryID) && props.query) {
        Object.keys(props.query).forEach((k) => {
          const qp = { ...decodeKey(k), value: props.query[k] };
          overloadFields.push(qp.field);
          toAddQPs.push(qp);
        });
      }

      loadingQueryParams.forEach((selectedQP) => {
        var matched = false;
        Object.keys(queryControls).forEach((qcUUID) => {
          if (
            queryControls[qcUUID].field === selectedQP.field &&
            queryControls[qcUUID].operator === selectedQP.operator &&
            !!queryControls[qcUUID].negate === !!selectedQP.negate
          ) {
            queryControlInterface.update(qcUUID, { value: selectedQP.value });
            matched = qcUUID;
          }
        });
        if (matched) {
          updatedUUIDs.push(matched);
        } else if (!overloadFields.includes(selectedQP.field)) {
          toAddQPs.push(selectedQP);
        }
      });

      Object.keys(queryControls)
        .filter(
          (qcUUID) =>
            !updatedUUIDs.includes(qcUUID) ||
            overloadFields.includes(queryControls[qcUUID].field)
        )
        .map((qcUUID) => queryControlInterface.remove(qcUUID));
      Object.keys(resultControls).map((resultType) =>
        resultControlInterface.remove(resultType)
      );
      // add values from selectedModel
      toAddQPs.map((qp) => queryControlInterface.add(qp));
      loadingOrQueryParams.forEach((orParam) => {
        queryControlInterface.add(orParam);
      });
      Object.keys(selectedModel.resultParams).map((resultName) =>
        resultControlInterface.update(
          resultName,
          selectedModel.resultParams[resultName]
        )
      );

      props.reload();
    }
  }, [selectedModel, props.query, props.queryID]);

  useEffect(() => {
    if (props.globalError) {
      setSelectedModel(false);
      setActiveQuery([]);
    }
  }, [props.globalError]);

  useDeepCompareEffect(() => {
    const availableFieldsList = populateAvailableFieldsList(
      typeControl,
      logTypes
    );
    setAvailableFieldsList(availableFieldsList);
    if (typeControl && addSuggestedFields && props.ready) {
      const firstElem = !isDistinct() ? "id" : "grouped_count";
      const outputFields = populateOutputFieldsList(
        firstElem,
        typeControl,
        logTypes,
        availableFieldsList,
        extraOutputFieldsList
      );
      resultControlInterface.update("outputFields", outputFields);
    }
  }, [typeControl, props.orgLogTypes, addSuggestedFields]);

  const handleAddExtraOutputField = (currentOutputFields) => {
    setExtraOutputFieldsList(
      _.difference(currentOutputFields, resultControls.outputFields)
    );
  };
  const handleRestore = () => {
    setDelta("custom");
    setSelectedModel(false);
    queryControlInterface.restore();
    resultControlInterface.restore();

    const outputFields = populateOutputFieldsList(
      "id",
      typeControl,
      logTypes,
      availableFieldsList,
      extraOutputFieldsList
    );

    resultControlInterface.update("outputFields", outputFields);
  };

  // this function handles running a saved or global report
  const handleSelectModel = (model) => {
    setAddSuggestedFields(false);
    setSelectedModel(model);

    if (model && model.id) {
      const currentRecentReportIds =
        props.currentUser.config_user?.recent_report_ids || [];

      // Remove the selected model's ID from its previous position
      const updatedRecentReportIds = currentRecentReportIds.filter(
        (id) => id !== model.id
      );

      // Add the selected model's ID to the front of the array
      updatedRecentReportIds.unshift(model.id);

      // Limit the recent_report_ids array to a maximum of 10 items
      if (updatedRecentReportIds.length > 10) {
        updatedRecentReportIds.pop();
      }

      const dataToUpdate = {
        config_user: {
          ...props.currentUser.config_user,
          recent_report_ids: updatedRecentReportIds,
        },
      };

      props.editUser(props.orgId, props.currentUser.id, dataToUpdate, true);
    }
  };

  const handleSelectAllTypes = () => {
    const typeControl = Object.values(queryControls).find(
      (control) => control.field === "type" && control.operator === "in"
    );
    const value = Object.keys(logTypes).filter(
      (logType) => props.language[logType]
    );
    if (typeControl) {
      setAddSuggestedFields(false);
      queryControlInterface.update(typeControl.uuid, { value });
    }
  };

  const handleSubmit = () => {
    props.reload("activeQuery");
  };

  const onSave = (queryID) => {
    setSaveOpen(false);
    props.reload(true);
  };

  const checkMenuDisabled = (contextMenu) => {
    if (isDateTimeField(availableFieldsObj[contextMenu.fieldName])) {
      return true;
    }
    if (
      contextMenu.fieldName &&
      resultControls.outputFields?.find(
        (field) => field === contextMenu.fieldName
      )
    ) {
      return false;
    }
    return true;
  };

  // Context Menu Functions
  const menuCopy = (contextMenu) => {
    copyTextToClipboard(contextMenu.model[contextMenu.fieldName]);
  };

  const menuFilterEq = (contextMenu) => {
    queryControlInterface.add({
      field: contextMenu.fieldName,
      value: getValue(null, contextMenu.model[contextMenu.fieldName]),
      operator: "eq",
      negate: false,
    });
  };

  const menuFilterNeq = (contextMenu) => {
    queryControlInterface.add({
      field: contextMenu.fieldName,
      value: getValue(null, contextMenu.model[contextMenu.fieldName]),
      operator: "eq",
      negate: true,
    });
  };

  /*
    description: The type of output that the report processing will produce; "direct" ==
      list of output objects in data field of response. Else, "jsonl", "csv",
      "xlsx", which produce signed URL to output file of given type.
  */
  const onExport = async (downloadType) => {
    const transform = !resultControls.transform
      ? []
      : resultControls.transform.map((obj) => {
          delete obj.uuid;
          delete obj.boolParam;
          return obj;
        });
    const queryParams = Object.values(queryControls).map((obj) => obj);
    const request = new Request("/bq", queryParams, {
      ...resultControls,
      transform,
      outputType: downloadType,
    });

    // set state var to disable export options
    // and display loading indicator in UI
    setIsExporting(true);

    // async to prohibit multiple export clicks
    // while we work on exporting
    request
      .get()
      .then(() => setIsExporting(false))
      .catch((err) => {
        setIsExporting(false);
        handleError("We had an issue exporting your report.");
      });
  };

  // These functions are to support the legacy UI "distint" action
  // it will be replaced by a robust result UI in the future
  const isDistinct = () => {
    return resultControls.transform?.find((t) => t.operator === "count");
  };

  const handleDistinct = () => {
    const control = isDistinct();
    const existingOrderBy = _.get(resultControls, ["orderBy"], []);
    const existingOutputFields = _.get(resultControls, ["outputFields"], []);
    if (control) {
      const updated = resultControls.transform
        .filter((t) => t.uuid !== control.uuid)
        .map((t) => {
          return {
            field: t.field,
            operator: t.operator,
            negate: t.negate,
            value: t.value,
          };
        });
      resultControlInterface.update("transform", updated);
      resultControlInterface.update("outputFields", [
        "id",
        "Timestamp",
        ...existingOutputFields.filter((obj) => obj !== "grouped_count"),
      ]);
      resultControlInterface.update("orderBy", [
        ...existingOrderBy.filter((obj) => obj.key !== "grouped_count"),
      ]);
    } else {
      let transformToMap = resultControls?.transform?.length
        ? resultControls.transform
        : [];
      const updated = transformToMap.map((t) => {
        return {
          field: t.field,
          operator: t.operator,
          negate: t.negate,
          value: t.value,
        };
      });
      updated.push({ operator: "count", value: "grouped_count" });
      resultControlInterface.update("transform", updated);
      resultControlInterface.update("outputFields", [
        "grouped_count",
        ...existingOutputFields.filter(
          (obj) => obj !== "id" && obj !== "Timestamp"
        ),
      ]);
      resultControlInterface.update("orderBy", [
        ...existingOrderBy.filter((obj) => obj.key !== "Timestamp"),
      ]);
    }
  };

  const toggleMoreMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const getFields = (resultControls) =>
    _.isNil(resultControls.outputFields)
      ? []
      : resultControls.outputFields
          .filter((field) => field in availableFieldsObj)
          .map((c) => ({ field: c }));

  const handleError = (resp) => {
    if (resp !== "") {
      setErrorMessage(resp);
      setIsAlertVisible(true);
    }
  };

  const handleAlertClose = () => {
    setErrorMessage("");
    setIsAlertVisible(false);
  };

  const filterActions = [
    {
      label: "Done",
      onClick: (editorValues) => {
        if (editorValues.uuid) {
          queryControlInterface.update(editorValues.uuid, editorValues);
        } else {
          queryControlInterface.add(editorValues);
        }
      },
    },
    {
      label: "Cancel",
      onClick: (editorValues) => {
        // noop.  Just closes editor
      },
    },
  ];

  const handleColumnOrderChange = (newColumnOrder) => {
    setColumnOrderList(newColumnOrder);
  };

  const columns = getColumns(arrayReportColumnsMap, resultControls);

  const filteredColumns = useMemo(
    () => filterColumns(activeQuery, columns),
    [activeQuery, columns]
  );

  const sortedFilteredColumns = sortColumns(columnOrderList, filteredColumns);

  const handleRemoveReportFromFavorites = (e, model) => {
    e.stopPropagation();

    if (model && model.id) {
      setIsRemovingFromFavorites(model.id);

      const currentSavedReportIds =
        props.currentUser.config_user?.saved_report_ids || [];
      const updatedSavedReportIds = currentSavedReportIds.filter(
        (reportId) => reportId !== model.id
      );

      const dataToUpdate = {
        config_user: {
          ...props.currentUser.config_user,
          saved_report_ids: updatedSavedReportIds,
        },
      };

      props.editUser(props.orgId, props.currentUser.id, dataToUpdate, true);
    }
  };

  return (
    <Root>
      <SavedQueryModal
        show={showSaved}
        onClick={handleSelectModel}
        queries={savedQueries}
        recentReports={recentReports}
        favoriteReports={favoriteReports}
        onClose={() => setShowSaved(false)}
        editUser={props.editUser}
        orgId={props.orgId}
        currentUser={props.currentUser}
      />
      <SaveRequestModal
        open={saveOpen}
        refName="activeQuery"
        onClose={onSave}
        savedQueries={savedQueries}
      />
      {isAlertVisible && errorMessage && (
        <Alert
          severity="error"
          className={classes.exportAlert}
          onClose={handleAlertClose}
        >
          {errorMessage}
        </Alert>
      )}
      <Grid
        container
        spacing={4}
        className={classes.recentAndSavedButtonRowContainer}
      >
        <Grid item xs={4.33}>
          <FormControl className={classes.formControlContainer}>
            <Select
              value={""}
              displayEmpty
              renderValue={() => (
                <span display={"flex"} alignItems={"center"}>
                  <UpdateOutlinedIcon className={classes.selectIcon} />
                  Recent Reports
                </span>
              )}
              className={classes.recentAndFavoriteReportButton}
              variant="outlined"
              inputProps={{ "aria-label": "select recent reports" }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
              }}
            >
              {recentReports.length > 0 ? (
                recentReports.map((report, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => handleSelectModel(report)}
                  >
                    {report.name || "Unnamed Report"}
                  </MenuItem>
                ))
              ) : (
                <MenuItem>No Recent Reports</MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4.33}>
          <FormControl className={classes.formControlContainer}>
            <Select
              value={""}
              displayEmpty
              renderValue={() => (
                <span display={"flex"} alignItems={"center"}>
                  <StarBorderOutlinedIcon className={classes.selectIcon} />
                  Favorite Reports
                </span>
              )}
              className={classes.recentAndFavoriteReportButton}
              variant="outlined"
              inputProps={{ "aria-label": "select favorite reports" }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
              }}
              classes={{ paper: classes.favoriteSelectPaper }}
            >
              {favoriteReports.length > 0 ? (
                favoriteReports.map((report, index) => (
                  <StyledMenuItem
                    key={index}
                    onClick={() => handleSelectModel(report)}
                  >
                    <div className={menuClasses.favoriteListItem}>
                      <p>{report.name || "Unnamed Report"}</p>
                      {isRemovingFromFavorites !== null &&
                      isRemovingFromFavorites === report.id ? (
                        <CircularProgress size={20} />
                      ) : (
                        <CloseIcon
                          onClick={(e) =>
                            handleRemoveReportFromFavorites(e, report)
                          }
                        />
                      )}
                    </div>
                  </StyledMenuItem>
                ))
              ) : (
                <MenuItem>No Favorite Reports</MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item className={classes.savedReportsGrid} xs={3.33}>
          <Button
            variant={"text"}
            onClick={() => setShowSaved(true)}
            className={classes.viewAllSavedReportsBtn}
          >
            View All Saved Reports
          </Button>
        </Grid>
      </Grid>
      {selectedModel.id && (
        <Typography className={classes.savedReportNameText}>
          Working with saved report: {selectedModel.name}
        </Typography>
      )}
      <Grid container>
        {formError && (
          <Grid item xs={12}>
            <Typography color="error">{formError}</Typography>
          </Grid>
        )}
        <Grid className={classes.dFlex} item form="maincomponent" xs>
          <TimeControls
            delta={delta}
            setDelta={setDelta}
            controls={queryControls}
            interface={queryControlInterface}
          />
          <FormControl fullWidth className={classes.formControl}>
            <DataSourcePicker
              controls={queryControls}
              interface={queryControlInterface}
              logTypes={logTypes}
              language={props.language}
              errorMsg={(resp) => handleError(resp)}
            />
          </FormControl>
          <Button
            variant={"outlined"}
            color={"primary"}
            className={classes.outlinedButton}
            onClick={() => setShowAdvanced(!showAdvanced)}
            datacy={"reportShowAdvancedBtn"}
            sx={{ marginBottom: "20px" }}
          >
            {showAdvanced ? "Done Editing" : "Edit Report"}
          </Button>
          <ButtonGroup
            className={classes.marginRight}
            color="primary"
            variant="contained"
            sx={{ marginBottom: "20px" }}
          >
            <Button
              startIcon={<PlayCircleOutlineIcon />}
              onClick={handleSubmit}
              datacy={"reportSubmitButton"}
            >
              Submit
            </Button>
          </ButtonGroup>
          <div>
            <IconButton
              onClick={toggleMoreMenu}
              aria-controls={"simple-menu"}
              data-cy={"moreReportBtnMenu"}
              sx={{ marginBottom: "20px" }}
            >
              <MoreHorizIcon />
            </IconButton>
            <Menu
              keepMounted
              id={"simple-menu"}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              disableScrollLock={true}
              onClose={() => setAnchorEl(null)}
            >
              {/*
                  if we are actively exporting, show a dummy item that explains to the user we are exporting
                  otherwise, if we are not actively exporting, we can show them export options
                */}
              {isExporting ? (
                <Tooltip
                  title={"Exporting could take up to several minutes."}
                  placement={"left"}
                  classes={{ tooltip: classes.tooltip }}
                >
                  <MenuItem className={classes.disabledMenuItem}>
                    Exporting...
                  </MenuItem>
                </Tooltip>
              ) : (
                <MenuItem
                  onClick={() => onExport("jsonl")}
                  disabled={!isThereActiveQueryData(activeQuery) || isExporting}
                >
                  Export JSONL
                </MenuItem>
              )}
              {!isExporting && (
                <MenuItem
                  onClick={() => onExport("csv")}
                  disabled={!isThereActiveQueryData(activeQuery) || isExporting}
                >
                  Export CSV
                </MenuItem>
              )}
              <LicenseRestriction requires={"query.create"}>
                <MenuItem
                  onClick={() => {
                    setSaveOpen(true);
                    setAnchorEl(null);
                  }}
                  data-cy={"saveReportBtn"}
                  disabled={!isThereDataSourcesSelected(queryControls)}
                >
                  Save & Schedule Report
                </MenuItem>
              </LicenseRestriction>
              <MenuItem
                onClick={() => {
                  handleRestore();
                  setAnchorEl(null);
                }}
              >
                Reset Report
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setShowSaved(true);
                  setAnchorEl(null);
                }}
                datacy={"loadSavedReportBtn"}
              >
                Load Saved Report
              </MenuItem>
            </Menu>
          </div>
        </Grid>
      </Grid>
      <Card className={classes.card}>
        <Collapse in={showAdvanced} timeout="auto" unmountOnExit>
          <CardContent>
            <FormControl fullWidth>
              <OutputFieldsEditor
                control={resultControls.outputFields}
                interface={resultControlInterface}
                fields={availableFieldsList}
                distinctCountsApplied={!!isDistinct()}
                onAddExtraOutputField={handleAddExtraOutputField}
              />
            </FormControl>
            {isAlertVisible && errorMessage && (
              <Alert
                severity="error"
                className={classes.fieldsMultiSelectAlert}
                onClose={handleAlertClose}
              >
                {errorMessage}
              </Alert>
            )}
            <FormGroup row className={classes.rowContainer}>
              <span className={classes.rowButtonContainer}>
                <FilterEditor
                  controls={queryControls}
                  interface={queryControlInterface}
                  actions={filterActions}
                  fields={getFields(resultControls)}
                />
              </span>
              <FormControlLabel
                className={classes.formControlLabel}
                control={
                  <Switch
                    checked={addSuggestedFields}
                    onChange={(e) => setAddSuggestedFields(!addSuggestedFields)}
                    color="primary"
                  />
                }
                label="Include Suggested Columns"
              />
            </FormGroup>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    checked={!!isDistinct()}
                    onChange={handleDistinct}
                    color="primary"
                  />
                }
                label="Apply Distinct Counts to Report"
                datacy={"reportDistinctCountToggle"}
              />
            </FormGroup>
            {/* resultControls.transform?.map(resultParam=>(<TransformParamControl key={resultParam.uuid} refName="activeQuery" param={resultParam.uuid} label={resultParam.field} />)) */}
            <Button variant={"outlined"} onClick={handleSelectAllTypes}>
              Select All Data Sources
            </Button>
          </CardContent>
        </Collapse>
      </Card>
      {activeQuery && activeQuery.length === 5000 && (
        <Alert severity="warning">
          Your search exceeded the maximum number of rows (5000)
        </Alert>
      )}
      <DataTable
        title="Results"
        data={[...activeQuery]}
        onColumnOrderChange={handleColumnOrderChange}
        columns={sortedFilteredColumns}
        isFetching={!props.ready}
        contextMenuItems={[
          {
            onClick: menuCopy,
            text: "Copy to Clipboard",
            isDisabled: checkMenuDisabled,
          },
          {
            onClick: menuFilterEq,
            text: "Add to filters",
            isDisabled: checkMenuDisabled,
          },
          {
            onClick: menuFilterNeq,
            text: "Remove from results",
            isDisabled: checkMenuDisabled,
          },
        ]}
      />
    </Root>
  );
};

// ** Proptypes ***********************
Search.propTypes = {
  results: PropTypes.array,
  columns: PropTypes.array,
  dispatch: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired,
  activeQuery: PropTypes.array,
  savedQueries: PropTypes.array,
};

// ** Default props *******************
Search.defaultProps = {
  results: [],
  columns: [],
  logTypes: {},
  activeQuery: [],
  savedQueries: [],
};

const mapDispatchToProps = (dispatch) => {
  return {
    reload: (force) => dispatch(loadPageData(force)),
    editUser: (orgId, personId, data, editingSelf) =>
      dispatch(updateUser({ orgId, personId, data, editingSelf })),
  };
};

// ** State constants *****************
const mapStateToProps = (state) => {
  return {
    orgId: state.location.payload.orgId,
    query: state.location.query,
    queryID: state.location.payload.id1,
    logTypes: Codex.constant.api.bq.type,
    language: Codex.language.models.bq.type,
    currentUser: state.session?.settings?.user,
    globalError: state.global.error,
  };
};

const SearchPage = connect(mapStateToProps, mapDispatchToProps)(Search);
export default SearchPage;
