import React, { useCallback, useState, useEffect } from "react";
import { connect } from "react-redux";

import {
  Alert,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

import { cloudModuleMetadata } from "./constants";
import PropTypes from "prop-types";

import { Integration as IntegrationModel } from "lib/models/Integration";
import moment from "moment-timezone";

import { IntegrationLogo } from "../../Pages/CloudModules/IntegrationLogo";
import { ConfigurationForm, areValuesEmpty } from "./ConfigurationForm";
import { ResponseConnector } from "lib/models/ResponseConnector";
import AlertMessage, { useAlertMessage } from "views/Components/AlertMessage";

import Link from "redux-first-router-link";
import { baseContactSalesUrl } from "views/Pages/BillingPageView";
import { StyledDialog, classes } from "./cloudModuleDialogStyles";

const betaCloudConnectorTooltipText =
  "This integration is collection-only, meaning Blumira ingests & stores raw logs collected from this source. No parsing or detections are available yet.";

const CloudModuleDialog = (props) => {
  const Status = {
    NONE: "none",
    PENDING_REMOVE: "pending_remove",
    REMOVING: "removing",
    SAVING: "saving",
  };

  const [isAlertVisible, setIsAlertVisible] = useState(true);
  const [alertMessageProps, displayMessage] = useAlertMessage();
  const [contactSalesUrl, setContactSalesUrl] = useState(baseContactSalesUrl);
  const [currentStatus, setCurrentStatus] = useState(Status.NONE);
  const [currentStep, setCurrentStep] = useState(0);
  const [hasValidationError, setHasValidationError] = useState(false);
  const [selected, setSelected] = useState({
    module: null,
    integration: null,
  });
  const [tabValue, setTabValue] = useState("Logging");

  const handleTabChange = useCallback(
    (event, value) => {
      if (value !== "Logging" && !selected.integration?.id) {
        displayMessage({
          severity: "warning",
          message:
            "Please set up the logging connector before setting up the response connector.",
          noAutoHide: true,
        });
        return;
      }
      setTabValue(value);
    },
    [selected, displayMessage]
  );

  useEffect(() => setTabValue("Logging"), [props.orgId]);

  useEffect(() => {
    if (props.editingModel) {
      setSelected({
        module: getModule(props.editingModel),
        integration: props.editingModel,
      });
      setCurrentStep(1);
      setTabValue("Logging");
    }
  }, [props.editingModel, props.open]);

  useEffect(() => {
    if (props.currentUser) {
      const { first_name = "", last_name = "", email = "" } = props.currentUser;
      const contactUrlToSet =
        baseContactSalesUrl +
        `?firstname=${first_name}&lastname=${last_name}&email=${email}`;
      setContactSalesUrl(contactUrlToSet);
    }
  }, [props.currentUser]);

  const resetModal = () => {
    setCurrentStatus(Status.NONE);
    setTimeout(() => setCurrentStep(0), 200);
  };

  const toggleModal = () => {
    resetModal();
    props.toggleModal();
  };

  const handleNextStep = () => {
    let nextStep = currentStep + 1;
    setCurrentStep(nextStep);
  };

  const handleAddAnotherConnector = () => {
    resetModal();
  };

  const handleInviteTeamMembers = () => {
    props.dispatch({
      type: "PAGE",
      payload: {
        orgId: props.orgId,
        toplevel: "settings",
        secondlevel: "users",
      },
    });
  };

  const handleSelectModule = (module) => {
    const integration = new IntegrationModel({
      integrationName: `${module.title} Cloud Connector ${moment().format(
        "YYYY-MM-DD"
      )}`,
      integrationType: module.integrationType,
      configuration: {},
      secret: {},
    });
    setSelected({
      module: module,
      integration: integration,
    });
    handleNextStep();
  };

  const isExistingModel = () => {
    let currentModel = null;
    if (tabValue === "Logging") {
      currentModel = selected.integration;
    } else if (tabValue === "Response") {
      currentModel = getResponseConnector(selected.integration);
    } else {
      throw new Error(`Unhandled tabValue: ${tabValue}`);
    }
    return currentModel && currentModel.id;
  };

  const handleRemoveButton = () => {
    setCurrentStatus(Status.PENDING_REMOVE);
  };

  const handleDelete = async () => {
    let model;
    if (tabValue === "Logging") {
      const resCon = getResponseConnector(selected.integration);
      if (resCon && resCon.id) {
        displayMessage({
          severity: "warning",
          message:
            "Cannot delete logging connector until the response connector has been deleted.",
          noAutoHide: true,
        });
        setCurrentStatus(Status.NONE);
        return;
      }
      model = selected.integration;
    } else if (tabValue === "Response") {
      model = getResponseConnector(selected.integration);
    } else {
      throw new Error(`Unhandled tabValue: ${tabValue}`);
    }

    setCurrentStatus(Status.REMOVING);

    await model
      .delete()
      .then(() => {
        displayMessage({
          severity: "success",
          message: `Removed ${tabValue.toLowerCase()} connector`,
          autoHideDuration: 10000,
        });
        if (props.reload) {
          props.reload(true);
        }
        toggleModal();
        setCurrentStep(0);
      })
      .catch((e) => {
        console.error(e);
        displayMessage({
          severity: "error",
          message: `Failed to delete ${tabValue.toLowerCase()} connector: ${e}`,
          noAutoHide: true,
        });
      });
    setCurrentStatus(Status.NONE);
  };

  const handleClearPendingDelete = () => {
    setCurrentStatus(Status.NONE);
  };

  const saveIntegrationModel = (model) => {
    const isUpdating = !!model.id;
    const func = isUpdating ? "update" : "create";
    // If secrets were left blank, use the existing secrets
    if (!model.secret || areValuesEmpty(model.secret)) {
      model.set({ secret: undefined }, false);
    }
    return model[func]()
      .then(() => {
        displayMessage({
          severity: "success",
          message: "Saved logging connector",
          autoHideDuration: 10000,
        });
        if (props.showSuccess) setCurrentStep(2);
        else toggleModal();
      })
      .catch((e) => {
        displayMessage({
          severity: "error",
          message: getErrorMessage(e),
          noAutoHide: true,
        });
        console.error(e);
      });
  };

  const saveResponseModel = (model) => {
    const isUpdating = !!model.id;
    const func = isUpdating ? "update" : "create";
    // If secrets were left blank, use the existing secrets
    if (!model.secretFields || areValuesEmpty(model.secretFields)) {
      model.set({ secretFields: undefined }, false);
    }
    return model[func]()
      .then(() => {
        displayMessage({
          severity: "success",
          message: "Saved response connector",
          autoHideDuration: 10000,
        });
        toggleModal();
      })
      .catch((e) => {
        displayMessage({
          severity: "error",
          message: getErrorMessage(e),
          noAutoHide: true,
        });
        console.error(e);
      });
  };

  const getErrorMessage = (error) => {
    return (
      <div>
        <p>
          An error occurred trying to connect to {selected.module.title}. Please
          ensure your entries are correct and try again.
        </p>
        <pre>{error}</pre>
        <p>
          <a
            href="https://blumira.help/troubleshooting"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "inherit", textDecoration: "underline" }}
          >
            Click here for troubleshooting help.
          </a>
        </p>
      </div>
    );
  };

  const handleSave = async () => {
    setCurrentStatus(Status.SAVING);
    if (tabValue === "Logging") {
      await saveIntegrationModel(selected.integration);
    } else if (tabValue === "Response") {
      await saveResponseModel(getResponseConnector(selected.integration));
    } else {
      const msg = `Unknown tab: ${tabValue}`;
      displayMessage({
        severity: "error",
        message: msg,
        noAutoHide: true,
      });
      console.error(msg);
    }
    setCurrentStatus(Status.NONE);
    if (props.reload) {
      props.reload(true);
    }
  };

  const getConnectButtonText = () => {
    if (isExistingModel()) {
      return currentStatus === Status.SAVING ? "Saving..." : "Save Changes";
    }
    return currentStatus === Status.SAVING ? "Connecting..." : "Connect";
  };

  const getRemoveButtonText = () => {
    return currentStatus === Status.REMOVING ? "Removing..." : "Remove";
  };

  const currentDialogStepTitle = () => {
    return currentStep === 0
      ? "Available Cloud Connectors"
      : currentStep === 2
      ? "Cloud Connector Setup Successful!"
      : selected.integration?.id
      ? "Edit Cloud Connector"
      : "Add Cloud Connector";
  };

  const handleCancelModal = () => {
    setCurrentStatus(Status.NONE);
    if (selected.integration) {
      selected.integration.reset();
    }
    toggleModal();
  };

  const displayDialogActions = ({ hasValidationError }) => {
    if (
      currentStep > 0 &&
      (currentStatus === Status.PENDING_REMOVE ||
        currentStatus === Status.REMOVING)
    ) {
      return (
        <DialogActions className={classes.dialogActions}>
          <div className={classes.dialogActionsBtnContainer}>
            Are you sure you want to remove this {tabValue.toLowerCase()}{" "}
            connector?
            <br />
            <Button
              datacy={"cloudModuleConfirmRemoveBtn"}
              onClick={handleDelete}
              disabled={currentStatus === Status.REMOVING}
              className={classes.error}
              variant={"text"}
              style={{ marginLeft: 5 }}
            >
              {currentStatus === Status.REMOVING ? "Removing..." : "Confirm"}
            </Button>
            <Button
              onClick={handleClearPendingDelete}
              disabled={currentStatus === Status.REMOVING}
              color={"primary"}
              variant={"contained"}
              style={{ marginLeft: 5 }}
            >
              Cancel
            </Button>
          </div>
        </DialogActions>
      );
    } else if (currentStep === 1) {
      return (
        <DialogActions className={classes.dialogActions}>
          <div className={classes.dialogActionsBtnContainer}>
            {isExistingModel() && (
              <Button
                datacy={"cloudModuleRemoveBtn"}
                disabled={currentStatus !== Status.NONE}
                onClick={handleRemoveButton}
                className={classes.error}
                variant={"text"}
                style={{ marginLeft: 5 }}
              >
                {getRemoveButtonText()}
              </Button>
            )}
            <Button
              onClick={handleCancelModal}
              color={"primary"}
              variant={"text"}
              style={{ marginLeft: 5 }}
            >
              Cancel
            </Button>
            <Button
              datacy={"cloudModuleConnectBtn"}
              disabled={currentStatus !== Status.NONE || hasValidationError}
              onClick={handleSave}
              color={"primary"}
              variant={"contained"}
              style={{ marginLeft: 5 }}
            >
              {getConnectButtonText()}
            </Button>
          </div>
          {selected.module && selected.module.setupGuideLink && (
            <a
              href={selected.module.setupGuideLink}
              target={"_blank"}
              rel="noopener noreferrer"
            >
              {selected.module.title} setup guide <OpenInNewIcon />
            </a>
          )}
        </DialogActions>
      );
    }
    return null;
  };

  const CloudButton = (props) => {
    return (
      <Grid item xs={3} className={classes.buttonsGrid}>
        <Button
          variant={"outlined"}
          disabled={props.disabled}
          className={classes.moduleButton}
          datacy={props.module.integrationType}
          onClick={() => {
            // reference paidCta prop so that user can still
            // select to click on the cta link in the card
            if (props.paidCta) return;
            handleSelectModule(props.module);
          }}
        >
          <div className={classes.moduleOptionContainer}>
            <div className={classes.logoCtaContainer}>
              <div className={classes.logoContainer}>
                <IntegrationLogo
                  integrationType={props.module.integrationType}
                  width={props.module.imageWidth || 100}
                  height={
                    props.module.imageWidth
                      ? props.module.imageWidth / 2.166666667
                      : 46
                  }
                />
              </div>
            </div>
            <div className={classes.textContainer}>
              <p className={classes.moduleTitleText}>{props.module.title}</p>
              <p className={classes.moduleSubtitleText}>
                {props.module.subtitle}
              </p>
            </div>
          </div>
        </Button>
        {props.paidCta ? (
          <div className={classes.paidPlanCtaContainer}>
            <p>Available on paid plans</p>
            <div>
              <a
                target={"_blank"}
                rel="noopener noreferrer"
                href={contactSalesUrl}
              >
                Contact sales
                <OpenInNewIcon />
              </a>
            </div>
          </div>
        ) : props.isBeta ? (
          <Tooltip
            title={betaCloudConnectorTooltipText}
            placement={"bottom-start"}
            classes={{ tooltip: classes.tooltip }}
          >
            <div
              className={`${classes.paidPlanCtaContainer} ${classes.betaTooltipCtaContainer}`}
            >
              <p>Beta</p>
              <InfoOutlinedIcon />
            </div>
          </Tooltip>
        ) : props.isNew ? (
          <div
            className={`${classes.paidPlanCtaContainer} ${classes.betaTooltipCtaContainer}`}
          >
            <p>New</p>
            <AutoAwesomeIcon />
          </div>
        ) : null}
      </Grid>
    );
  };

  const getCloudConnectorList = () => {
    // Only show connectors that are generally available OR the the org has the beta license feature enabled for
    return cloudModuleMetadata
      .filter(
        (module) =>
          !module.betaChip || props.license.hasFeature(module?.betaChip)
      )
      .sort((a, b) => (a.title > b.title ? 1 : -1));
  };

  const isModuleInBeta = (module) => {
    {
      /*
      Leaving the following as an example
      for how to show beta flag in UI
      */
    }
    const { integrationType = "" } = module;
    if (integrationType === "replace me for beta module") {
      return true;
    }

    return false;
  };

  const isModuleNew = (module) => {
    const { integrationType = "" } = module;

    if (
      integrationType === "crowdstrike/poll" ||
      integrationType === "mscloud/poll"
    ) {
      return true;
    }

    return false;
  };

  const typeValuesToDisableWhenLimitReached = [
    "sentinelone/poll",
    "duo/poll",
    "mimecast/poll",
    "umbrella/poll",
    "webroot/poll",
    "o365/Initialize",
    "gsuite/poll",
    "sophos/poll",
    "jumpcloud/poll",
    "onelogin/poll",
    "onepass/poll",
    "azhub/poll",
  ];

  const getModule = (integration) => {
    return cloudModuleMetadata.find(
      ({ integrationType }) => integrationType === integration.integrationType
    );
  };

  const getResponseConnector = (integration) => {
    const connectorType = getResponseConnectorType(integration);
    if (!connectorType) {
      return null;
    }
    let connector = props.responseConnectors.find((conn) => {
      return conn.integrationId === integration.id;
    });
    if (!connector) {
      connector = new ResponseConnector({
        integrationId: integration.id,
        connectorTypeId: connectorType.id,
        orgId: props.orgId,
        configFields: {},
        secretFields: {},
      });
      props.responseConnectors.push(connector);
    }
    return connector;
  };

  const getResponseConnectorType = (integration) => {
    return props.responseConnectorTypes.find((connType) => {
      return connType.integrationType === integration.integrationType;
    });
  };

  const displayDialogContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <Grid
            container
            spacing={2}
            className={classes.moduleOptionsContainer}
            datacy={"cloudConnectorListModal"}
          >
            {getCloudConnectorList().map((module, idx) => {
              // Disable AWS connector on FREE, M365, and CLOUD
              if (
                module.integrationType === "aws/Initialize" &&
                (props.license.isTagged("limited-to-free") ||
                  props.license.isTagged("limited-to-microsoft") ||
                  props.license.isTagged("limited-to-cloud"))
              ) {
                return (
                  <CloudButton
                    key={module.integrationType}
                    module={module}
                    paidCta={true}
                    disabled={true}
                  />
                );
                // Disable Duo, SentinelOne, Mimecast, Umbrella, Webroot, Google and Sophos connectors on FREE and M365 when the 3 connector limit has been reached
              } else if (
                typeValuesToDisableWhenLimitReached.includes(
                  module.integrationType
                ) &&
                (props.license.isTagged("limited-to-free") ||
                  props.license.isTagged("limited-to-microsoft"))
              ) {
                return (
                  <CloudButton
                    key={module.integrationType}
                    module={module}
                    disabled={props.integrations.length === 3}
                    isBeta={isModuleInBeta(module)}
                    isNew={isModuleNew(module)}
                  />
                );
              } else {
                return (
                  <CloudButton
                    module={module}
                    key={module.integrationType}
                    isBeta={isModuleInBeta(module)}
                    isNew={isModuleNew(module)}
                  />
                );
              }
            })}
          </Grid>
        );
      case 1:
        return (
          <div className={classes.moduleFormContainer}>
            {selected.module && (
              <IntegrationLogo
                integrationType={selected.module.integrationType}
                width={selected.module.imageWidth || 100}
                height={
                  selected.module.imageWidth
                    ? selected.module.imageWidth / 2.166666667
                    : 46
                }
              />
            )}
            {isAlertVisible &&
              selected.module &&
              !selected.integration.id &&
              selected.module.setupGuideLink && (
                <Alert
                  icon={false}
                  className={classes.alert}
                  onClose={() => setIsAlertVisible(false)}
                >
                  Get started by{" "}
                  <a
                    target={"_blank"}
                    rel="noopener noreferrer"
                    href={selected.module.setupGuideLink}
                  >
                    opening the {selected.module.title} setup guide{" "}
                    <OpenInNewIcon />
                  </a>
                </Alert>
              )}
            <div className={classes.form}>
              <ConfigurationForm
                disabled={currentStatus !== Status.NONE}
                handleTabChange={handleTabChange}
                integration={selected.integration}
                integrationSchemas={props.integrationSchemas}
                license={props.license}
                onValidation={(validation) =>
                  setHasValidationError(validation.hasError)
                }
                responseConnector={getResponseConnector(selected.integration)}
                responseConnectorType={getResponseConnectorType(
                  selected.integration
                )}
                selectedCloudModule={selected.module}
                superadmin={props.superadmin}
                tabValue={tabValue}
              />
            </div>
          </div>
        );
      case 2:
        return (
          <div className={classes.nextStepContainer}>
            <p>
              Logs should begin flowing in soon, then Blumira will deploy your
              detection rules. This may take up to thirty minutes. While you
              wait...
            </p>
            <div className={classes.nextStepCtaContainer}>
              <Button
                className={classes.nextStepCtaButton}
                color={"primary"}
                variant={"contained"}
                onClick={handleAddAnotherConnector}
              >
                Add another Cloud Connector
              </Button>
              <Button
                className={classes.nextStepCtaButton}
                color={"primary"}
                variant={"outlined"}
                onClick={handleInviteTeamMembers}
              >
                Invite Team Members
              </Button>
            </div>
            <p className={classes.nextStepOrText}>or</p>
            <p className={classes.nextStepText}>
              Go to your Summary Dashboard to get started using Blumira
            </p>
            <Link
              to={{
                type: "PAGE",
                payload: {
                  orgId: props.orgId,
                  toplevel: "dashboard",
                  secondlevel: "summary",
                },
              }}
              className={classes.nextStepLink}
            >
              See Summary Dashboard
            </Link>
          </div>
        );
      default:
        return <span>error: unknown step {currentStep}</span>;
    }
  };

  return (
    <>
      <AlertMessage {...alertMessageProps} />
      <StyledDialog
        open={props.open}
        onClose={handleCancelModal}
        maxWidth={currentStep === 2 ? "sm" : "lg"}
        fullWidth={currentStep === 2}
      >
        <div className={classes.dialogHeaderContainer}>
          <DialogTitle>{currentDialogStepTitle()}</DialogTitle>
          <IconButton title="Close without saving" onClick={handleCancelModal}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent className={classes.dialogContent}>
          {displayDialogContent()}
        </DialogContent>
        {displayDialogActions({ hasValidationError })}
      </StyledDialog>
    </>
  );
};

CloudModuleDialog.propTypes = {
  editingModel: PropTypes.object,
  integrations: PropTypes.array.isRequired,
  integrationSchemas: PropTypes.array.isRequired,
  license: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  ready: PropTypes.bool.isRequired,
  reload: PropTypes.func.isRequired,
  responseConnectors: PropTypes.array.isRequired,
  responseConnectorTypes: PropTypes.array.isRequired,
  showSuccess: PropTypes.bool.isRequired,
  superadmin: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

CloudModuleDialog.defaultProps = {
  integrations: [],
  integrationSchemas: [],
  responseConnectors: [],
  responseConnectorTypes: [],
  ready: false,
  superadmin: false,
  showSuccess: false,
};

const mapStateToProps = (state) => {
  const orgId = state.location.payload.orgId;
  const currentOrg = state.session.settings.userOrgs.find(
    ({ id }) => id === orgId
  );

  return {
    orgId,
    currentOrg,
    currentUser: state?.session?.settings?.user,
  };
};

export default connect(mapStateToProps)(CloudModuleDialog);
