const ID_OPERATORS = ["eq", "!eq", "in", "!in"];

const psaIntegrationSchema = {
  $id: "/psaintegration",
  title: "PSAIntegration",
  type: "object",
  properties: {
    companyId: { type: "string" },
    manageUrl: { type: "string" },
    name: { type: "string" },
    privateKey: { type: "string" },
    publicKey: { type: "string" },
    orgId: {
      type: "string",
      format: "uuid",
      validOperators: ID_OPERATORS,
    },
    psaType: { type: "string", enum: ["Connectwise", "Halo", "Autotask"] },
    companySyncing: { type: "string", enum: ["Default", "Manual", "Auto"] },
    statusSyncing: { type: "string", enum: ["Default", "Manual", "Auto"] },
    typeSyncing: { type: "string", enum: ["Default", "Manual", "Auto"] },
    prioritySyncing: { type: "string", enum: ["Default", "Manual", "Auto"] },
    defaultEmail: { type: "string" },
    defaultBoard: { type: "string" },
    defaultCompany: { type: "string" },
    enabled: { type: "boolean" },
    connectionStatus: { type: "string" },
    lastConnection: { type: "string", format: "date-time" },
    companyMappings: {
      type: "array",
      items: {
        type: "object",
        properties: {
          orgId: {
            type: "string",
            format: "uuid",
          },
          companyId: { type: "string" },
          id: {
            type: "string",
            format: "uuid",
          },
          settingId: {
            type: "string",
            format: "uuid",
          },
          enabled: { type: "boolean" },
          created: { type: "string", format: "date-time", readOnly: true },
        },
        required: [],
        additionalProperties: true,
      },
    },
    statusMappings: {
      type: "array",
      items: {
        type: "object",
        properties: {
          blumiraStatus: { type: "string" },
          psaStatus: { type: "string" },
          id: {
            type: "string",
            format: "uuid",
          },
          settingId: {
            type: "string",
            format: "uuid",
          },
          created: { type: "string", format: "date-time", readOnly: true },
        },
        required: [],
        additionalProperties: true,
      },
    },
    priorityMappings: {
      type: "array",
      items: {
        type: "object",
        properties: {
          blumiraPriority: { type: "string" },
          psaPriority: { type: "string" },
          id: {
            type: "string",
            format: "uuid",
          },
          settingId: {
            type: "string",
            format: "uuid",
          },
          created: { type: "string", format: "date-time", readOnly: true },
        },
        required: [],
        additionalProperties: true,
      },
    },
    typeMappings: {
      type: "array",
      items: {
        type: "object",
        properties: {
          blumiraType: { type: "string" },
          psaType: { type: "string" },
          id: {
            type: "string",
            format: "uuid",
          },
          settingId: {
            type: "string",
            format: "uuid",
          },
          created: { type: "string", format: "date-time", readOnly: true },
        },
        required: [],
        additionalProperties: true,
      },
    },
    created: { type: "string", format: "date-time", readOnly: true },
  },
  required: [],
  additionalProperties: true,
};

export default psaIntegrationSchema;
