import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { connect } from "react-redux";

import {
  Dialog,
  TextField,
  DialogTitle,
  DialogContent,
  InputAdornment,
  IconButton,
  Link,
  Button,
} from "@mui/material";

import { matchSorter } from "match-sorter";

import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";

const PREFIX = "SearchableListDialog";
const classes = {
  dialog: `${PREFIX}-dialog`,
  dialogHeaderContainer: `${PREFIX}-dialogHeaderContainer`,
  dialogContent: `${PREFIX}-dialogContent`,
  headerCtaContainer: `${PREFIX}-headerCtaContainer`,
  itemContainer: `${PREFIX}-itemContainer`,
  searchTextFieldContainer: `${PREFIX}-searchTextFieldContainer`,
  link: `${PREFIX}-link`,
  close: `${PREFIX}-close`,
  clickable: `${PREFIX}-clickable`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.dialog}`]: {
    minWidth: 600,
  },
  [`& .${classes.dialogHeaderContainer}`]: {
    display: "flex",
    paddingRight: 30,
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  [`& .${classes.dialogContent}`]: {
    height: 500,
    padding: theme.shape.padding * 2,
  },
  [`& .${classes.headerCtaContainer}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    "& p": {
      marginBottom: 0,
      paddingRight: theme.shape.padding,
    },
  },
  [`& .${classes.itemContainer}`]: {
    display: "flex",
    flexDirection: "column",
  },
  [`& .${classes.searchTextFieldContainer}`]: {
    display: "flex",
    padding: "15px 30px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  [`& .${classes.link}`]: {
    cursor: "pointer",
    color: theme.palette.primary.main,
  },
  [`& .${classes.close}`]: {
    cursor: "pointer",
    color: theme.palette.text.primary,
  },
  [`& .${classes.clickable}`]: {
    cursor: "pointer",
  },
}));

export const SearchableListDialog = (props) => {
  const [searchText, setSearchText] = useState("");
  const [viewingItems, setViewingItems] = useState(props.items);

  useEffect(() => {
    setViewingItems(props.items);
  }, [props.items]);

  const handleSearch = (event) => {
    setSearchText(event.target.value);

    const propToSearch = props.searchableProperty
      ? props.searchableProperty
      : "name";

    if (event.target.value) {
      setViewingItems(
        matchSorter(props.items, event.target.value, { keys: [propToSearch] })
      );
    } else setViewingItems(props.items);
  };

  const handleItemClick = (itemId) => {
    if (!props.onItemClick) return;

    // reset search text
    setSearchText("");

    // handle item click and close
    props.onItemClick(itemId);
    props.onClose();
  };

  return (
    <StyledDialog open={props.open} onClose={props.onClose}>
      <div className={classes.dialog}>
        <div className={classes.dialogHeaderContainer}>
          <DialogTitle>{props.title}</DialogTitle>
          <div className={classes.headerCtaContainer}>
            {!!props.handleAdditionalInfoClick &&
            props.isAdditionalInfoAButton ? (
              <Button
                className={classes.link}
                onClick={props.handleAdditionalInfoClick}
              >
                {props.additionalInfo}
              </Button>
            ) : (
              <Link
                href={props.handleAdditionalInfoClick}
                className={classes.link}
                target={"_blank"}
                rel={"noopener noreferrer"}
                underline={"none"}
              >
                {props.additionalInfo}
              </Link>
            )}
            <IconButton
              color="primary"
              aria-label="close dialog"
              datacy={"orgSwitcherCloseBtn"}
              onClick={props.onClose}
              sx={{ marginLeft: "8px" }}
            >
              <CloseIcon className={classes.close} />
            </IconButton>
          </div>
        </div>
        {props.items && props.items.length > 0 ? (
          <div className={classes.searchTextFieldContainer}>
            <TextField
              autoFocus
              label={"Search"}
              value={searchText}
              data-cy={"searchableListDialogInput"}
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            {props.actionItem && props.actionItem}
          </div>
        ) : null}
        <DialogContent className={classes.dialogContent}>
          <div className={classes.itemContainer}>
            {viewingItems.length === 0 ? (
              <p style={{ textAlign: "center" }}>
                {props.emptyText ? props.emptyText : "No items found."}
              </p>
            ) : (
              viewingItems.map((item, index) => (
                <p
                  key={index}
                  onClick={() => handleItemClick(item.id)}
                  className={props.onItemClick ? classes.clickable : null}
                  data-cy={"selectOrgItem"}
                >
                  {props.searchableProperty
                    ? item[props.searchableProperty]
                    : item.name}
                </p>
              ))
            )}
          </div>
        </DialogContent>
      </div>
    </StyledDialog>
  );
};

SearchableListDialog.defaultProps = {
  items: [],
  additionalInfo: "",
};

export default connect(null, null)(SearchableListDialog);
