import React, { useState } from "react";
import PropTypes from "prop-types";

import Schema from "lib/schema";

import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import InputLabel from "@mui/material/InputLabel";
import { TimespanPicker } from "../TimespanPicker";
import ActionDialog from "views/Components/ActionDialog";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";

import {
  allowlistClasses,
  reportingClasses,
  ReportingFilterField,
  AllowlistFilterField,
} from "./filterFieldStyles";
import { getValue } from "utils";
import _ from "lodash";
import { useDeepCompareEffect } from "react-use";

export const defaultOperators = {
  eq: {
    description: "Equal",
    symbol: "=",
    types: ["string", "number", "boolean", "date-time", "uuid"],
    operator: "eq",
    negate: false,
  },
  "!eq": {
    description: "Not Equal",
    symbol: "=",
    types: ["string", "number", "boolean", "date-time", "uuid"],
    operator: "eq",
    negate: true,
  },
  gt: {
    description: "Greater than",
    symbol: ">",
    types: ["number", "array", "date-time"],
    operator: "gt",
    negate: false,
  },
  "!gt": {
    description: "Not greater than",
    symbol: ">",
    types: ["number", "array", "date-time"],
    operator: "gt",
    negate: true,
  },
  lt: {
    description: "Less than",
    symbol: "<",
    types: ["number", "array", "date-time"],
    operator: "lt",
    negate: false,
  },
  "!lt": {
    description: "Not less than",
    symbol: "<",
    types: ["number", "array", "date-time"],
    operator: "lt",
    negate: true,
  },
  contains: {
    description: "Contains",
    symbol: "C",
    types: ["string", "array"],
    operator: "contains",
    negate: false,
  },
  "!contains": {
    description: "Not contains",
    symbol: "C",
    types: ["string", "array"],
    operator: "contains",
    negate: true,
  },
  in: {
    description: "In",
    symbol: "in",
    types: ["string", "number", "uuid"],
    operator: "in",
    negate: false,
  },
  "!in": {
    description: "Not in",
    symbol: "in",
    types: ["string", "number", "uuid"],
    operator: "in",
    negate: true,
  },
  regex: {
    description: "Regex",
    symbol: "(.*)",
    types: ["string", "array"],
    operator: "regex",
    negate: false,
  },
  "!regex": {
    description: "Negate Regex",
    symbol: "(.*)",
    types: ["string", "array"],
    operator: "regex",
    negate: true,
  },
};

export const isDateTimeField = _.matches({ format: "date-time" });

export const filterFields = (schema, fields) =>
  fields.filter((obj) => !isDateTimeField(schema[obj.field]));
export const transformArrayValue = (value) => {
  if (_.isNil(value)) {
    return [];
  }
  if (!Array.isArray(value)) {
    return [value];
  }
  return value;
};

// FYI: the below func is commented to be doing 'more harm than good'
// in its current state, commenting out to reduce warnings in logs

// const getDefaultFilterValue = (valueType,editorValues)=>{
//   const {value}=editorValues;

//   if (valueType ==='boolean' && !_.isBoolean(value)){
//     return false;
//   }
//   else if (valueType ==='string' && !_.isString(value)){
//     return '';
//   }
//   else if (valueType ==='number' && !isNumber(value)){
//     return '0';
//   }
//   return value;
// }

const FilterField = (props) => {
  const classes =
    props.componentStyles === "allowlist" ? allowlistClasses : reportingClasses;

  const StyleElement =
    props.componentStyles === "allowlist"
      ? AllowlistFilterField
      : ReportingFilterField;
  // eslint-disable-next-line no-unused-vars
  const [schema, setSchema] = useState(
    Schema.getSchema(props.namespace).schema.properties
  );
  // eslint-disable-next-line no-unused-vars
  const [operators, setOperators] = useState(
    props.operators || defaultOperators
  );
  const [editorValues, setEditorValues] = useState({
    field: props.fields[0].field,
    operator: "eq",
    ...props.control,
  });
  const [confirmOperatorChangeOpen, setConfirmOperatorChangeOpen] =
    useState(false);
  const [targetOperatorValue, setTargetOperatorValue] = useState("");
  const [fields, setFields] = useState([]);

  useDeepCompareEffect(() => {
    const filteredFields = filterFields(schema, props.fields);
    setFields(filteredFields);
  }, [props.fields]);

  /* This is wrong, and is doing more harm than good right now, so i'm commenting it out
  useEffect(() => {
    const operator = Object.keys(operators).find(op=>operators[op].types.includes(schema[editorValues.field].type))
    const eValues = {...editorValues, operator,
       value:getDefaultFilterValue(schema[editorValues.field].type,editorValues)
    }
    setEditorValues(eValues)
  },[editorValues.field]) /* eslint-disable-line */

  const setQueryParam = (value) => {
    const eValues = { ...editorValues, ...value };
    setEditorValues(eValues);
    if (props.onChange) {
      props.onChange(eValues);
    }
  };

  const changeOperatorValue = (targetValue) => {
    const { negate, operator } =
      operators[Object.keys(operators).find((k) => k === targetValue)];
    const newParams = { operator, negate };

    if (operator === "in") {
      newParams.value = [];
    } else {
      newParams.value = "";
    }

    setQueryParam(newParams);
  };

  const handleChangeOperator = (e) => {
    // If the value field is empty, just change the operator
    if (
      editorValues.value === false ||
      _.isNil(editorValues.value) ||
      editorValues.value.length === 0
    ) {
      changeOperatorValue(e.target.value);
      // Otherwise, bring up the modal to confirm change
    } else {
      setTargetOperatorValue(e.target.value);
      setConfirmOperatorChangeOpen(true);
    }
  };

  const handleConfirmOperatorChange = () => {
    setConfirmOperatorChangeOpen(false);
    changeOperatorValue(targetOperatorValue);
  };

  const handleAction = (action) => {
    action.onClick(editorValues);
    if (props.onComplete) {
      props.onComplete();
    }
  };

  const handleFieldChange = (e) => {
    const field = e.target.value;

    const fieldSchema = schema[field];

    let updatedValues = {
      ...editorValues,
      field: field,
    };

    if (fieldSchema) {
      const compatibleOperator = Object.keys(operators).find((op) =>
        operators[op].types.includes(fieldSchema.type)
      );

      if (!compatibleOperator) {
        // No valid operator, set default
        updatedValues.operator = "eq";
        updatedValues.value = "";
      } else if (
        !operators[updatedValues.operator].types.includes(fieldSchema.type)
      ) {
        // Field type changed, update operator and value
        updatedValues.operator = compatibleOperator;
        updatedValues.value = "";
      }
    }

    setQueryParam(updatedValues);
  };

  const renderEditField = () => {
    const fieldParams = props.fields.find(
      (f) => f.field === editorValues.field
    );
    if (fieldParams && fieldParams.options) {
      return (
        <FormControl>
          <InputLabel id="value-field-label">Value</InputLabel>
          <Select
            labelId="value-field-label"
            id="value-field"
            label="Value"
            value={editorValues.value === undefined ? "" : editorValues.value}
            onChange={(e) => setQueryParam({ value: e.target.value })}
            disabled={props.disabled ? props.disabled : false}
            className={classes.valueWidth}
          >
            {fieldParams.options.map((o) => (
              <MenuItem key={`fieldParams-${o.value}`} value={o.value}>
                {o.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    } else if (
      editorValues.operator === "in" &&
      schema[editorValues.field].format !== "date-time"
    ) {
      return (
        <>
          <Autocomplete
            multiple
            id="filter-field-value-field"
            options={[]}
            defaultValue={[]}
            freeSolo
            onChange={(e, v) => setQueryParam({ value: v })}
            value={
              editorValues.value && editorValues.value.map
                ? editorValues.value || []
                : [editorValues.value]
            }
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={`${option}-${index}`}
                  color="primary"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                disabled={props.disabled ? props.disabled : false}
                className={classes.valueWidth}
                label={"Value"}
              />
            )}
          />
        </>
      );
    } else {
      switch (schema[editorValues.field]?.type) {
        case "string":
          if (schema[editorValues.field].format === "date-time") {
            return (
              <TimespanPicker
                labelId="value-field-label"
                starttime={editorValues.value || ""}
                settimes={(startTime, endTime) =>
                  setQueryParam({ value: startTime })
                }
                disabled={props.disabled ? props.disabled : false}
                addedStyles={
                  props.componentStyles === "allowlist"
                    ? { width: "100%" }
                    : { minWidth: "150px" }
                }
              />
            );
          } else {
            return (
              <>
                <TextField
                  value={editorValues.value || ""}
                  id={`QueryParamControl-${editorValues.uuid}`}
                  onChange={(e) => setQueryParam({ value: e.target.value })}
                  disabled={props.disabled ? props.disabled : false}
                  className={classes.valueWidth}
                  datacy={"filterFieldValueField"}
                  label={"Value"}
                />
              </>
            );
          }
        case "number":
          return (
            <>
              <TextField
                type="number"
                value={getValue("", editorValues.value)}
                id={`QueryParamControl-${editorValues.uuid}`}
                onChange={(e) =>
                  setQueryParam({ value: e.target.valueAsNumber })
                }
                disabled={props.disabled ? props.disabled : false}
                className={classes.valueWidth}
                label={"Value"}
              />
            </>
          );
        case "array":
          if (editorValues.operator === "contains") {
            return (
              <>
                <TextField
                  value={editorValues.value || ""}
                  id={`QueryParamControl-${editorValues.uuid}`}
                  onChange={(e) => setQueryParam({ value: e.target.value })}
                  disabled={props.disabled ? props.disabled : false}
                  className={classes.valueWidth}
                  label={"Value"}
                />
              </>
            );
          } else if (["gt", "lt"].includes(editorValues.operator)) {
            return (
              <>
                <TextField
                  type="number"
                  value={editorValues.value || ""}
                  id={`QueryParamControl-${editorValues.uuid}`}
                  onChange={(e) => setQueryParam({ value: e.target.value })}
                  disabled={props.disabled ? props.disabled : false}
                  className={classes.valueWidth}
                  label={"Value"}
                />
              </>
            );
          } else {
            return (
              <>
                <Autocomplete
                  multiple
                  id="filter-field-value-field"
                  options={[]}
                  defaultValue={[]}
                  freeSolo
                  onChange={(e, v) => setQueryParam({ value: v })}
                  value={transformArrayValue(editorValues.value)}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={`${option}-${index}`}
                        color="primary"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      disabled={props.disabled ? props.disabled : false}
                      className={classes.valueWidth}
                      label={"Value"}
                    />
                  )}
                />
              </>
            );
          }
        case "boolean":
          return (
            <>
              <FormControlLabel
                control={
                  <Switch
                    id={`QueryParamControl-${editorValues.uuid}`}
                    checked={editorValues.value || false}
                    onChange={(e) => setQueryParam({ value: e.target.checked })}
                    disabled={props.disabled ? props.disabled : false}
                  />
                }
                label="Value"
                labelPlacement={"top"}
              />
            </>
          );
        default:
          return (
            <>
              <Skeleton
                variant="text"
                disabled={props.disabled ? props.disabled : false}
              />
            </>
          );
      }
    }
  };

  const isIconButtonDisabled = (action) => {
    if (props.conditionsLength === 1 && action.actionType === "delete") {
      return true;
    } else {
      return false;
    }
  };

  const renderActionButtons = () => {
    if (props.disabled !== undefined) {
      if (!props.disabled) {
        return (
          <div
            className={`${classes.actionButtonWrap} ${classes.iconButtonWrap}`}
          >
            {props.actions &&
              props.actions.map((action, index) => (
                <Tooltip
                  key={action.tooltip}
                  title={action.tooltip}
                  className={classes.tooltip}
                >
                  <div>
                    <IconButton
                      className={classes.iconButtons}
                      color={action.color || "default"}
                      onClick={(e) => handleAction(action)}
                      disabled={isIconButtonDisabled(action)}
                    >
                      <action.icon fontSize="small" />
                    </IconButton>
                  </div>
                </Tooltip>
              ))}
          </div>
        );
      } else {
        return null;
      }
    } else {
      return (
        <div className={classes.actionButtonWrap}>
          {props.actions &&
            props.actions.map((action, index) => (
              <Button
                className={classes.wordButtons}
                key={action.label}
                onClick={() => handleAction(action)}
              >
                {action.label}
              </Button>
            ))}
        </div>
      );
    }
  };

  return (
    <StyleElement>
      <Paper className={classes.paper} elevation={props.elevation}>
        <div
          className={
            classes.selectFieldWrapper ? classes.selectFieldWrapper : ""
          }
        >
          <FormControl
            className={
              props.componentStyles === "allowlist" ? classes.formControl : ""
            }
          >
            <InputLabel id="field-field-label">Field</InputLabel>
            <Select
              labelId="field-field-label"
              id="field-field"
              label="Field"
              value={editorValues.field}
              onChange={handleFieldChange}
              className={`${classes.rightMargin} ${classes.fieldWidth}`}
              disabled={props.disabled ? props.disabled : false}
              datacy={"filterFieldFieldField"}
            >
              {fields
                .sort((a, b) =>
                  (a.display_name || a.field).localeCompare(
                    b.display_name || b.field
                  )
                )
                .map((obj) => (
                  <MenuItem key={obj.field} value={obj.field}>
                    {obj.display_name || obj.field}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
        <div
          className={
            classes.selectFieldWrapper ? classes.selectFieldWrapper : ""
          }
        >
          <FormControl
            className={
              props.componentStyles === "allowlist" ? classes.formControl : ""
            }
          >
            <InputLabel id="operator-field-label">Operator</InputLabel>
            <Select
              labelId="operator-field-label"
              id="operator-field"
              label="Operator"
              value={
                "negate" in editorValues && editorValues.negate === true
                  ? `!${editorValues.operator}`
                  : editorValues.operator
              }
              onChange={(e) => handleChangeOperator(e)}
              className={`${classes.rightMargin} ${classes.operatorWidth}`}
              disabled={props.disabled ? props.disabled : false}
              datacy={"filterFieldOperatorField"}
            >
              {Object.keys(operators).map((op) => {
                return (
                  <MenuItem
                    disabled={
                      !schema[editorValues.field] ||
                      !operators[op].types.includes(
                        schema[editorValues.field].type
                      )
                    }
                    key={op}
                    value={op}
                  >
                    {operators[op].description}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div
          className={classes.valueFieldWrapper ? classes.valueFieldWrapper : ""}
        >
          {renderEditField()}
          {props.errorMessage && (
            <p className={classes.errorText}>{props.errorMessage}</p>
          )}
        </div>
        {renderActionButtons()}
      </Paper>
      <ActionDialog
        open={confirmOperatorChangeOpen}
        title={"Are you sure?"}
        description={
          "Changing operators will clear the values from the value field"
        }
        actions={[
          {
            title: "Cancel",
            action: () => setConfirmOperatorChangeOpen(false),
          },
          {
            title: "Yes, I'm sure",
            action: handleConfirmOperatorChange,
            variant: "contained",
          },
        ]}
      />
    </StyleElement>
  );
};

// ** Proptypes ***********************
FilterField.propTypes = {
  namespace: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  onComplete: PropTypes.func,
};

// ** Default props *******************
FilterField.defaultProps = {
  control: {},
};

export default FilterField;
