import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Tooltip,
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import API from "lib/api/API2";
import { styled } from "@mui/material/styles";

const PREFIX = "ServerTable";

const classes = {
  actions: `${PREFIX}-actions`,
  dataGrid: `${PREFIX}-dataGrid`,
  errorContainer: `${PREFIX}-errorContainer`,
  loadingOverlay: `${PREFIX}-loadingOverlay`,
  tableCell: `${PREFIX}-tableCell`,
  tooltip: `${PREFIX}-tooltip`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.errorContainer}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: 100,
    padding: "20px",
  },
  [`&.${classes.loadingOverlay}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
}));

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  [`& .${classes.actions}`]: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  [`&.${classes.dataGrid}`]: {
    backgroundColor: theme.palette.background.paper,
    "& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeaderTitle": {
      fontWeight: "bold",
    },
    "& .MuiDataGrid-cell": {
      outline: "none !important",
      padding: "16px",
    },
    "& .MuiDataGrid-columnHeader": {
      outline: "none !important",
      padding: "16px",
    },
  },
  [`& .${classes.tableCell}`]: {
    cursor: "pointer",
    maxWidth: 400,
    wordWrap: "break-word",
    "& pre": {
      whiteSpace: "pre-wrap",
      color: theme.palette.text.primary,
    },
    "&:hover $tableCellAction": {
      display: "initial",
    },
  },
  [`& .${classes.tooltip}`]: {
    boxShadow: theme.shadows[4],
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
  },
}));

// Convert sortModel to orderBy for FEA
const getOrderBy = (sortModel) => {
  const sortFields = [];
  for (const item of sortModel) {
    const sortDir = item.sort === "desc" ? "-" : "";
    const sortField = item.field;
    sortFields.push(`${sortDir}${sortField}`);
  }
  return sortFields.join(",");
};

const renderActions = (actions, params) => {
  return actions.map((action) => (
    <Tooltip
      key={action.tooltip}
      title={action.tooltip}
      classes={{ tooltip: classes.tooltip }}
    >
      <IconButton
        datacy={`simpleTableRowIconButton`}
        className={classes.actions}
        color={action.color || "default"}
        onClick={(event) => {
          event.stopPropagation(); // Avoid onRowClick handler from firing
          action.onClick(params, event);
        }}
      >
        <action.icon fontSize="small" />
      </IconButton>
    </Tooltip>
  ));
};

// Get rows per page from either local storage or the first option
const getRowsPerPage = (pageSizeOptions, uniqueName) => {
  const pageSize = parseInt(localStorage.getItem(`rowsPerPage-${uniqueName}`));
  if (pageSize) {
    return pageSize;
  } else {
    return pageSizeOptions[0];
  }
};

const ServerTable = ({
  actions,
  columns,
  density,
  endpoint,
  groupBy,
  onRowClick,
  outputFields,
  refresh,
  pageSizeOptions,
  params,
  sortable,
  uniqueName,
}) => {
  const cols = columns.map((column) => ({
    cellClassName: classes.tableCell,
    headerName: column.headerName || column.title,
    field: column.field,
    flex: column.flex || 1,
    sortable: sortable || column.sortable,
    type: column.type || "string",
    valueGetter: column.valueGetter,
  }));

  cols.push({
    field: "actions",
    headerName: "",
    align: "right",
    sortable: false,
    resizable: false,
    filterable: false,
    renderCell: (params) => renderActions(actions, params),
  });

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: getRowsPerPage(pageSizeOptions, uniqueName),
  });
  const [sortModel, setSortModel] = useState([
    { field: cols[0].field, sort: "asc" },
  ]);

  const fetchRows = useCallback(async () => {
    setLoading(true);
    setError(null);

    let payload = {
      page: `${paginationModel.page + 1}:${paginationModel.pageSize}`,
    };
    if (groupBy) {
      payload.groupBy = groupBy.join(",");
    }
    if (sortable) {
      payload.orderBy = getOrderBy(sortModel);
    }
    if (outputFields) {
      payload.outputFields = outputFields.join(",");
    }
    for (const param of params) {
      const op = param?.operator;
      const value = param?.value ? String(param.value) : "";
      if (op) {
        const negate = param?.negate ? "!" : "";
        payload[`${param.field}.${negate}${op}`] = value;
      } else {
        payload[`${param.field}`] = value;
      }
    }

    try {
      const api = new API();
      const response = await api.get(endpoint, payload, []);

      setRows(response.data || []);
      setTotalRows(response?.meta?.totalItems || 0);
    } catch (err) {
      setError(err.message || "An unexpected error occurred");
      setRows([]);
      setTotalRows(0);
    } finally {
      setLoading(false);
    }
  }, [
    endpoint,
    groupBy,
    outputFields,
    paginationModel.page,
    paginationModel.pageSize,
    params,
    refresh,
    sortModel,
  ]);

  useEffect(() => {
    fetchRows();
  }, [fetchRows]);

  // Custom loading overlay
  const CustomLoadingOverlay = () => {
    return (
      <StyledBox className={classes.loadingOverlay}>
        <CircularProgress />
      </StyledBox>
    );
  };

  // Custom error component
  const CustomNoRowsOverlay = () => {
    if (error) {
      return (
        <StyledBox className={classes.errorContainer}>
          <Alert
            severity="error"
            sx={{ width: "100%", marginBottom: 2 }}
            action={
              <Button color="inherit" size="small" onClick={() => fetchRows()}>
                Retry
              </Button>
            }
          >
            Error loading data: {error}
          </Alert>
        </StyledBox>
      );
    } else {
      return (
        <StyledBox className={classes.errorContainer}>
          <p>No rows</p>
        </StyledBox>
      );
    }
  };

  const handlePaginationModelChange = useCallback((newPageModel) => {
    if (uniqueName && paginationModel.pageSize !== newPageModel.pageSize) {
      localStorage.setItem(`rowsPerPage-${uniqueName}`, newPageModel.pageSize);
    }
    setPaginationModel(newPageModel);
  }, []);

  const handleSortModelChange = useCallback((newSortModel) => {
    setSortModel(newSortModel);
  }, []);

  // Note: DataGrid gets its height and width from its parent
  return (
    <Box sx={{ width: "100%" }} datacy="simpleTable">
      <StyledDataGrid
        autoHeight
        className={classes.dataGrid}
        columns={cols}
        components={{
          LoadingOverlay: CustomLoadingOverlay,
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
        density={density || "standard"}
        disableColumnFilter
        disableColumnMenu
        disableColumnReorder
        disableRowSelectionOnClick
        error={error}
        getRowId={(row) => Object.values(row).join("")}
        loading={loading}
        onPaginationModelChange={handlePaginationModelChange}
        onRowClick={onRowClick}
        onSortModelChange={handleSortModelChange}
        pageSizeOptions={pageSizeOptions}
        pagination={true}
        paginationMode="server"
        paginationModel={paginationModel}
        rowCount={totalRows}
        rows={rows}
        slots={{ noRowsOverlay: CustomNoRowsOverlay }}
        sortable={sortable}
        sortingMode="server"
        sortingOrder={["asc", "desc"]}
      />
    </Box>
  );
};

ServerTable.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  density: PropTypes.string,
  endpoint: PropTypes.string.isRequired,
  groupBy: PropTypes.arrayOf(PropTypes.string),
  outputFields: PropTypes.arrayOf(PropTypes.string),
  refresh: PropTypes.number,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  params: PropTypes.arrayOf(PropTypes.object),
  sortable: PropTypes.bool,
  uniqueName: PropTypes.string.isRequired, // App-wide unique name
};

ServerTable.defaultProps = {
  actions: [],
  params: [],
  pageSizeOptions: [25, 50, 100, 250],
  sortable: false, // Not all of our APIs allow order_by yet
};

export default ServerTable;
