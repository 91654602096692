import React, { useState, useEffect } from "react";
import _ from "lodash";

import { styled } from "@mui/material/styles";
import { get, isNil, isEmpty } from "lodash";

import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormGroup from "@mui/material/FormGroup";

import Radio from "@mui/material/Radio";
import Tooltip from "@mui/material/Tooltip";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import InputAdornment from "@mui/material/InputAdornment";
import Checkbox from "@mui/material/Checkbox";

import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";

import HelpIcon from "@mui/icons-material/Help";

const PREFIX = "SimpleModelForm";
const classes = {
  formControl: `${PREFIX}-formControl`,
  button: `${PREFIX}-button`,
  divider: `${PREFIX}-divider`,
  radio: `${PREFIX}-radio`,
  tooltip: `${PREFIX}-tooltip`,
  multiselect: `${PREFIX}-multiselect`,
  plaintext: `${PREFIX}-plaintext`,
};

const Root = styled("form")(({ theme }) => ({
  [`& .${classes.formControl}`]: {
    padding: theme.spacing(3),
  },
  [`& .${classes.button}`]: {
    margin: theme.spacing(0, 0, 0, 1),
  },
  [`& .${classes.divider}`]: {
    margin: theme.spacing(1, 0),
  },
  [`& .${classes.radio}`]: {
    marginBottom: theme.spacing(0),
  },
  [`& .${classes.tooltip}`]: {
    cursor: "pointer",
  },
  [`& .${classes.multiselect}`]: {
    "& input": {
      height: 28,
    },
  },
  [`& .${classes.plaintext}`]: {
    "& .MuiInput-root": {
      border: 0,
      padding: 0,
    },
    "& .MuiFormLabel-root.Mui-disabled": {
      color: theme.palette.text.primary,
    },
    "& .MuiInputBase-root.Mui-disabled": {
      color: theme.palette.text.primary,
      cursor: "default",
    },
    "& .MuiInputBase-input.Mui-disabled": {
      cursor: "text",
    },
  },
}));

const fieldMap = {
  text: TextField,
  number: TextField,
  datetime: TextField,
  select: TextField,
  chip: Autocomplete,
  radio: RadioGroup,
  multiselect: Autocomplete,
  checkbox: Checkbox,
};

const showRequired = {
  text: true,
  number: true,
  datetime: true,
  select: false,
  chip: true,
  radio: false,
  multiselect: true,
  checkbox: true,
};

// if a value is not nil on the target's
// value we can use that, else return null
// in case of nullable props on the model
export const getNullableValueToSet = (value) => {
  // isNil returns true if the value is nullish, else false
  const valueToReturn = isNil(value) ? null : value;
  return valueToReturn;
};

const SimpleModelForm = (props) => {
  const { isSaving, formError } = props;

  const [model, setModel] = useState(props.model);
  const [fields, setFields] = useState(props.fields);
  const [rolesAllOptions, setRolesAllOptions] = useState([]);

  //todo layout state
  const [layout, setLayout] = useState([]);

  const [valid, setValid] = useState({});

  const [disabled, setDisabled] = useState(false);

  const [error, setError] = useState(false);
  const [formHelperText, setFormHelperText] = useState("");
  const [chipFieldHelperText, setChipFieldHelperText] = useState("");

  useEffect(() => {
    if (props.fields?.orgRoles?.lookup && !rolesAllOptions.length)
      setRolesAllOptions(props.fields.orgRoles.lookup);
  }, [props.fields, rolesAllOptions]);

  useEffect(() => {
    setLayout(props.layout);
  }, [props.layout]);

  useEffect(() => {
    if (props.model) setModel(props.model);
  }, [props.model]);

  const schema = model.getSchema().schema;

  const hasNestedPropertyValue = (model, names) => {
    if (!!model[names[0]] && !!model[names[0]][names[1]]) {
      return true;
    }

    return false;
  };

  const hasDoubleNestedPropertyValue = (model, names) => {
    if (
      !!model[names[0]] &&
      !!model[names[0]][names[1]] &&
      !!model[names[0]][names[1]][names[2]]
    ) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    const _fields = props.fields;

    Object.keys(_fields).map((fieldName) => {
      if (
        _fields[fieldName].value &&
        Object.hasOwnProperty.call(!_fields[fieldName], "ignore")
      ) {
        _fields[fieldName].ignore = true;
      }

      const names = fieldName.split(".");

      if (
        (model[fieldName] === undefined || model[fieldName] === null) &&
        (!Object.hasOwnProperty.call(_fields[fieldName], "ignore") ||
          _fields[fieldName].ignore !== true)
      ) {
        if (names.length > 2 && !hasDoubleNestedPropertyValue(model, names)) {
          let valueToSet = {
            [names[0]]: {
              ...model[names[0]],
              [names[1]]: {
                [names[2]]: _fields[fieldName].default,
              },
            },
          };
          model.set(valueToSet);
        } else if (names.length > 1 && !hasNestedPropertyValue(model, names)) {
          model.set({
            [names[0]]: {
              ...model[names[0]],
              [names[1]]: _fields[fieldName].default,
            },
          });
        } else {
          model.set({ [fieldName]: _fields[fieldName].default });
        }
      }
      if (!_fields[fieldName].value) {
        if (
          !Object.hasOwnProperty.call(_fields[fieldName], "ignore") ||
          _fields[fieldName].ignore !== true
        ) {
          if (names.length > 2)
            _fields[fieldName].value = model[names[0]][names[1]][names[2]];
          else if (names.length > 1) {
            _fields[fieldName].value = model[names[0]][names[1]];
          } else {
            _fields[fieldName].value = model[fieldName];
          }
        }
      }

      if (
        schema.properties[fieldName] &&
        schema.required &&
        schema.required.includes(fieldName) &&
        showRequired[_fields[fieldName].type] &&
        !Object.hasOwnProperty.call(_fields[fieldName], "helperText")
      ) {
        _fields[fieldName].helperText = "* Required";
      }

      if (
        schema.properties[fieldName] &&
        schema.properties[fieldName].readOnly &&
        !Object.hasOwnProperty.call(_fields[fieldName], "readOnly")
      ) {
        _fields[fieldName].readOnly = true;
      }

      _fields[fieldName].field = fieldName;
    });

    if (typeof props.onValidate === "function") {
      props.onValidate(model, _fields);
    }

    setFields({ ..._fields });
  }, [props.fields]);

  const handleSubmit = (event) => {
    event.preventDefault();

    const valid = validate();
    if (Object.keys(valid).length) {
      return;
    }

    setDisabled(true);

    const func = model.id ? "update" : "create";
    model[func]()
      .then(() => {
        if (props.reload) props.reload();
        props.onClose(func);
        setDisabled(false);
      })
      .catch(() => {
        setError(true);
        setFormHelperText(model.error.message);
        setDisabled(false);
      });
  };

  const updateRolesOptions = (selectedRoles = []) => {
    if (selectedRoles.length === 0) {
      //the selectedRoles array being empty means no role has been selected yet, or all roles were removed
      //so the full list of roles should show again as the role options
      fields["orgRoles"].lookup = rolesAllOptions;
    } else {
      //change the role options dynamically once a role is selected
      if (selectedRoles.some((r) => r.roleId === 50)) {
        fields["orgRoles"].lookup = fields["orgRoles"].lookup.filter(
          (r) => r.value.roleId === 50
        );
      } else {
        fields["orgRoles"].lookup = fields["orgRoles"].lookup.filter(
          (r) => r.value.roleId !== 50
        );
      }
    }
  };

  const handleChange = (value, name) => {
    if (name === "orgRoles") {
      updateRolesOptions(value);
    }

    let valueToSet = value;
    if (fields[name].type === "number") {
      const parsedNum = parseInt(value);
      if (_.isNaN(parsedNum)) {
        valueToSet = "";
      } else {
        valueToSet = parsedNum;
      }
    }

    fields[name].value = valueToSet;

    // if we are a select or radio, then validate and update
    // the fields onChange, because the user is done
    if (
      ["select", "radio", "multiselect", "number", "chip"].indexOf(
        fields[name].type
      ) >= 0
    ) {
      // the following is handling nested field values
      // and only up to one period (or one nest deep)
      let names = name.split(".");

      if (names.length > 1)
        model.set({
          [names[0]]: { ...model[names[0]], [names[1]]: valueToSet },
        });
      else model.set({ [name]: valueToSet });

      if (props.onChange) {
        props.onChange(value, name);
      }

      validate(fields);
    } else {
      setFields({ ...fields });
    }
  };

  const handleBlur = (value, name) => {
    // only supported fields
    if (name && fields[name].ignore !== true) {
      // the following is handling nested field values
      let names = name.split(".");
      if (names.length > 2) {
        let valueToSet = {
          [names[0]]: {
            ...model[names[0]],
            [names[1]]: {
              ...model[names[0]][names[1]],
              [names[2]]: value,
            },
          },
        };
        model.set(valueToSet);
      } else if (names.length > 1)
        model.set({ [names[0]]: { ...model[names[0]], [names[1]]: value } });
      else {
        model.set({ [name]: value });
        validate({ [name]: fields[name] });
      }
    }
  };

  // accepts options fields as input to
  const validate = (validateFields) => {
    // get all validation messages on the model
    const _valid = model.validationMessages();
    Object.keys(_valid).map((k) => console.warn(`[${k}]: ${_valid[k]}`));
    const modelFields = validateFields || fields;

    const validMessages = {};

    Object.keys(modelFields).map((fieldName) => {
      if (_valid[fieldName]) {
        validMessages[fieldName] = _valid[fieldName];
      }
    });

    // keep old messages too
    Object.keys(valid).map((fieldName) => {
      if (_valid[fieldName]) {
        validMessages[fieldName] = _valid[fieldName];
      }
    });

    setValid(validMessages);

    // If the invalid email format error message is present on
    // the recipients field (currently the only chip field used
    // in SimpleModelForm), Then show specific error message.
    if (!isEmpty(Object.keys(validMessages))) {
      Object.keys(validMessages).map((fieldName) => {
        if (fieldName === "recipients") {
          Object.values(validMessages).map((fieldValue) => {
            if (fieldValue === 'should match format "email"') {
              setChipFieldHelperText("One or more email addresses are invalid");
            } else {
              // user likely needs to add an email, show directions
              setChipFieldHelperText(
                "Type at least one email and press enter to add"
              );
            }
          });
        }
      });
    } else {
      setChipFieldHelperText("");
    }

    if (typeof props.onValidate === "function") {
      const _fields = props.onValidate(model, fields);
      if (_fields) {
        setFields({ ..._fields });
      }
    } else if (validateFields) {
      setFields({ ...fields });
    }

    if (Object.keys(validMessages).length) {
      setFormHelperText("Please fix the errors");
      setError(true);
    } else {
      setFormHelperText("");
      setError(false);
    }

    return _valid;
  };

  const getFieldAttributes = (field) => {
    switch (field.type) {
      case "text":
        return {
          className: field.plainText ? classes.plaintext : "",
          variant: field.plainText ? "standard" : "outlined",
          fullWidth: true,
          error: !!valid[field.field] || undefined,
          onBlur: (e) => {
            let valueToEvaluate = get(e, ["target", "value"], null);
            const valueToSet = getNullableValueToSet(valueToEvaluate);
            handleBlur(valueToSet, field.field);
          },
          helperText: valid[field.field]
            ? valid[field.field]
            : field.helperText,
          InputProps: field.tooltip
            ? {
                startAdornment: (
                  <InputAdornment position={"start"}>
                    <Tooltip
                      arrow
                      title={field.tooltip}
                      className={classes.tooltip}
                    >
                      <HelpIcon />
                    </Tooltip>
                  </InputAdornment>
                ),
              }
            : field.plainText
            ? { disableUnderline: true }
            : null,
          InputLabelProps: {
            shrink: true,
          },
        };
      case "number":
        return {
          type: "number",
          fullWidth: true,
          error: !!valid[field.field] || undefined,
          onBlur: (e) => handleBlur(parseInt(e.target.value), field.field),
          helperText: valid[field.field]
            ? valid[field.field]
            : field.helperText,
          InputLabelProps: {
            shrink: true,
          },
        };
      case "datetime":
        return {
          fullWidth: true,
          type: "datetime-local",
          error: !!valid[field.field] || undefined,
          onBlur: (e) => handleBlur(e.target.value, field.field),
          helperText: valid[field.field]
            ? valid[field.field]
            : field.helperText,
          InputLabelProps: {
            shrink: true,
          },
        };
      case "select":
        return {
          select: true,
          fullWidth: true,
          error: !!valid[field.field] || field.error || undefined,
          helperText: valid[field.field]
            ? valid[field.field]
            : field.helperText,
          InputProps: field.tooltip
            ? {
                startAdornment: (
                  <InputAdornment position={"start"}>
                    <Tooltip
                      arrow
                      title={field.tooltip}
                      className={classes.tooltip}
                    >
                      <HelpIcon />
                    </Tooltip>
                  </InputAdornment>
                ),
              }
            : null,
          InputLabelProps: {
            shrink: true,
          },
        };
      case "chip":
        return {
          className: classes.multiselect,
          multiple: true,
          options: [],
          freeSolo: true,
          getOptionLabel: (option) => option,
          onChange: (e, v) =>
            handleChange(
              v.map((_v) => _v.value || _v),
              field.field
            ),
          renderInput: (params) => (
            <TextField
              {...params}
              variant="outlined"
              error={!!valid[field.field] || undefined}
              // Display specific error helper text for invalid emails, then any other error text, and default to the basic field helper text
              helperText={
                chipFieldHelperText.length
                  ? chipFieldHelperText
                  : valid[field.field]
                  ? valid[field.field]
                  : field.helperText
              }
              label={field.label}
              InputLabelProps={{
                shrink: true,
              }}
            />
          ),
          renderTags: (tagValue, getTagProps) => {
            return tagValue.map((option, index) => (
              <Chip
                key={index}
                color={"primary"}
                label={option}
                {...getTagProps({ index })}
              />
            ));
          },
        };
      case "radio":
        return {};
      case "multiselect":
        return {
          className: classes.multiselect,
          multiple: true,
          options: field.lookup,
          getOptionLabel: (option) => option.label,
          onChange: (e, v) =>
            handleChange(
              v.map((_v) => _v.value || _v),
              field.field
            ),
          renderInput: (params) => (
            <TextField
              {...params}
              variant="outlined"
              error={!!valid[field.field] || undefined}
              helperText={
                valid[field.field] ? valid[field.field] : field.helperText
              }
              label={field.label}
              placeholder={field.label}
              InputLabelProps={{
                shrink: true,
              }}
            />
          ),
          isOptionEqualToValue: (option, value) => {
            return option.value === value;
          },
          renderTags: (tagValue, getTagProps) => {
            return tagValue
              .filter((option) => field.lookup.find((f) => f.value === option))
              .map((option, index) => (
                <Chip
                  key={index}
                  color={"primary"}
                  label={field.lookup.find((f) => f.value === option).label}
                  {...getTagProps({ index })}
                />
              ));
          },
        };
      default:
        return {};
    }
  };

  const renderFieldComponent = (modelField, index) => {
    const FieldComponent = fieldMap[modelField.type];
    if (!FieldComponent || !modelField) {
      throw `Unknown Field: ${modelField.type}`;
    } else {
      if (modelField.field === "orgRoles") {
        updateRolesOptions(modelField.value);
      }
      return (
        <FieldComponent
          name={modelField.field}
          label={modelField.label}
          value={modelField.value}
          disabled={modelField.readOnly || disabled || isSaving}
          datacy={`simpleModelFormFieldComponent-${index}`}
          onChange={(e, v) => {
            // largely to handle proper selection of 0 values
            const valueToEvaluate = e.target.value.toString()
              ? e.target.value
              : v;
            const valueToSet = getNullableValueToSet(valueToEvaluate);
            handleChange(valueToSet, modelField.field);
          }}
          {...getFieldAttributes(modelField)}
          {...modelField.props}
        >
          {modelField.type === "select" &&
            modelField.lookup.map((option) => {
              return (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              );
            })}
          {modelField.type === "radio" &&
            modelField.lookup.map((option) => (
              <FormControlLabel
                className={classes.radio}
                key={option.value}
                value={option.value}
                control={<Radio />}
                label={option.label}
              />
            ))}
          {modelField.type === "checkbox" &&
            modelField.lookup.map((option) => {
              return (
                <FormGroup key={option.value}>
                  <FormControlLabel
                    value={option.value}
                    control={<Checkbox />}
                    label={option.label}
                  />
                  {/* <FormControlLabel control={<Checkbox defaultChecked />} label="Label" />
              <FormControlLabel disabled control={<Checkbox />} label="Disabled" /> */}
                </FormGroup>
              );
            })}
        </FieldComponent>
      );
    }
  };

  const handleButton = (event, action) => {
    if (action.validate) {
      const valid = validate();
      if (Object.keys(valid).length) {
        return;
      }
    }
    action.action(event);
  };

  const getStyleForActionButton = (action) => {
    let stylesToReturn = {};

    if (action.leftAlign) {
      stylesToReturn.position = "absolute";
      stylesToReturn.left = "15px"; // default theme padding
    }

    if (action.backgroundColor)
      stylesToReturn.backgroundColor = action.backgroundColor;

    if (action.textColor) stylesToReturn.color = action.textColor;

    return stylesToReturn;
  };

  return (
    <Root>
      <FormControl
        component="fieldset"
        error={error}
        fullWidth={true}
        className={classes.formControl}
      >
        <Grid container spacing={props.spacing || 1}>
          {layout
            .filter(
              (fieldLayout) =>
                !!fields[fieldLayout.field] &&
                fields[fieldLayout.field].visible !== false
            )
            .map((fieldLayout, index) => (
              <Grid item xs={fieldLayout.xs || 6} key={fieldLayout.field}>
                {renderFieldComponent(fields[fieldLayout.field], index)}
              </Grid>
            ))}
        </Grid>

        {/* 
          FYI 'actions' also accept parameters for custom styling
          @backgroundColor (string) - change the background color with 
          @textColor (string) - change the color of the action text with 
          @leftAlign (bool) - align one button to the left of the grid
        */}
        {props.actions && <Divider className={classes.divider} />}
        <FormHelperText>{formHelperText}</FormHelperText>
        <Grid container justifyContent="flex-end">
          {props.actions &&
            props.actions.map((action, index) => {
              const isSaveButton = action?.title.toLowerCase().includes("save");
              const isAddButton = action?.title.toLowerCase().includes("add");

              // if the button title does not add or save, retain its title
              // during changing states, i.e. saving or adding a model
              const retainButtonText = !isSaveButton && !isAddButton;

              return (
                <Button
                  // Theme colors are proving to be tricky on
                  // MUI buttons, so adding to styles for now
                  style={getStyleForActionButton(action)}
                  color={action.backgroundColor ? "inherit" : "primary"}
                  variant={action.variant ? action.variant : "outlined"}
                  key={action.title}
                  disabled={
                    disabled || isSaving || (isSaveButton && formError) // If the form has a specific, non-model validation error, then disable the save button
                      ? true
                      : false
                  }
                  className={classes.button}
                  type={action.type || "button"}
                  datacy={`simpleModelFormSubmitBtn-${index}`}
                  onClick={
                    action.type === "button" && action.action
                      ? (e) => handleButton(e, action)
                      : handleSubmit
                  }
                >
                  {/* 
                    If the button is not the save button,
                    display the button's title instead
                  */}
                  {retainButtonText
                    ? action.title
                    : disabled || isSaving
                    ? "Saving..."
                    : action.title}
                </Button>
              );
            })}
        </Grid>
      </FormControl>
    </Root>
  );
};

export default SimpleModelForm;
