const ID_OPERATORS = ["eq", "!eq", "in", "!in"];

const psaTicketDetailSchema = {
  $id: "/psaticketdetail",
  title: "PSATicketDetail",
  type: "object",
  properties: {
    findingId: {
      type: "string",
      format: "uuid",
      validOperators: ID_OPERATORS,
      readOnly: true,
    },
    psaTicketId: {
      type: "string",
      format: "uuid",
      readOnly: true,
    },
    orgId: {
      type: "string",
      format: "uuid",
      validOperators: ID_OPERATORS,
      readOnly: true,
    },
    lastUpdated: { type: "string", format: "date-time", readOnly: true },
    created: { type: "string", format: "date-time", readOnly: true },
  },
  required: [],
  additionalProperties: true,
};

export default psaTicketDetailSchema;
