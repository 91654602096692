import React, { useEffect, useCallback, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Button, CircularProgress } from "@mui/material";
import API from "lib/api/API2";
import FindingCard from "views/Components/Finding/Card";

const moment = require("moment-timezone");

const PREFIX = "FindingCardList";

const classes = {
  tableTitle: `${PREFIX}-tableTitle`,
  emptyStateContainer: `${PREFIX}-emptyStateContainer`,
  loadMoreContainer: `${PREFIX}-loadMoreContainer`,
};

export const Root = styled("div")(({ theme }) => ({
  [`& .${classes.tableTitle}`]: {
    fontSize: 22,
    marginRight: 8,
    marginBottom: 8,
    fontWeight: "bold",
  },
  [`& .${classes.emptyStateContainer}`]: {
    textAlign: "center",
    padding: theme.spacing(4),
  },
  [`& .${classes.loadMoreContainer}`]: {
    display: "flex",
    justifyContent: "center",
    margin: theme.spacing(2, 0),
  },
}));

const FindingCardList = ({
  displayMessage,
  integrations,
  license,
  loading,
  orgId,
  ready,
  setLoading,
  setFindingCount,
}) => {
  const pageSize = 10;

  const [constants, setConstants] = useState([]);
  const [findings, setFindings] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loadingMore, setLoadingMore] = useState(false);

  const fetchConstants = useCallback(async () => {
    try {
      const api = new API();
      const response = await api.get("/constants", {}, []);
      setConstants(response?.data || []);
    } catch (err) {
      displayMessage({
        severity: "error",
        message: "Failed to load finding constants",
        autoHideDuration: 10000,
      });
    }
  }, []);

  const fetchFindings = useCallback(
    async (pageNum, append) => {
      setLoadingMore(true);
      try {
        const params = {
          "created.!lt": moment
            .utc()
            .add(-6, "months")
            .startOf("minute")
            .format("YYYY-MM-DDTHH:mm"),
          status: 0,
          orderBy: "-created",
          outputFields: [
            "analysis",
            "category",
            "confirmation",
            "confirmationCompleted",
            "created",
            "escalation",
            "escalationCompleted",
            "id",
            "mitigation",
            "mitigationCompleted",
            "name",
            "orgId",
            "owners",
            "priority",
            "shortId",
            "type",
          ].join(","),
          page: `${pageNum + 1}:${pageSize}`,
        };

        // If the org is a FREE edition org and doesn't have a cloud connector
        // setup yet, then return the full list of findings. This will allow
        // FREE orgs that have generated sample findings to view them up until
        // they have setup a cloud connector.
        const isFreeOrg = license?.isTagged("limited-to-free");
        const hasIntegs = integrations && integrations.length;
        if (!isFreeOrg || hasIntegs) {
          params["name.!contains"] = "[SAMPLE]";
        }

        const api = new API();
        const response = await api.get("/finding", params, []);
        const data = response?.data || [];

        // Filter out duplicates if appending
        let newFindings = data;
        if (append) {
          setFindings((prevFindings) => {
            const mergedFindings = newFindings.filter(
              (newFinding) => !prevFindings.some((f) => f.id === newFinding.id)
            );
            return [...prevFindings, ...mergedFindings];
          });
        } else {
          setFindings(newFindings);
        }

        setTotalPages(response?.meta?.totalPages || 0);
        setFindingCount(response?.meta?.totalItems || 0);
      } catch (err) {
        displayMessage({
          severity: "error",
          message: "Failed to load findings",
          autoHideDuration: 10000,
        });
      } finally {
        setLoadingMore(false);
      }
    },
    [license, integrations, orgId]
  );

  const handleLoadMore = useCallback(() => {
    setPage((prevPage) => {
      const nextPage = prevPage + 1;
      fetchFindings(nextPage, true);
      return nextPage;
    });
  }, []);

  // Load constants
  useEffect(() => {
    fetchConstants();
  }, [fetchConstants]);

  // Initial load of findings
  useEffect(() => {
    setFindings([]);
    setFindingCount(null);
    setLoading(true);
    fetchFindings(0, false).finally(() => setLoading(false));
  }, [fetchFindings]);

  if (!ready || loading) {
    return (
      <Root>
        <div className={classes.emptyStateContainer}>
          <p>Loading your unresolved findings...</p>
          <CircularProgress />
        </div>
      </Root>
    );
  }

  if (!findings.length) {
    return (
      <div className={classes.emptyStateContainer}>
        <p>No unresolved findings were generated.</p>
      </div>
    );
  }

  return (
    <Root>
      <div>
        {findings.map((finding) => (
          <FindingCard
            {...finding}
            key={finding.id}
            hideComments={true}
            constants={constants}
          />
        ))}
      </div>
      {page < totalPages - 1 && (
        <div className={classes.loadMoreContainer}>
          <Button
            datacy="summaryLoadMore"
            variant="outlined"
            onClick={handleLoadMore}
            disabled={loadingMore}
            startIcon={loadingMore && <CircularProgress size={20} />}
          >
            {loadingMore ? "Loading..." : "Load More"}
          </Button>
        </div>
      )}
    </Root>
  );
};

FindingCardList.propTypes = {
  displayMessage: PropTypes.func.isRequired,
  integrations: PropTypes.arrayOf(PropTypes.object).isRequired,
  license: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  ready: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  setFindingCount: PropTypes.func.isRequired,
};

FindingCardList.defaultProps = {
  integrations: [],
};

export default FindingCardList;
