const lcGroupSchema = {
  $id: "/lcgroup",
  title: "LC Group",
  type: "object",
  properties: {
    id: { type: "string", format: "uuid", readOnly: true },
    orgId: { type: "string", format: "uuid", createOnly: true },
    created: { type: "string", format: "date-time", readOnly: true },
    createdBy: { type: "string", format: "uuid", readOnly: true },
    modified: { type: "string", format: "date-time", readOnly: true },
    modifiedBy: { type: "string", format: "uuid", readOnly: true },
    description: { type: "string" },
    installationKey: { type: "string", format: "uuid", readOnly: true },
    agentCount: { type: "number", readOnly: true },
    agentSeatCount: { type: "number" },
  },
  required: ["agentSeatCount", "description"],
  additionalProperties: false,
};

export default lcGroupSchema;
