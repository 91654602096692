import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import moment from "moment-timezone";
import Time from "lib/time";

import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";

import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormGroup from "@mui/material/FormGroup";
import InputLabel from "@mui/material/InputLabel";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import _ from "lodash";
import { isNumber } from "../../utils";

const PREFIX = "TimespanPicker";
const rootClasses = {
  inlineFormControl: `${PREFIX}-inlineFormControl`,
  quickSelectItem: `${PREFIX}-quickSelectItem`,
  presetContainer: `${PREFIX}-presetContainer`,
  inputContainer: `${PREFIX}-inputContainer`,
  labelText: `${PREFIX}-labelText`,
  textContainer: `${PREFIX}-textContainer`,
  newSearchField: `${PREFIX}-newSearchField`,
  customSelectOption: `${PREFIX}-customSelectOption`,
};

const dialogClasses = {
  formControl: `${PREFIX}-formControl`,
  errorMessage: `${PREFIX}-errorMessage`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${rootClasses.inlineFormControl}`]: {
    minWidth: 250,
    marginRight: theme.shape.padding,
  },
  [`& .${rootClasses.quickSelectItem}`]: {
    textTransform: "capitalize",
    backgroundColor: theme.palette.background.paper,
  },
  [`& .${rootClasses.presetContainer}`]: {
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
  },
  [`& .${rootClasses.inputContainer}`]: {
    display: "flex",
    marginRight: 10,
    flexDirection: "column",
    alignItems: "flex-start",
  },
  [`& .${rootClasses.labelText}`]: {
    fontSize: 14,
    marginBottom: 5,
  },
  [`& .${rootClasses.textContainer}`]: {
    minWidth: 250,
    display: "flex",
    borderRadius: 10,
    alignItems: "center",
    padding: "10px 15px",
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    "& p": {
      fontSize: 15,
      marginBottom: 0,
    },
  },
  [`& .${rootClasses.newSearchField}`]: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
  },
  [`& .${rootClasses.customSelectOption}`]: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${dialogClasses.formControl}`]: {
    margin: theme.spacing(1),
  },
  [`& .${dialogClasses.errorMessage}`]: {
    margin: 0,
    paddingLeft: 20,
    color: theme.palette.error.main,
  },
}));

const defaultDeltas = [
  "@:-10",
  "@-1",
  "@-4",
  "@-24",
  "-7:d",
  "-30:d",
  "-1:w",
  "0:m",
  "-1:m",
  "0:q",
  "-1:q",
];

export const getDeltaStartTime = (delta) => {
  if (_.isNil(delta)) {
    return null;
  }

  const timeUnit = delta.charAt(delta.length - 1);
  const timeUnitMap = {
    d: "days",
    h: "hours",
    m: "months",
  };
  const timeNumber = delta.slice(0, -1);

  if (!Object.keys(timeUnitMap).includes(timeUnit) || !isNumber(timeNumber)) {
    return null;
  }

  return { startTimeNumber: timeNumber, startTimeUnit: timeUnitMap[timeUnit] };
};

export const TimespanPicker = (props) => {
  const deltas =
    props.deltas === undefined
      ? defaultDeltas.map((d) => new Time(d))
      : props.deltas.map((d) => new Time(d));

  const [selectedDelta, setSelectedDelta] = useState("-30:d");
  const [customDateRange, setCustomDateRange] = useState([]);
  const [startTime, setStartTime] = useState(props.starttime);
  const [endTime, setEndTime] = useState(props.endtime);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    const dMatch = deltas.find((d) => {
      const ranges = d.getTimeRange();
      return (
        ranges.startTime.diff(props.starttime || startTime, "minutes") === 0 &&
        ranges.endTime.diff(props.endtime || endTime, "minutes") === 0
      );
    });

    if (dMatch) {
      const ranges = dMatch.getTimeRange();
      setStartTime(moment.utc(ranges.startTime));
      setEndTime(moment.utc(ranges.endTime));
      setSelectedDelta(dMatch.timeString);
    } else {
      setStartTime(moment.utc(props.starttime));
      setEndTime(moment.utc(props.endtime));
      setSelectedDelta(props.selecteddelta || "custom");
    }
  }, [props.starttime, props.endtime, props.selecteddelta]);

  useEffect(() => {
    if (props.selecteddelta) {
      const deltaTime = new Time(props.selecteddelta);
      if (defaultDeltas.includes(deltaTime.timeString)) {
        setSelectedDelta(deltaTime.timeString);
      } else {
        setSelectedDelta("custom");
      }
    }
  }, [props.selecteddelta]);

  const quickDateClickHandler = (e) => {
    if (defaultDeltas.includes(e.target.value)) {
      const t = new Time(e.target.value);
      setSelectedDelta(t.timeString);
      const ranges = t.getTimeRange();
      setTimes(
        moment.utc(ranges.startTime),
        moment.utc(ranges.endTime),
        t.timeString
      );
    }
  };

  const setTimes = (startTime, endTime, selectedDelta) => {
    if (startTime > endTime)
      return setErrorMessage(
        "Oops, please ensure your start time begins before your end time"
      );
    else setErrorMessage(null);
    setStartTime(startTime);
    setEndTime(endTime);
    props.settimes(startTime, endTime, selectedDelta);
    handleCustomDateRangeClose();
  };

  const handleCustomDateRangeClose = () => {
    setErrorMessage(null);
    setCustomDateRange([]);
  };

  const handleSetCustomDateRange = () => {
    setCustomDateRange([startTime, endTime]);
  };

  const selectCustomDateRange = () => {
    setTimes(customDateRange[0].utc(), customDateRange[1].utc(), "custom");
  };

  return (
    <Root>
      <div className={rootClasses.root}>
        {props.isNorthStar ? (
          <div className={rootClasses.presetContainer}>
            <div className={rootClasses.inputContainer}>
              <FormControl
                className={rootClasses.inlineFormControl}
                style={
                  props.addedStyles ? props.addedStyles : { width: "260px" }
                }
              >
                <InputLabel id="datetime-select-label">
                  {"Time Range"}
                </InputLabel>
                <Select
                  id="datetime-select"
                  value={selectedDelta}
                  labelId="datetime-select-label"
                  label={"Time Range"}
                  onChange={quickDateClickHandler}
                  className={rootClasses.quickSelectItem}
                  MenuProps={{ disableScrollLock: true }}
                  style={{ borderRadius: 10, height: 43 }}
                  variant={"outlined"}
                >
                  <MenuItem
                    value="custom"
                    className={rootClasses.customSelectOption}
                    onClick={handleSetCustomDateRange}
                  >
                    Custom
                  </MenuItem>
                  {deltas.map((d, i) => (
                    <MenuItem
                      key={i}
                      className={rootClasses.quickSelectItem}
                      value={d.timeString}
                    >
                      {d.language()}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        ) : (
          <FormControl
            className={rootClasses.inlineFormControl}
            style={props.addedStyles ? props.addedStyles : { width: "260px" }}
          >
            <InputLabel id="datetime-select-label">{"Time Range"}</InputLabel>
            <Select
              MenuProps={{ disableScrollLock: true }}
              labelId="datetime-select-label"
              label={"Time Range"}
              id="datetime-select"
              value={selectedDelta}
              datacy={"datetimeSelect"}
              onChange={quickDateClickHandler}
              className={rootClasses.quickSelectItem}
              disabled={props.disabled ? props.disabled : false}
            >
              <MenuItem value="custom" onClick={handleSetCustomDateRange}>
                Custom
              </MenuItem>
              {deltas.map((d, i) => (
                <MenuItem
                  key={d.timeString}
                  datacy={`datetimeSelectOption-${i}`}
                  className={rootClasses.quickSelectItem}
                  value={d.timeString}
                >
                  {d.language()}
                </MenuItem>
              ))}
            </Select>
            {!!startTime && !!endTime && (
              <FormHelperText
                datacy={`formHelperText`}
                sx={{ marginLeft: 0, marginRight: 0 }}
              >{`${startTime.local().format("l, LT")} - ${endTime
                .local()
                .format("l, LT")}`}</FormHelperText>
            )}
          </FormControl>
        )}

        <StyledDialog
          disableScrollLock={true}
          onClose={handleCustomDateRangeClose}
          aria-labelledby="custom-daterange-picker"
          open={customDateRange.length}
        >
          <DialogTitle id="custom-daterange-picker-title">
            Select Date Range
          </DialogTitle>
          {!!errorMessage && (
            <p className={dialogClasses.errorMessage}>{errorMessage}</p>
          )}
          <FormGroup row>
            <div>
              <FormControl
                component="fieldset"
                className={dialogClasses.formControl}
              >
                <TextField
                  id="date-start"
                  label="Start Date"
                  type="date"
                  value={
                    customDateRange.length
                      ? customDateRange[0].format("YYYY-MM-DD")
                      : ""
                  }
                  onChange={(event) => {
                    let time = event.target.value;
                    if (!time) return;
                    const [year, month, date] = time.split("-");
                    // subtract one here because months are zero indexed
                    // per moment js documentation but month extracted
                    // from target value is not i.e. August = 7
                    let newTime = moment(customDateRange[0]).set({
                      year: year,
                      month: month - 1,
                      date: date,
                    });
                    setCustomDateRange([newTime, customDateRange[1]]);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
              <FormControl
                component="fieldset"
                className={dialogClasses.formControl}
              >
                <TextField
                  id="time-start"
                  label="Start Time"
                  type="time"
                  value={
                    customDateRange.length
                      ? customDateRange[0].local().format("HH:mm")
                      : ""
                  }
                  onChange={(event) => {
                    let time = event.target.value;
                    if (!time) return;
                    const [hour, minute] = time.split(":");
                    let newTime = moment(customDateRange[0].local())
                      .set("hour", hour)
                      .set("minute", minute);
                    setCustomDateRange([newTime.utc(), customDateRange[1]]);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </div>

            <div>
              <FormControl
                component="fieldset"
                className={dialogClasses.formControl}
              >
                <TextField
                  id="date-end"
                  label="End Date"
                  type="date"
                  value={
                    customDateRange.length
                      ? customDateRange[1].format("YYYY-MM-DD")
                      : ""
                  }
                  onChange={(event) => {
                    let time = event.target.value;
                    if (!time) return;
                    const [year, month, date] = time.split("-");
                    // subtract one here because months are zero indexed
                    // per moment js documentation but month extracted
                    // from target value is not i.e. August = 7
                    let newTime = moment(customDateRange[1]).set({
                      year: year,
                      month: month - 1,
                      date: date,
                    });
                    setCustomDateRange([customDateRange[0], newTime]);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
              <FormControl
                component="fieldset"
                className={dialogClasses.formControl}
              >
                <TextField
                  id="time-end"
                  label="End Time"
                  type="time"
                  value={
                    customDateRange.length
                      ? customDateRange[1].format("HH:mm")
                      : ""
                  }
                  onChange={(event) => {
                    let time = event.target.value;
                    if (!time) return;
                    const [hour, minute] = time.split(":");
                    let newTime = moment(customDateRange[1])
                      .set("hour", hour)
                      .set("minute", minute);
                    setCustomDateRange([customDateRange[0], newTime]);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </div>
          </FormGroup>
          <DialogActions>
            <Button onClick={handleCustomDateRangeClose} color="primary">
              Cancel
            </Button>
            <Button onClick={selectCustomDateRange} color="primary">
              OK
            </Button>
          </DialogActions>
        </StyledDialog>
      </div>
    </Root>
  );
};

export const TimeControls = (props) => {
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [selectedDelta, setSelectedDelta] = useState(props.delta);

  const deltas = {
    "@:-10": null,
    "@-1": "1h",
    "@-4": "4h",
    "@-24": "24h",
    "-7:d": "7d",
    "-30:d": "30d",
    "-1:w": null,
    "0:m": null,
    "-1:m": null,
    "0:q": null,
    "-1:q": null,
  };

  // this useEffect is largely to handle the query restore logic from the new report builder,
  // which should reset the selected relative time label to 'custom' after resetting
  useEffect(() => {
    if (props.delta !== selectedDelta) setSelectedDelta(props.delta);
  }, [props.delta]);

  useEffect(() => {
    Object.keys(props.controls)
      .filter((uuid) => props.controls[uuid].field === "created")
      .forEach((uuid) => {
        if (props.controls[uuid].operator === "gt") {
          setStartTime(props.controls[uuid].value);
        } else if (props.controls[uuid].operator === "lt") {
          setEndTime(props.controls[uuid].value);
        } else if (props.controls[uuid].operator === "in") {
          const delta = props.controls[uuid].value[0];
          const mappedDelta = Object.keys(deltas).find(
            (d) => deltas[d] === delta
          );

          const { startTimeNumber, startTimeUnit } = getDeltaStartTime(delta);

          setStartTime(moment().subtract(startTimeNumber, startTimeUnit));
          setEndTime(moment());
          setSelectedDelta(mappedDelta);
          if (props.setDelta) {
            props.setDelta(mappedDelta);
          }
        }
      });
  }, [props.interface, props.controls]);

  const setTimes = (startTime, endTime, selectedDelta) => {
    const legacyDelta = deltas[selectedDelta];
    const updated = {};

    if (legacyDelta) {
      Object.keys(props.controls)
        .filter((uuid) => props.controls[uuid].field === "created")
        .forEach((uuid) => {
          if (props.controls[uuid].operator === "in") {
            updated["in"] = true;
            props.interface.update(uuid, { value: [legacyDelta] });
          } else {
            props.interface.remove(uuid);
          }
        });
      if (!updated["in"]) {
        props.interface.add({
          field: "created",
          value: [legacyDelta],
          operator: "in",
        });
      }
    } else {
      setSelectedDelta(selectedDelta);
      if (props.setDelta) {
        props.setDelta(selectedDelta);
      }
      Object.keys(props.controls)
        .filter((uuid) => props.controls[uuid].field === "created")
        .forEach((uuid) => {
          if (props.controls[uuid].operator === "gt") {
            updated["gt"] = true;
            props.interface.update(uuid, {
              value: startTime.format("YYYY-MM-DDTHH:mm:ss.SSS999"),
            });
          } else if (props.controls[uuid].operator === "lt") {
            updated["lt"] = true;
            props.interface.update(uuid, {
              value: endTime.format("YYYY-MM-DDTHH:mm:ss.SSS999"),
            });
          } else {
            props.interface.remove(uuid);
          }
        });
      if (!updated["gt"]) {
        props.interface.add({
          field: "created",
          value: startTime.format("YYYY-MM-DDTHH:mm:ss.SSS999"),
          operator: "gt",
        });
      }
      if (!updated["lt"]) {
        props.interface.add({
          field: "created",
          value: endTime.format("YYYY-MM-DDTHH:mm:ss.SSS999"),
          operator: "lt",
        });
      }
    }
  };

  return (
    <TimespanPicker
      defaltdeltas={Object.keys(deltas)}
      selecteddelta={selectedDelta}
      starttime={startTime}
      endtime={endTime}
      settimes={setTimes}
      style={props.style}
    />
  );
};
