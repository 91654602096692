const responseConnectorTypeSchema = {
  $id: "/response/connectorType",
  title: "Response Connector Type",
  type: "object",
  properties: {
    id: {
      type: "string",
      maxLength: 255,
      pattern: "^[A-Za-z0-9-]+$",
      readOnly: true,
    },
    displayName: { type: "string", readOnly: true },
    description: { type: "string", readOnly: true },
    integrationType: { type: "string", readOnly: true },
    configFieldTypes: {
      type: "array",
      items: {
        type: "object",
        properties: {
          fieldName: { type: "string", readOnly: true },
          externalFieldName: { type: "string", readOnly: true },
          displayName: { type: "string", readOnly: true },
          fieldType: { type: "integer", readOnly: true },
        },
        required: [
          "field_name",
          "external_field_name",
          "display_name",
          "field_type",
        ],
        additionalProperties: false,
      },
      readOnly: true,
    },
    secretFieldTypes: {
      type: "array",
      items: {
        type: "object",
        properties: {
          fieldName: { type: "string", readOnly: true },
          externalFieldName: { type: "string", readOnly: true },
          displayName: { type: "string", readOnly: true },
          fieldType: { type: "integer", readOnly: true },
          showLastN: { type: "boolean", readOnly: true },
        },
        required: [
          "field_name",
          "external_field_name",
          "display_name",
          "field_type",
        ],
        additionalProperties: false,
      },
      readOnly: true,
    },
  },
  required: ["id"],
  additionalProperties: false,
};

export default responseConnectorTypeSchema;
