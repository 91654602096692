import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";

import { setOwners } from "redux/actions/Findings";
import { resolveFinding } from "redux/actions/Findings";
import { loadPageData } from "redux/actions/Request";

import Time from "lib/time";
import { findingColumnOptions } from "lib/util/FindingUtils";

import SimpleTable from "views/Components/SimpleTable";
import FilterEditor from "views/Components/Reporting/FilterEditor";
import { TimeControls } from "views/Components/TimespanPicker";

import {
  useQueryControls,
  useResultControls,
  SaveRequestModal,
} from "views/Components/QueryControls";

import { get, isEqual } from "lodash";

import {
  Chip,
  Fade,
  Button,
  Switch,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  IconButton,
  FormControl,
  InputAdornment,
  ToggleButton,
  ToggleButtonGroup,
  Alert,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import PublicIcon from "@mui/icons-material/Public";
import DeleteIcon from "@mui/icons-material/DeleteOutline";

import { copyTextToClipboard, getValue } from "utils";

import {
  resolutionOptions,
  getFindingTableQuickFilters,
  getQueryParameterFromFilter,
  getSelectedFilterFromQueryControls,
} from "./constants";

import FindingsResolutionDialog from "views/Components/Finding/FindingsResolutionDialog";

import {
  Root,
  PrioritySpan,
  StyledDialog,
  rootClasses,
  priorityClasses,
  dialogClasses,
} from "./styles";

import Request from "../../../lib/api/Request";

const Priority = (props) => {
  return (
    <PrioritySpan>
      <div className={priorityClasses.container}>
        <div
          className={priorityClasses.circle}
          style={{ backgroundColor: props.color }}
        />
        P{props.priority}
      </div>
    </PrioritySpan>
  );
};

const renderDateCell = (column, model) => {
  return moment
    .utc(model[column.field])
    .tz(moment.tz.guess(true))
    .format("lll z");
};

const renderAnalysisCell = (column, model) => {
  if (!model[column.field]) return;
  let string = model[column.field].replace(/(<([^>]+)>)/gi, ""); // regex to identify html tags and replace

  // Truncate analyses that are too long
  if (string.length > 400) {
    const words = string.slice(0, 400).split(" ");
    return <>{words.slice(0, words.length - 1).join(" ")}&hellip;</>;
  }

  if (string.includes("{")) return <pre>{string}</pre>;
  return string;
};

const renderAverageTime = (column, model) => {
  if (!model[column.field]) return;

  // value is returned in seconds
  // if less than 48 hours, show hours, minutes and seconds
  // if more than 48 hours, show days, hours and minutes

  const timeInSeconds = model[column.field];
  let standardizedTime = moment.unix(timeInSeconds).utc();

  if (timeInSeconds < 172800)
    return standardizedTime.format("H [hours,] m [minutes and] s [seconds]");

  return standardizedTime.format("D [days,] H [hours and] m [minutes]");
};

// Default columns for the finding page, in order of columns in the table
export const defaultColumnFields = [
  "status",
  "created",
  "shortId",
  "type",
  "priority",
  "name",
  "analysis",
  "jurisdiction",
  "id",
];

// Full columns for the finding page, in order of columns in the table
export const fullColumnFields = [
  ...defaultColumnFields,
  ...["category", "resolution", "summary", "blocked", "owners"],
];

const FindingsView = (props) => {
  // const rootClasses = useStyles();

  const [resolution, setResolution] = useState(10);
  const [selectedFindingIds, setSelectedFindingIds] = useState([]);

  const [textFilter, setTextFilter] = useState("");
  const [tableTextFilter, setTableTextFilter] = useState("");
  const [findingLink, setFindingLink] = useState({});
  const [viewingQuery, setViewingQuery] = useState(null);
  const [modelToDelete, setModelToDelete] = useState(false);
  const [isApplyingFilters, setIsApplyingFilters] = useState(false);
  const [activeColumns, setActiveColumns] = useState([]);
  const [viewingQueries, setViewingQueries] = useState([]);
  const [viewingQueryLabel, setViewingQueryLabel] = useState("All Findings");
  const [isViewingFilters, setIsViewingFilters] = useState(false);
  const [isPresetDialogOpen, setIsPresetDialogOpen] = useState(false);
  const [selectedQuickFilter, setSelectedQuickFilter] = useState("All");
  const [queryControls, queryControlInterface] = useQueryControls("findings");
  const [resultControls, resultControlInterface] =
    useResultControls("findings");
  const [isResolutionNotesDialogOpen, setIsResolutionNotesDialogOpen] =
    useState(false);
  const [saveOpen, setSaveOpen] = useState(false);
  const [delta, setDelta] = useState();
  const [isExporting, setIsExporting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isAlertVisible, setIsAlertVisible] = useState(true);

  const distinctCountColumn = { title: "Count", flex: 1, field: "count" };

  // ATTENTION ATTENTION
  // remove EVERYTHING BELOW when API is updated to handle these transforms
  const averageTimeColumn = {
    title: "Average Time",
    flex: 1,
    field: "mAvgSecondsToStatus",
    renderValue: renderAverageTime,
  };
  // remove EVERYTHING ABOVE when API is updated to handle these transforms
  // ATTENTION ATTENTION

  const lookups = props.lookups;
  const renderAssignees = useCallback(
    (column, model) => {
      const assignees = model.owners;
      if (!assignees) {
        return "";
      }
      const allAssignees = [];
      const seen = {};
      ["analysts", "responders", "managers"].forEach((type) => {
        if (assignees[type]) {
          assignees[type].forEach((userId) => {
            if (!seen[userId]) {
              seen[userId] = true;
              allAssignees.push(lookups.owners[userId] || userId);
            }
          });
        }
      });
      if (allAssignees.length > 0) return allAssignees.join(", ");
      return <span className={rootClasses.noAssignees}>None</span>;
    },
    [lookups, rootClasses]
  );

  const availableColumns = useMemo(
    () => [
      {
        title: "Status",
        flex: 1,
        field: "status",
        searchable: true,
        lookup: lookups.status,
      },
      {
        title: "Created",
        flex: 1,
        field: "created",
        renderValue: renderDateCell,
      },
      { title: "Short Id", flex: 1, field: "shortId", searchable: true },
      { title: "Type", flex: 1, field: "type", lookup: lookups.type },
      {
        title: "Priority",
        flex: 0.5,
        field: "priority",
        lookup: lookups.priority,
      },
      { title: "Name", flex: 1, field: "name", searchable: true },
      {
        title: "Analysis",
        flex: 1,
        field: "analysis",
        searchable: true,
        renderValue: renderAnalysisCell,
      },
      {
        title: "Jurisdiction",
        flex: 1,
        field: "jurisdiction",
        searchable: true,
        lookup: lookups.jurisdiction,
      },
      {
        title: "Category",
        flex: 1,
        field: "category",
        searchable: true,
        lookup: lookups.category,
      },
      {
        title: "Resolution",
        flex: 1,
        field: "resolution",
        searchable: true,
        lookup: lookups.resolution,
      },
      { title: "Summary", flex: 1, field: "summary", searchable: true },
      {
        title: "Blocked",
        flex: 0.5,
        field: "blocked",
        searchable: true,
        lookup: lookups.blocked,
      },
      {
        title: "Assignees",
        flex: 1,
        field: "owners",
        searchable: true,
        lookup: lookups.owners,
        renderValue: renderAssignees,
      },
    ],
    [lookups, renderAssignees]
  );
  const defaultColumns = useMemo(
    () =>
      defaultColumnFields
        .filter((fld) => fld !== "id")
        .map((fld) => availableColumns.find((col) => col.field === fld)),
    [availableColumns]
  );

  useEffect(() => {
    // if it exists get the deviceName query param
    // to set in state for filtering on page render
    const newTextFilter = get(props.query, ["deviceName"], null);

    if (newTextFilter) setTableTextFilter(newTextFilter);
  }, [props.query]);

  useEffect(() => {
    // if user is viewing a query honor
    // the active columns of said query
    if (!isApplyingFilters && !viewingQuery) setActiveColumns(defaultColumns);

    if (
      !isApplyingFilters &&
      !viewingQuery &&
      resultControls.outputFields &&
      !isEqual(resultControls.outputFields, fullColumnFields)
    ) {
      const filteredColumns = resultControls.outputFields
        .filter((fld) => fld !== "id")
        .map((fld) => availableColumns.find((col) => col.field === fld));

      setActiveColumns(filteredColumns);
    }
  }, [defaultColumns, resultControls]);

  useEffect(() => {
    setViewingQueries(props.queries);
  }, [props.queries]);

  useEffect(() => {
    if (props.ready) {
      const options = props.users.map((user) => ({
        value: user.id,
        label: `${user.firstName} ${user.lastName}`,
      }));
      findingColumnOptions.find((c) => c.field === "owners").options = options;
    }
  }, [props.ready, props.users]);

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevOrgId = usePrevious(props.orgId);

  useEffect(() => {
    setViewingQuery(null);
    if (
      localStorage.getItem("viewingQuery") &&
      prevOrgId &&
      prevOrgId !== props.orgId
    ) {
      localStorage.removeItem("viewingQuery");
      setViewingQueryLabel("All Findings");
    }
  }, [props.orgId]);

  useEffect(() => {
    if (Object.keys(queryControls).length > 0) {
      // setting selectedFilter here based on present queryControls since the
      // page requires a reload when a user interacts with a 'quick filter'
      const selectedFilter = getSelectedFilterFromQueryControls(queryControls);
      setSelectedQuickFilter(selectedFilter);

      if (props.query) {
        // deviceName is a query param reserved for filtering the simple table
        // on page load when viewing from the Blu Agent Devices page
        Object.keys(props.query)
          .filter((key) => key !== "deviceName")
          .forEach((key) => {
            // check if the query is already present in queryControls since
            // we reload the page and query params could be added infinitely
            const alreadyAdded = Object.keys(queryControls).find(
              (qcUUID) => queryControls[qcUUID].field === key
            );

            // if the query param has not been added, we can add here
            // prior to reloading the page
            if (!alreadyAdded) {
              const qp = {
                field: key,
                operator: "eq",
                value: getValue("", props.query[key]),
              };

              queryControlInterface.add(qp);
            }
          });
      }

      const viewingQueryLabel = localStorage.getItem("viewingQuery");
      if (viewingQueryLabel) {
        setViewingQueryLabel(viewingQueryLabel);
        const query = props.queries.find((q) => q.name === viewingQueryLabel);
        setViewingQuery(query);

        Object.keys(query.resultParams).map((k) =>
          resultControlInterface.update(k, query.resultParams[k])
        );
        if (query?.resultParams?.outputFields.length) {
          // use provided output fields if present
          // and update simple table columns
          let newActiveColumns = query.resultParams.outputFields
            .filter((fld) => fld !== "id")
            .map((fld) => availableColumns.find((col) => col.field === fld))
            .filter((col) => col);

          // ATTENTION ATTENTION
          // remove EVERYTHING BELOW when API is updated to handle these transforms
          // if average time is included add to active cols

          const isAverage = query.resultParams.outputFields.find(
            (fieldName) => fieldName === averageTimeColumn.field
          );

          newActiveColumns = isAverage
            ? [...newActiveColumns, averageTimeColumn]
            : newActiveColumns;

          if (!isAverage) {
            resultControlInterface.update("outputFields", [
              ...query.resultParams.outputFields,
              ...["id"],
            ]);
          } else {
            const columnFields = newActiveColumns.map((col) => col.field);
            resultControlInterface.update("outputFields", columnFields);
          }
          // remove EVERYTHING ABOVE when API is updated to handle these transforms
          // ATTENTION ATTENTION

          setActiveColumns(newActiveColumns);
        } else {
          // if no output fields provided, use default
          setActiveColumns(defaultColumns);
          resultControlInterface.update("outputFields", defaultColumnFields);
        }
      }

      props.reload();
    }
  }, [queryControls]);

  const handleApplyFilters = () => {
    const { reload } = props;

    const columnFields = activeColumns.map((col) => col.field);
    const isAverage = columnFields.find(
      (field) => field === "mAvgSecondsToStatus"
    );
    const isDistinct =
      resultControls.transform && resultControls.transform.key === "distinct";
    if (!isDistinct && !isAverage) columnFields.push("id");
    resultControlInterface.update("outputFields", columnFields);
    setIsApplyingFilters(true);
    reload(true);
  };

  const handleApplyQuickFilter = (event, newSelectedFilter) => {
    const queryParameter = getQueryParameterFromFilter({
      filterLabel: newSelectedFilter,
      findings: props.findings,
    });

    const existingTypeQueryControlUUID = Object.keys(queryControls).find(
      (qcUUID) => queryControls[qcUUID].field === "type"
    );

    // a 'type' query param is already in place, remove it
    if (existingTypeQueryControlUUID)
      queryControlInterface.remove(existingTypeQueryControlUUID);

    // check for status, if not add it to the query params as "Open" (0)
    const existingStatusQueryControlUUID = Object.keys(queryControls).find(
      (qcUUID) => queryControls[qcUUID].field === "status"
    );

    if (!existingStatusQueryControlUUID)
      queryControlInterface.add({
        field: "status",
        value: "0",
        operator: "eq",
      });

    // add the new query param from the quick filter selection if it exists
    if (queryParameter) queryControlInterface.add(queryParameter);

    setSelectedQuickFilter(newSelectedFilter);

    props.reload(true);
  };

  const resetSearchPresets = () => {
    handleReset();
  };

  const clearAdvancedFilters = () => {
    handleReset();
    toggleViewingFilters();
  };

  const handleReset = (overrideControls = false) => {
    const { reload } = props;
    queryControlInterface.restore();
    resultControlInterface.restore();

    // use set times func to update
    // ui times and query controls
    let startTime = moment().add(-30, "days");
    let endTime = moment();
    handleSetTimes(startTime, endTime);

    localStorage.removeItem("viewingQuery");
    setViewingQueryLabel("All Findings");

    setViewingQuery(null);
    setIsApplyingFilters(false);
    reload(true);
  };

  const handleSetTimes = (startTime, endTime) => {
    queryControlInterface.update(
      "startTime",
      moment.utc(startTime).startOf("minute").format("YYYY-MM-DDTHH:mm")
    );
    queryControlInterface.update(
      "endTime",
      moment.utc(endTime).endOf("minute").format("YYYY-MM-DDTHH:mm:ss.SSS999")
    );
    props.reload();
  };

  const handleSavedSelect = (e) => {
    const query = props.queries.find((q) => q.id === e.target.value);
    setViewingQuery(query);
    localStorage.setItem("viewingQuery", query.name);
    queryControlInterface.restore();
    resultControlInterface.restore();
    query.queryParams.forEach((qp) => {
      if (qp.field === "created") {
        if (
          (qp.operator === "gt" && qp.negate === false) ||
          (qp.operator === "lt" && qp.negate === true)
        ) {
          let newStartTime = moment.utc(qp.value);
          queryControlInterface.update(
            "startTime",
            newStartTime.format("YYYY-MM-DDTHH:mm")
          );
        } else if (
          (qp.operator === "lt" && qp.negate === false) ||
          (qp.operator === "gt" && qp.negate === true)
        ) {
          let newEndTime = moment.utc(qp.value).endOf("minute");
          queryControlInterface.update(
            "endTime",
            newEndTime.format("YYYY-MM-DDTHH:mm:ss.SSS999")
          );
        } else if (qp.operator === "in") {
          const t = new Time(qp.value[0]);
          const ranges = t.getTimeRange();
          queryControlInterface.update(
            "startTime",
            ranges.startTime.format("YYYY-MM-DDTHH:mm")
          );
          queryControlInterface.update(
            "endTime",
            ranges.endTime.format("YYYY-MM-DDTHH:mm:ss.SSS999")
          );
        }
      } else {
        queryControlInterface.add(qp);
      }
    });
    Object.keys(query.resultParams).map((k) =>
      resultControlInterface.update(k, query.resultParams[k])
    );
    if (query?.resultParams?.outputFields.length) {
      // use provided output fields if present
      // and update simple table columns
      let newActiveColumns = query.resultParams.outputFields
        .filter((fld) => fld !== "id")
        .map((fld) => availableColumns.find((col) => col.field === fld))
        .filter((col) => col);

      // ATTENTION ATTENTION
      // remove EVERYTHING BELOW when API is updated to handle these transforms
      // if average time is included add to active cols
      const isAverage = query.resultParams.outputFields.find(
        (fieldName) => fieldName === averageTimeColumn.field
      );
      newActiveColumns = isAverage
        ? [...newActiveColumns, averageTimeColumn]
        : newActiveColumns;

      if (!isAverage) {
        resultControlInterface.update("outputFields", [
          ...query.resultParams.outputFields,
          ...["id"],
        ]);
      } else {
        const columnFields = newActiveColumns.map((col) => col.field);
        resultControlInterface.update("outputFields", columnFields);
      }
      // remove EVERYTHING ABOVE when API is updated to handle these transforms
      // ATTENTION ATTENTION

      setActiveColumns(newActiveColumns);
    } else {
      // if no output fields provided, use default
      setActiveColumns(defaultColumns);
      resultControlInterface.update("outputFields", defaultColumnFields);
    }
    props.reload();

    if (localStorage.getItem("viewingQuery")) {
      setViewingQueryLabel(localStorage.getItem("viewingQuery"));
    }
  };

  const handleTextFilterChange = (event) => {
    setTextFilter(event.target.value);

    if (event.target.value)
      setViewingQueries(
        props.queries.filter((q) =>
          q.name.toLowerCase().includes(event.target.value.toLowerCase())
        )
      );
    else setViewingQueries(props.queries);
  };

  // Context Menu Fucntions
  const handleMenuCopy = (contextMenu) => {
    copyTextToClipboard(contextMenu.anchorEl.innerText);
  };

  const handleViewDetails = (contextMenu) => {
    const { orgId } = props;
    const { id } = contextMenu.model;
    props.gotoFinding({ id, orgId });
  };

  const handleOpenInNewTab = (contextMenu) => {
    const { orgId } = props;
    const { model = {} } = contextMenu;
    setFindingLink({
      type: "PAGE",
      payload: {
        orgId: orgId,
        toplevel: "reporting",
        secondlevel: "findings",
        id1: model.id,
      },
    });
  };

  const onSave = (queryID) => {
    setSaveOpen(false);
    // ensure UI is updated with
    // up to date saved queries
    props.reload(true);
  };

  const toggleViewingFilters = () => {
    setIsViewingFilters(!isViewingFilters);
  };

  const handleError = (resp) => {
    if (resp !== "") {
      setErrorMessage(resp);
      setIsAlertVisible(true);
    }
  };

  const onExport = async (downloadType) => {
    const transform = !resultControls.transform
      ? []
      : resultControls.transform.map((obj) => {
          delete obj.uuid;
          delete obj.boolParam;
          return obj;
        });
    const queryParams = Object.values(queryControls).map((obj) => obj);
    const request = new Request("/findingexport", queryParams, {
      ...resultControls,
      transform,
      outputType: downloadType,
    });

    // set state var to disable export options
    // and display loading indicator in UI
    setIsExporting(true);

    // async to prohibit multiple export clicks
    // while we work on exporting
    request
      .get()
      .then(() => setIsExporting(false))
      .catch((err) => {
        console.log(err);
        setIsExporting(false);
        handleError("We had an issue exporting your report.");
      });
  };

  const togglePresetDialog = () => {
    setModelToDelete(false);
    setIsPresetDialogOpen(!isPresetDialogOpen);
  };

  const toggleResolutionNotesDialog = () => {
    setIsResolutionNotesDialogOpen(!isResolutionNotesDialogOpen);
  };

  const handleSelectQuery = (id) => {
    handleSavedSelect({ target: { value: id } });
    togglePresetDialog();
  };

  const handleViewScheduledReports = () => {
    props.dispatch({
      type: "PAGE",
      payload: {
        orgId: props.orgId,
        toplevel: "reporting",
        secondlevel: "scheduledreports",
      },
    });
  };

  const setColumns = (columns) => {
    setActiveColumns(columns);
    resultControlInterface.update(
      "outputFields",
      columns.map((col) => col.field)
    );
  };

  const handleAddColumn = (event) => {
    if (
      event.target.value &&
      activeColumns.length !== availableColumns.length
    ) {
      const newColumns = event.target.value;
      setColumns(newColumns);
    }
  };

  const handleRemoveColumn = (column) => {
    const newColumns = activeColumns.filter((c) => c !== column);
    setColumns(newColumns);
  };

  const handleDistinctChange = (e) => {
    if (e.target.checked) {
      resultControlInterface.update("transform", [{ operator: "count" }]);
      setColumns(activeColumns);
    } else {
      resultControlInterface.remove("transform");
      setColumns(activeColumns);
    }
  };

  const isGlobal = (query) => !query?.orgId;

  const toggleConfirmDelete = (e, model) => {
    e.stopPropagation();
    setModelToDelete(model);
  };

  const handleCancel = (e) => {
    e.stopPropagation();
    setModelToDelete(false);
  };

  const handleDeleteQuery = () => {
    if (!modelToDelete) return;
    modelToDelete.delete().then(({ statusCode, data = {} }) => {
      if (statusCode === 200) {
        // if we  successfully delete we should
        // update the UI to display current viewing
        // queries without closing the modal
        let newViewingQueries = [...viewingQueries];
        setViewingQueries(newViewingQueries.filter((q) => q.id !== data.id));
        setViewingQuery(null); // UI should show 'all findings' as current selected preset
      }
    });
  };

  const handleMultiSelection = (option = {}, findingIds = []) => {
    const { value } = option;
    // selected value from const options
    setResolution(value);

    // set array in state for resolution
    setSelectedFindingIds(findingIds);

    // open dialog for optional resolution notes
    toggleResolutionNotesDialog();
  };

  const handleResolveFindings = (resolutionNotes) => {
    // build data with user notes
    const data = {
      status: 40,
      resolution: resolution,
      resolutionNotes: resolutionNotes,
    };

    // assigning owners expects an arr of objects
    // with a value prop that matches a user id
    const ownerData = [
      {
        value: props.currentUser.id,
      },
    ];

    // assign user as responder and resolve selected findings
    Promise.all(
      selectedFindingIds.map(async (findingId) => {
        await props.assignCurrentUserAsResponder({
          ownerValues: ownerData,
          findingId: findingId,
        });
        await props.dispatchResolveFinding({
          data,
          findingId,
        });
      })
    ).then(() => {
      // close dialog
      toggleResolutionNotesDialog();

      // reload with resolved findings
      props.reload(true);
    });
  };

  const isDistinctChecked = () => {
    return (
      resultControls.transform &&
      resultControls.transform.find((c) => c.operator === "count")
    );
  };

  const filterActions = [
    {
      label: "Done",
      onClick: (editorValues) => {
        if (editorValues.uuid) {
          queryControlInterface.update(editorValues.uuid, editorValues);
        } else {
          queryControlInterface.add(editorValues);
        }
      },
    },
    {
      label: "Cancel",
      onClick: (editorValues) => {
        // noop.  Just closes editor
      },
    },
  ];

  const renderAdvancedFilters = () => {
    if (isViewingFilters) {
      return (
        <div className={rootClasses.filterContainer}>
          <div className={rootClasses.filterSectionOuterContainer}>
            <div className={rootClasses.advancedSectionContainer}>
              <FormControl>
                <InputLabel id="select-label">Show columns</InputLabel>
                <Select
                  multiple
                  value={activeColumns}
                  onChange={handleAddColumn}
                  labelId={"select-label"}
                  label={"Show columns"}
                  renderValue={() => (
                    <div>
                      {activeColumns.map((column) => (
                        <Chip
                          clickable
                          color={"primary"}
                          key={column.title}
                          label={column.title}
                          className={rootClasses.chip}
                          onMouseDown={(event) => {
                            event.stopPropagation();
                          }}
                          onDelete={() => handleRemoveColumn(column)}
                        />
                      ))}
                    </div>
                  )}
                >
                  {availableColumns.filter(
                    (c) => !activeColumns.find((ac) => ac.title === c.title)
                  ).length ? (
                    availableColumns
                      .filter(
                        (c) => !activeColumns.find((ac) => ac.title === c.title)
                      )
                      .map((column) => (
                        <MenuItem key={column.title} value={column}>
                          {column.title}
                        </MenuItem>
                      ))
                  ) : (
                    <p value={null} className={rootClasses.noColumnText}>
                      no columns available
                    </p>
                  )}
                </Select>
              </FormControl>
            </div>
            <div className={rootClasses.advancedSectionContainer}>
              <p className={rootClasses.titleText}>Filters</p>
              <FilterEditor
                controls={queryControls}
                interface={queryControlInterface}
                fields={findingColumnOptions}
                namespace="/finding"
                actions={filterActions}
              />
            </div>
            <div className={rootClasses.distinctCountContainer}>
              <Switch
                color={"primary"}
                onChange={handleDistinctChange}
                checked={isDistinctChecked()}
              />
              <p className={rootClasses.sectionText}>Apply Distinct Count</p>
            </div>
          </div>
          <div className={rootClasses.buttonContainer}>
            <Button
              color={"primary"}
              variant={"contained"}
              onClick={handleApplyFilters}
            >
              Apply
            </Button>
            <Button onClick={() => clearAdvancedFilters()} color={"primary"}>
              Clear
            </Button>
          </div>
        </div>
      );
    }
    return null;
  };

  const renderQuickFilters = () => (
    <div className={rootClasses.quickFilterContainer}>
      <span className={rootClasses.quickFilterLabel}>Open Findings</span>
      <ToggleButtonGroup
        exclusive
        value={"Quick filters"}
        onChange={handleApplyQuickFilter}
        aria-label="Quick filters for findings table"
      >
        {getFindingTableQuickFilters({
          findings: props.findings,
          isTableFilteredByType: !selectedQuickFilter.includes("All"),
        }).map(({ label }, index) => (
          <ToggleButton
            key={`${label}-${index}`}
            datacy={`index${index}QuickFilterButton`}
            value={label}
            aria-label={`Apply ${label} quick filter`}
            className={rootClasses.quickFilterButton}
            // this looks this way since the labels are dynamic and display a count at certain
            // times, so we are only concerned with the first word i.e. 'Suspect' or 'Threat'
            selected={
              label.includes(selectedQuickFilter.split("")[0]) ? true : false
            }
          >
            {label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
  );

  const renderFindingsData = () => {
    const doesContainSamples = props.findings.find(
      (finding) => finding.name && finding.name.includes("[SAMPLE]")
    );
    // If the org is a FREE edition org and doesn't have a cloud connector setup yet, then return the full list of findings
    // This will allow FREE orgs that have generated smaple findings to view those up until they setup a cloud connector
    if (
      props.license?.isTagged("limited-to-free") &&
      !props.integrations.length
    ) {
      return props.findings;
      // Otherwise, check to see if the findings list has any SAMPLE findings.  If it does then  filter them out.
    } else if (doesContainSamples) {
      return props.findings.filter((f) => !f.name.includes("[SAMPLE]"));
      // In all other cases, just return the findings list as it is
    } else {
      return props.findings;
    }
  };

  const handleAlertClose = () => {
    setErrorMessage("");
    setIsAlertVisible(false);
  };

  return (
    <Root>
      {isAlertVisible && errorMessage && (
        <Alert
          severity="error"
          className={rootClasses.exportAlert}
          onClose={handleAlertClose}
        >
          {errorMessage}
        </Alert>
      )}
      <SaveRequestModal open={saveOpen} refName="findings" onClose={onSave} />
      <SimpleTable
        isNorthStar
        maxBatchCount={250}
        data={renderFindingsData()}
        searchData={tableTextFilter}
        initialOrderBy={["created", "desc"]}
        columns={[
          ...(resultControls.transform &&
          resultControls.transform.find((t) => t.operator === "count")
            ? [distinctCountColumn]
            : []),
          ...activeColumns,
        ]}
        exportData={onExport}
        isExporting={isExporting}
        isFetching={!props.ready}
        contextMenuItems={
          isDistinctChecked()
            ? [{ onClick: handleMenuCopy, text: "Copy to Clipboard" }]
            : [
                {
                  onClick: handleViewDetails,
                  text: "View Finding Details",
                  datacy: "findingsContextMenuItemFindingsDetail",
                },
                { onClick: handleMenuCopy, text: "Copy to Clipboard" },
                {
                  onClick: handleOpenInNewTab,
                  link: findingLink,
                  text: "Open in New Tab",
                },
              ]
        }
        moreMenuItems={[
          {
            label: "Reset Search",
            onClick: (event) => {
              resetSearchPresets();
            },
          },
          {
            label: "Save Preset",
            onClick: (event) => {
              setSaveOpen(true);
            },
          },
          {
            label: "Scheduled Reports",
            onClick: (event) => {
              handleViewScheduledReports();
            },
            licenseRestriction: "report.create",
            datacy: "scheduledReportsPageBtn",
          },
        ]}
        toolbarActions={[
          {
            isPrimary: true,
            component: (
              <div className={rootClasses.inputContainer}>
                <p className={rootClasses.labelText}>Search presets</p>
                <div
                  className={rootClasses.textContainer}
                  onClick={togglePresetDialog}
                >
                  {viewingQueryLabel}
                </div>
              </div>
            ),
          },
          {
            isPrimary: true,
            component: (
              <TimeControls
                controls={queryControls}
                delta={delta}
                setDelta={setDelta}
                interface={queryControlInterface}
                style={{ marginBottom: -22 }}
              />
            ),
          },
          {
            tooltip: "Advanced",
            onClick: (event) => {
              toggleViewingFilters();
            },
          },
        ]}
        handleMultiSelection={handleMultiSelection}
        quickFilterComponent={renderQuickFilters()}
        advancedFilterComponent={renderAdvancedFilters()}
        multiselectOptions={isDistinctChecked() ? false : resolutionOptions}
        isFindingsTable={true}
      />
      <StyledDialog
        onClose={togglePresetDialog}
        open={isPresetDialogOpen}
        maxWidth={"md"}
      >
        <div className={dialogClasses.presetDialog}>
          <div className={dialogClasses.titleOuterContainer}>
            <div className={dialogClasses.titleContainer}>
              <TextField
                value={textFilter}
                label={"Search Presets"}
                onChange={handleTextFilterChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  disableUnderline: true,
                }}
              />
            </div>
            <IconButton onClick={togglePresetDialog}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className={dialogClasses.queryContainer}>
            {viewingQueries &&
              viewingQueries.map((q, i) => (
                <div
                  key={i}
                  className={dialogClasses.queryNameContainer}
                  onClick={() => handleSelectQuery(q.id)}
                >
                  <p>{q.name}</p>
                  {/*
                  if we have a global query show the global chip indicator
                  otherwise show a delete icon that allows the user to set a query
                  model to be deleted if they confirm after pressing the delete icon
                */}
                  {isGlobal(q) ? (
                    <Chip icon={<PublicIcon />} label={"Global"} /> // OR
                  ) : modelToDelete && modelToDelete.id === q.id ? (
                    <Fade in={modelToDelete.id === q.id} timeout={500}>
                      <div className={dialogClasses.confirmContainer}>
                        <p>are you sure?</p>
                        <Button
                          variant={"text"}
                          className={dialogClasses.confirmDelete}
                          onClick={handleDeleteQuery}
                        >
                          delete preset
                        </Button>
                        <Button
                          variant={"text"}
                          color={"primary"}
                          onClick={handleCancel}
                        >
                          cancel
                        </Button>
                      </div>
                    </Fade> // OR
                  ) : (
                    <DeleteIcon
                      color={"primary"}
                      onClick={(e) => toggleConfirmDelete(e, q)}
                    />
                  )}
                </div>
              ))}
          </div>
        </div>
      </StyledDialog>
      <FindingsResolutionDialog
        open={isResolutionNotesDialogOpen}
        onClose={toggleResolutionNotesDialog}
        handleResolveFindings={(notes) => handleResolveFindings(notes)}
      />
    </Root>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    reload: (force) => dispatch(loadPageData(force)),
    gotoFinding: ({ id, orgId }) => {
      dispatch({
        type: "PAGE",
        payload: {
          orgId,
          toplevel: "reporting",
          secondlevel: "findings",
          id1: id,
        },
      });
    },
    dispatchResolveFinding: ({ findingId, data }) => {
      dispatch(resolveFinding({ findingId, data }));
    },
    assignCurrentUserAsResponder: ({ ownerValues, findingId }) => {
      dispatch(
        setOwners({
          findingId: findingId,
          ownerType: "responder",
          owners: (ownerValues || []).map((i) => i.value),
        })
      );
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  const { location = {} } = state;
  const { query = {} } = location;

  const lookups = {
    jurisdiction: {
      10: "Administrator",
      20: "Manager",
      30: "Analyst",
      40: "Responder",
    },
    status: {
      0: "Open",
      10: "Analysis in Progress",
      20: "Analysis Complete",
      30: "Response in Progress",
      40: "Resolved",
    },
    type: {
      10: "Operational",
      15: "Risk",
      20: "Suspect",
      30: "Threat",
      40: "System",
    },
    category: {
      0: "Unknown",
      10: "Unauthorized Access Attempt",
      20: "Malicious Code",
      30: "Vulnerability Exploit Attempt",
      40: "Reconnaissance",
      50: "Denial of Service",
      60: "Policy Violation",
      70: "Assets",
      80: "Authentication",
      90: "Code Injection",
      100: "Collection",
      110: "Command and Control",
      120: "Command Line Logging",
      125: "Credential Access",
      130: "Defense Evasion",
      140: "Discovery/Reconnaissance",
      150: "EventIDs",
      160: "Execution",
      170: "Exfiltration",
      175: "Impact",
      180: "Initial Access",
      190: "Internal",
      200: "Lateral Movement",
      210: "Persistance",
      220: "Privilege Escalation",
      225: "resource Development",
      230: "Threats",
      240: "Users and Groups",
    },
    resolution: {
      10: "Valid",
      20: "False Positive",
      30: "No Action Needed",
      40: "Risk Accepted",
    },
    priority: {
      1: <Priority color="red" priority="1" />,
      2: <Priority color="#FDE03A" priority="2" />,
      3: <Priority color="gray" priority="3" />,
    },
    blocked: {
      false: "Not Blocked",
      true: "Blocked",
    },
  };

  if (ownProps.constants && ownProps.constants.length > 0) {
    Object.keys(lookups).forEach((l) => {
      if (ownProps.constants[0][l]) {
        ownProps.constants[0][l].forEach((s) => {
          lookups[l][s.id] = s.name;
        });
      }
    });
  }

  if (ownProps.users && ownProps.users.length > 0) {
    lookups.owners = {};
    ownProps.users.forEach((user) => {
      lookups.owners[user.id] = `${user.firstName} ${user.lastName}`;
    });
  }

  return {
    query,
    lookups,
    orgId: state.page.payload.orgId,
    findingId: state.location.payload.id1,
    license: state.license,
    currentUser: state.session.settings.user,
  };
};

FindingsView.defaultProps = {
  ready: false,
  users: [],
  queries: [],
  constants: [],
  findings: [],
  findingId: null,
  lookups: {
    jurisdiction: {},
    status: {},
    type: {},
    priority: {},
    blocked: {},
    owners: {},
  },
  integrations: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(FindingsView);
