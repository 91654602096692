import { styled } from "@mui/styles";

const blumiraBlue = "#007ffc";

const PREFIX = "LoginViewStyles";
export const rootClasses = {
  root: `${PREFIX}-root`,
  loginCard: `${PREFIX}-loginCard`,
  logo: `${PREFIX}-logo`,
  alert: `${PREFIX}-alert`,
  loadingContainer: `${PREFIX}-loadingContainer`,
  loadingText: `${PREFIX}-loadingText`,
  circularProgress: `${PREFIX}-circularProgress`,
  textContainer: `${PREFIX}-textContainer`,
  buttonContainer: `${PREFIX}-buttonContainer`,
  primaryButton: `${PREFIX}-primaryButton`,
};

export const Root = styled("div")(({ theme }) => ({
  [`&.${rootClasses.root}`]: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "#F4F6F8",
  },
  [`& .${rootClasses.loginCard}`]: {
    padding: 30,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    "& h2": {
      fontWeight: "normal",
    },
    "& p": {
      fontSize: 13,
    },
  },
  [`& .${rootClasses.logo}`]: {
    height: 50,
    marginBottom: 30,
  },
  [`& .${rootClasses.alert}`]: {
    width: 300,
    display: "flex",
    marginBottom: 15,
    alignItems: "center",
  },
  [`& .${rootClasses.loadingContainer}`]: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  [`& .${rootClasses.loadingText}`]: {
    fontSize: 20,
  },
  [`& .${rootClasses.circularProgress}`]: {
    color: blumiraBlue,
  },
  [`& .${rootClasses.textContainer}`]: {
    maxWidth: 650,
    padding: "30px 0",
    textAlign: "center",
  },
  [`& .${rootClasses.buttonContainer}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  [`& .${rootClasses.primaryButton}`]: {
    width: 280,
    padding: 15,
    fontSize: 16,
    borderRadius: 3,
    color: blumiraBlue,
    fontWeight: "normal",
    textTransform: "none",
    border: `1px solid ${blumiraBlue}`,
  },
}));
