const responseConnectorSchema = {
  $id: "/response/connector",
  title: "Response Connector",
  type: "object",
  properties: {
    id: { type: "string", format: "uuid", readOnly: true },
    integrationId: { type: "string", format: "uuid", createOnly: true },
    orgId: { type: "string", format: "uuid", createOnly: true },
    connectorTypeId: { type: "string", createOnly: true },
    configFields: { type: "object", properties: {} },
    secretFields: { type: "object", properties: {} },
    created: { type: "string", format: "date-time", readOnly: true },
    createdBy: { type: "string", format: "uuid", readOnly: true },
    modified: { type: "string", format: "date-time", readOnly: true },
    modifiedBy: { type: "string", format: "uuid", readOnly: true },
    deleted: { type: "string", format: "date-time", readOnly: true },
    deletedBy: { type: "string", format: "uuid", readOnly: true },
  },
  required: ["orgId", "connectorTypeId"],
  additionalProperties: false,
};

export default responseConnectorSchema;
