import React from "react";
import PropTypes from "prop-types";
import API from "lib/api/API2";

import ActionDialog from "views/Components/ActionDialog";
import { Status } from "views/Components/Finding/ResponseDialog";

const ResponsePending = ({
  activeWorkflow,
  currentAction,
  currentStatus,
  setCurrentStatus,
  displayMessage,
  logger,
  orgId,
}) => {
  const handleRunningAction = async () => {
    setCurrentStatus(Status.RUNNING_ACTION);

    const payload = {
      orgId: orgId,
      targetFields: activeWorkflow.targetFields.map((field) => ({
        value: currentAction[field.evidenceFieldName],
        workflowInputName: field.workflowInputName,
      })),
      connectorFields: activeWorkflow.connectorFields.map((field) => ({
        connectorFieldName: field.connectorFieldName,
        responseConnectorType: field.responseConnectorType,
        value: currentAction[field.evidenceFieldName],
        workflowInputName: field.workflowInputName,
      })),
      workflowId: activeWorkflow.responseWorkflowId,
    };

    try {
      const api = new API();
      const response = await api.post(`/response/runWorkflowSync`, payload, []);

      const msg = response?.data?.message;
      if (response?.data?.success === "true") {
        displayMessage({
          severity: "success",
          message: msg || "Successfully ran response action",
          autoHideDuration: 10000,
        });
      } else {
        throw new Error(msg);
      }

      setCurrentStatus(Status.SUCCESS_ACTION);
    } catch (err) {
      displayMessage({
        severity: "error",
        message: err.message || "Failed to run response action",
        noAutoHide: true,
      });
      logger.error(err);
      setCurrentStatus(Status.FAILED_ACTION);
    }
  };

  const DescriptionPending = () => {
    let ret = `${activeWorkflow.buttonName}: `;
    ret += activeWorkflow.targetFields
      .map((field) => currentAction[field.evidenceFieldName])
      .join(" ");
    return (
      <>
        <p>{ret}</p>
        <p>This action may require admin intervention to reverse.</p>
      </>
    );
  };

  return (
    <ActionDialog
      open={
        currentStatus === Status.PENDING_ACTION ||
        currentStatus === Status.RUNNING_ACTION
      }
      title="Are you sure?"
      description={<DescriptionPending />}
      actions={[
        {
          title: "Cancel",
          action: () => setCurrentStatus(Status.NONE),
        },
        {
          title: "Yes, I'm sure",
          action: handleRunningAction,
          variant: "contained",
          loading: currentStatus === Status.RUNNING_ACTION,
        },
      ]}
    />
  );
};

ResponsePending.propTypes = {
  activeWorkflow: PropTypes.object.isRequired,
  currentAction: PropTypes.object.isRequired,
  currentStatus: PropTypes.string.isRequired,
  setCurrentStatus: PropTypes.func.isRequired,
  displayMessage: PropTypes.func.isRequired,
  logger: PropTypes.object.isRequired,
  orgId: PropTypes.string.isRequired,
};

export default ResponsePending;
