import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import ErrorIcon from "@mui/icons-material/Error";
import PendingIcon from "@mui/icons-material/Pending";

const ActionDialog = (props) => {
  const [displayedDescription, setDisplayedDescription] = useState(
    props.description
  );
  const [displayedTitle, setDisplayedTitle] = useState(props.title);

  // Avoid changing description on close to avoid "flashing" of a
  // new description if `description` is dependent on `open`.
  useEffect(() => {
    if (props.open && props.description) {
      setDisplayedDescription(props.description);
    }
  }, [props.description]);

  // See warning for description
  useEffect(() => {
    if (props.open && props.title) {
      setDisplayedTitle(props.title);
    }
  }, [props.title]);

  const handleClick = (action) => {
    action();
  };

  return (
    <Dialog open={props.open} maxWidth={"sm"} fullWidth>
      <DialogTitle id="alert-dialog-title">{displayedTitle}</DialogTitle>
      <DialogContent>{displayedDescription}</DialogContent>
      <Divider sx={{ marginLeft: 3, marginRight: 3 }} />
      <DialogActions sx={{ padding: 2.75 }}>
        {props.actionsInfo && (
          <Box sx={{ marginRight: "auto" }}>{props.actionsInfo}</Box>
        )}
        {props.actions.map((action, idx) => {
          let Component;
          const loadingProps = {};
          if (action.loading === undefined) {
            Component = Button;
          } else {
            Component = LoadingButton;
            loadingProps.loading = action.loading;
            if (action.loading) {
              // The pending icon is only visible for one tick.
              // It's mainly there to make space for the loading animation.
              loadingProps.loadingPosition = "end";
              loadingProps.endIcon = <PendingIcon />;
            } else if (action.error) {
              loadingProps.endIcon = <ErrorIcon />;
            }
          }
          return (
            <Component
              key={action.title}
              datacy={action.datacy}
              onClick={() => handleClick(action.action)}
              variant={action.variant ? action.variant : "text"}
              disabled={action.disabled ? action.disabled : false}
              // Theme colors are proving to be tricky on
              // MUI buttons, so adding to styles for now
              style={
                action.backgroundColor
                  ? {
                      color: action.textColor,
                      backgroundColor: action.backgroundColor,
                    }
                  : {}
              }
              color={action.backgroundColor ? "inherit" : "primary"}
              {...loadingProps}
            >
              {action.title}
            </Component>
          );
        })}
      </DialogActions>
    </Dialog>
  );
};

export default ActionDialog;
