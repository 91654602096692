const findingSchema = {
  $id: "/finding",
  title: "Finding",
  type: "object",
  properties: {
    id: { type: "string", format: "uuid", readOnly: true },
    orgId: { type: "string", format: "uuid" },
    analysis: { type: "string" },
    attachments: { type: "array", readOnly: true },
    bdfNotifications: { type: "array", readOnly: true },
    blocked: { type: "boolean" },
    category: { type: "number" },
    matchId: { type: "string" },
    name: { type: "string" },
    priority: { type: "number" },
    summary: { type: "string" },
    type: { type: "number" },
    created: { type: "string", format: "date-time", readOnly: true },
    createdBy: { type: "string", format: "uuid", readOnly: true },
    confirmation: { type: "array" },
    confirmationCompleted: {
      type: "string",
      format: "date-time",
      readOnly: true,
    },
    escalation: { type: "array" },
    escalationCompleted: {
      type: "string",
      format: "date-time",
      readOnly: true,
    },
    evidence: { type: "array" },
    jurisdiction: { type: "number" },
    mitigation: { type: "array" },
    mitigationCompleted: {
      type: "string",
      format: "date-time",
      readOnly: true,
    },
    orgLocationIds: { type: "array" },
    owners: { type: "array" },
    promotedToId: { type: "string", readOnly: true },
    relatedFindings: { type: "array" },
    resolution: { type: "number" },
    resolutionNotes: { type: "string" },
    srcCountry: { type: "array" },
    status: { type: "number" },
    statusModified: { type: "string", format: "date-time" },
    statusModifiedBy: { type: "string" },
    modified: { type: "string", format: "date-time", readOnly: true },
    modifiedBy: { type: "string", format: "uuid", readOnly: true },
    shortId: { type: "string", readOnly: true },
    workflows: { type: "array" },
    // ATTENTION ATTENTION
    // remove EVERYTHING BELOW when API is updated to handle these transforms
    mAvgSecondsToStatus: { type: "number" },
    seconds_to_status: { type: "number" },
    count: { type: "number" },
    // remove EVERYTHING ABOVE when API is updated to handle these transforms
    // ATTENTION ATTENTION
  },
  required: [
    "orgId",
    "analysis",
    "blocked",
    "category",
    "matchId",
    "matched",
    "name",
    "priority",
    "summary",
    "type",
  ],
  additionalProperties: false,
};

export default findingSchema;
