import { BaseModel } from "./BaseModel";

export const MessageType = {
  call: 10,
  comment: 20,
  email: 30,
  sms: 40,
  flash: 50,
};

export class Message extends BaseModel {
  schema = "/message";
  constructor(params) {
    return super(params, "/message");
  }
}
