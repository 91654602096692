import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { loadPageData } from "redux/actions/Request";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Skeleton,
} from "@mui/material";

import { Root, IntegrationStatusDiv, classes } from "./styles";

import FlagIcon from "@mui/icons-material/Flag";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveIcon from "@mui/icons-material/Remove";

import AlertMessage, { useAlertMessage } from "views/Components/AlertMessage";
import FindingCardList from "views/Components/Finding/Card/List";
import RunTestDialog from "views/Components/RunTestDialog";
import VisualizationList from "views/Components/VisualizationList";
import { popularReports } from "../PopularReports/popularReportsList";
import FunnelVisualization from "views/Components/FunnelVisualization";
import SearchableListDialog from "views/Components/SearchableListDialog";
import { isMicrosoft, isGlobal } from "views/Components/Reporting/helpers";
import MoreDetectionCoverageDialog from "views/Components/MoreDetectionCoverageDialog";

import { INTEGRATION_STATUS } from "../../Components/CloudModules/constants";

import API from "lib/api/API2";
import Logger from "lib/logger";
import Request from "lib/api/Request";

import { DemoAPI } from "../../../lib/api";

import { accountAdministrationHref } from "views/Pages/WelcomePageView";

const moment = require("moment-timezone");

const DEMO_API = { demo: new DemoAPI() };

export const filterReports = (queries, license) => {
  if (license.isTagged("limited-to-microsoft")) {
    return queries
      .filter((o) => isGlobal(o))
      .filter((o) => isMicrosoft(o))
      .filter((o) => o.uiVisible);
  } else if (license.isTagged("limited-to-free")) {
    // return only global for FREE but do not restrict to microsoft
    return queries.filter((o) => o.uiVisible).filter((o) => isGlobal(o));
  }
  return queries.filter((o) => o.uiVisible);
};

const SummaryDashboard = (props) => {
  const api = new API();
  const logger = Logger("SummaryDashboard");

  const [alertMessageProps, displayMessage] = useAlertMessage();
  const [visibleReports, setVisibleReports] = useState(null);
  const [loadingFindings, setLoadingFindings] = useState(false);
  const [isUsersDialogOpen, setIsUsersDialogOpen] = useState(false);
  const [visibleSavedQueries, setVisibleSavedQueries] = useState(null);
  const [isReportsDialogOpen, setIsReportsDialogOpen] = useState(false);
  const [isRunTestDialogOpen, setIsRunTestDialogOpen] = useState(false);
  const [visibleFindingCount, setVisibleFindingCount] = useState(null);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [isDetectionRulesDialogOpen, setIsDetectionRulesDialogOpen] =
    useState(false);
  const [isDetectionCoverageDialogOpen, setIsDetectionCoverageDialogOpen] =
    useState(false);
  const [visibleCloudConnectors, setVisibleCloudConnectors] = useState([]);
  const [enabledRules, setEnabledRules] = useState(null);
  const [users, setUsers] = useState(null);

  const fetchEnabledRules = async () => {
    const response = await api.get(
      "/rule",
      { orderBy: "name", outputFields: "name", status: 1 },
      []
    );
    setEnabledRules(response?.data || []);
  };

  const fetchReports = async (savedQueries) => {
    let report = popularReports.find((pr) => pr.id === 1050);
    report.model = savedQueries.find((sq) => sq.id === report.id);
    if (report.model) {
      report.request = new Request("/bq");
      report.request.fromQuery(report.model);
      report.request.setResultParam("outputFields", [report.field]);
      report.request.setResultParam("transform", [
        { operator: "count", value: "grouped_count" },
      ]);
      report.request.setResultParam("orderBy", [
        { key: "grouped_count", value: -1 },
      ]);
      // clear any queryParams already dealing with date
      Object.keys(report.request.queryParams).forEach((uuid) => {
        if (report.request.queryParams[uuid].field === "created") {
          report.request.deleteQueryParam(uuid);
        }
      });

      report.request.setQueryParam({
        field: "created",
        operator: "gt",
        value: moment().add(-6, "months").format("YYYY-MM-DDTHH:mm"),
      });

      report.request.setQueryParam({
        field: "created",
        operator: "lt",
        value: moment().format("YYYY-MM-DDTHH:mm"),
      });

      report.request.get().then((results) => {
        const formattedResults = report.formatFunction(results);
        report.data = formattedResults;
        setVisibleReports([report]);
      });
    }
  };

  const fetchSavedQueries = async () => {
    const response = await api.get(
      "/query",
      { "namespace.eq": "bq", orderBy: "name" },
      []
    );
    const savedQueries = response?.data || [];
    setVisibleSavedQueries(filterReports(savedQueries, props.license));
    fetchReports(savedQueries);
  };

  const fetchUsers = async () => {
    const response = await api.get(
      "/user",
      { orderBy: "email", outputFields: "email" },
      []
    );
    setUsers(response?.data || []);
  };

  useEffect(() => {
    setEnabledRules(null);
    setUsers(null);
    setVisibleReports(null);
    setVisibleSavedQueries(null);

    fetchEnabledRules().catch((err) => {
      logger.error("Error fetching rules:", err);
    });

    // This also fetches reports
    fetchSavedQueries().catch((err) => {
      logger.error("Error fetching queries/reports:", err);
    });

    fetchUsers().catch((err) => {
      logger.error("Error fetching users:", err);
    });
  }, [props.orgId]);

  useEffect(() => {
    if (props.ready) {
      // If the org has more than 3 connectors setup, only show the first 3
      if (props.integrations?.length > 3) {
        const threeConnectors = props.integrations?.slice(0, 3);
        setVisibleCloudConnectors(threeConnectors);
        // Otherwise, show all of them
      } else {
        setVisibleCloudConnectors(props.integrations);
      }
    }
  }, [props.ready, props.integrations]);

  const toggleReportsDialog = () => {
    setIsReportsDialogOpen(!isReportsDialogOpen);
  };

  const toggleUsersDialog = () => {
    setIsUsersDialogOpen(!isUsersDialogOpen);
  };

  const toggleDetectionRulesDialog = () => {
    setIsDetectionRulesDialogOpen(!isDetectionRulesDialogOpen);
  };

  const toggleDetectionCoverageDialog = () => {
    setIsDetectionCoverageDialogOpen(!isDetectionCoverageDialogOpen);
  };

  const toggleRunTestDialog = () => {
    setIsRunTestDialogOpen(!isRunTestDialogOpen);
  };

  const toggleConfirmationModal = () => {
    setIsConfirmationDialogOpen(!isConfirmationDialogOpen);
  };

  const handleAdditionalInfoClick = () => {
    setIsDetectionRulesDialogOpen(false);
    setIsDetectionCoverageDialogOpen(true);
  };

  const handleReportItemClick = (reportId) => {
    if (reportId) props.routeToSavedReport(reportId, props.orgId);
  };

  const handleCloudConnectorClick = () => {
    if (props.integrations?.length) props.routeToCloudConnector(props.orgId);
  };

  const handleGenerateData = async () => {
    const data = {
      org_id: props.orgId,
      launch: true,
      reset: true,
    };

    setLoadingFindings(true);
    toggleConfirmationModal();

    await DEMO_API.demo.resetDemo({
      orgId: props.orgId,
      data,
    });

    // account for generation time
    setTimeout(() => {
      setLoadingFindings(false);
      props.reload(true);
    }, 3500);
  };

  const canGenerateFindings = () => {
    // page is loaded with data, no findings, license is defined and free, and they don't have a CC setup
    return (
      !!props.ready &&
      !visibleFindingCount &&
      props?.license?.isTagged("limited-to-free") &&
      !props.integrations?.length
    );
  };

  const integrationStatus = (connector) => {
    if (connector.integrationStatus === INTEGRATION_STATUS.INIT) {
      return (
        <IntegrationStatusDiv className={classes.row}>
          <CircularProgress size={20} className={classes.statusIcon} />
          <p className={classes.statusText}>Initializing…</p>
        </IntegrationStatusDiv>
      );
    } else if (connector.integrationStatus === INTEGRATION_STATUS.OK) {
      return (
        <IntegrationStatusDiv className={classes.row}>
          <CheckCircleIcon
            className={`${classes.statusIcon} ${classes.success}`}
          />
          <p className={classes.statusText}>Connected</p>
        </IntegrationStatusDiv>
      );
    } else if (connector.integrationStatus === INTEGRATION_STATUS.ERROR) {
      return (
        <IntegrationStatusDiv className={classes.row}>
          <ErrorIcon className={`${classes.statusIcon} ${classes.error}`} />
          <p className={classes.statusText}>Needs Attention</p>
        </IntegrationStatusDiv>
      );
    } else {
      return (
        <IntegrationStatusDiv className={classes.row}>
          {connector.integrationStatus}
        </IntegrationStatusDiv>
      );
    }
  };

  return (
    <Root className={classes.page}>
      <AlertMessage {...alertMessageProps} />
      <div className={classes.topRowContainer}>
        <FunnelVisualization
          ready={props.ready}
          findings={visibleFindingCount}
          integrations={visibleCloudConnectors}
          orgId={props.orgId}
        />
        <div
          className={`${classes.baseContainer} ${classes.rulesContainer}`}
          onClick={toggleDetectionRulesDialog}
          datacy={"detectionRulesComponent"}
        >
          <p className={classes.dataTitle}>Detection rules</p>
          <p className={classes.dataText}>
            {enabledRules === null ? (
              <Skeleton animation="wave" height={40} width={40} />
            ) : (
              enabledRules.length
            )}
          </p>
        </div>
        <div
          className={`${classes.baseContainer} ${classes.connectorStatusContainer}`}
          onClick={handleCloudConnectorClick}
          datacy={"cloudConnectorComponent"}
        >
          <p className={classes.dataTitle}>
            {props.integrations?.length > 1
              ? "Cloud Connectors"
              : "Cloud Connector"}
          </p>
          {props.integrations?.length ? (
            visibleCloudConnectors?.map((c) => (
              <div key={c.id}>
                <p className={classes.connectorName}>{c.integrationName}</p>
                <div className={classes.status}>
                  {integrationStatus(c)}
                  <RemoveIcon fontSize={"small"} className={classes.dashIcon} />
                  {c.integrationStatus === INTEGRATION_STATUS.ERROR ? (
                    <p className={classes.restoreText}>
                      Click here to restore logging
                    </p>
                  ) : (
                    <p className={classes.statusText}>
                      Last log event detected:{" "}
                      {moment
                        .utc(c.lastStatusUpdate)
                        .tz(moment.tz.guess(true))
                        .fromNow()}
                    </p>
                  )}
                </div>
              </div>
            ))
          ) : props.ready && !props.integrations?.length ? (
            <p className={classes.dataTitle}>No Cloud Connector detected</p>
          ) : (
            <p className={classes.dataTitle}>
              Gathering Cloud Connector data...
            </p>
          )}
          {!props.integrations?.length && !props.ready && (
            <div className={classes.status}>
              <CircularProgress />
            </div>
          )}
        </div>
        <div
          className={`${classes.baseContainer} ${classes.testRunContainer}`}
          onClick={toggleRunTestDialog}
          datacy={"runTestComponent"}
        >
          <FlagIcon />
          <p>Run a test</p>
        </div>
      </div>
      <div className={classes.topRowContainer}>
        <div
          className={`${classes.baseContainer} ${classes.reportNumberContainer}`}
          onClick={toggleUsersDialog}
          datacy={"usersComponent"}
        >
          <p className={classes.dataTitle}>Users</p>
          <p className={classes.dataText}>
            {users ? (
              users.length
            ) : (
              <Skeleton animation="wave" height={40} width={40} />
            )}
          </p>
        </div>
        <div
          className={`${classes.baseContainer} ${classes.reportNumberContainer}`}
          onClick={toggleReportsDialog}
          datacy={"reportsComponent"}
        >
          <p className={classes.dataTitle}>Reports</p>
          <p className={classes.dataText}>
            {visibleSavedQueries ? (
              visibleSavedQueries.length
            ) : (
              <Skeleton animation="wave" height={40} width={40} />
            )}
          </p>
        </div>
        {visibleReports?.length ? (
          <VisualizationList
            theme={props.theme}
            listData={visibleReports}
            isLoading={!props.ready}
            standAlone={true}
          />
        ) : (
          <div
            className={`${classes.baseContainer} ${classes.popReportPlaceholder}`}
          >
            {visibleReports === null ? (
              <>
                <p>Loading your report overview...</p>
                <CircularProgress size={30} />
              </>
            ) : (
              <p>Nothing to report, yet...</p>
            )}
          </div>
        )}
      </div>
      <div className={classes.topRowContainer}>
        <div className={classes.sortedDataContainer}>
          <p className={classes.findingNumberText}>
            {visibleFindingCount
              ? `${visibleFindingCount} Unresolved Findings`
              : "No Unresolved Findings"}
          </p>
          {!!visibleFindingCount && (
            <p className={classes.sortedDataText}>
              Past 6 months, sorted by newest first
            </p>
          )}
        </div>
        {canGenerateFindings() && (
          <Button
            variant={"contained"}
            className={classes.red}
            onClick={toggleConfirmationModal}
            disabled={!!loadingFindings}
            datacy={"generateSampleFindingsBtn"}
          >
            {loadingFindings
              ? "Generating sample findings"
              : "Generate sample findings"}
          </Button>
        )}
      </div>
      <FindingCardList
        displayMessage={displayMessage}
        integrations={props.integrations}
        license={props.license}
        loading={loadingFindings}
        orgId={props.orgId}
        ready={props.ready}
        setLoading={setLoadingFindings}
        setFindingCount={setVisibleFindingCount}
      />
      <SearchableListDialog
        title={"Reports"}
        items={visibleSavedQueries || []}
        open={isReportsDialogOpen}
        onClose={toggleReportsDialog}
        onItemClick={handleReportItemClick}
      />
      <SearchableListDialog
        title={"Users"}
        items={users || []}
        open={isUsersDialogOpen}
        onClose={toggleUsersDialog}
        searchableProperty={"email"}
        handleAdditionalInfoClick={accountAdministrationHref}
        additionalInfo={"Administering Blumira User Accounts"}
      />
      <SearchableListDialog
        items={enabledRules || []}
        title={"Detection rules"}
        open={isDetectionRulesDialogOpen}
        onClose={toggleDetectionRulesDialog}
        additionalInfo={"Get more detection coverage"}
        handleAdditionalInfoClick={handleAdditionalInfoClick}
        isAdditionalInfoAButton={true}
        emptyText={
          "Detection rules will appear as soon as Blumira processes your incoming log data. This can take up to 60 minutes after you set up your first Cloud Connector or Sensor."
        }
      />
      <MoreDetectionCoverageDialog
        open={isDetectionCoverageDialogOpen}
        onClose={toggleDetectionCoverageDialog}
      />
      <RunTestDialog open={isRunTestDialogOpen} onClose={toggleRunTestDialog} />
      <Dialog open={isConfirmationDialogOpen}>
        <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
        <DialogContent>
          <strong>WARNING:</strong>
          <p>
            Generating new findings will take a few moments and add example data
            to your account so that you can learn what Blumira findings look
            like and how to respond to them.
          </p>
          <p>
            The activity in these sample findings is not real and is not based
            on data your organization sent to Blumira.
          </p>
          <p>
            These findings will send multiple email notifications to users who
            have the default notification settings.
          </p>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            onClick={handleGenerateData}
            className={classes.red}
            variant={"contained"}
            datacy={"confirmButton"}
          >
            Confirm
          </Button>
          <Button
            onClick={toggleConfirmationModal}
            color="primary"
            variant={"outlined"}
            datacy={"cancelButton"}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Root>
  );
};

SummaryDashboard.defaultProps = {
  findings: [],
};

const mapStateToProps = (state) => ({
  license: state.license,
  orgId: state.location.payload.orgId,
});

const mapDispatchToProps = (dispatch) => {
  return {
    routeToSavedReport: (reportId, orgId) => {
      dispatch({
        type: "PAGE",
        payload: {
          orgId: orgId,
          id1: reportId,
          toplevel: "reporting",
          secondlevel: "builder",
        },
      });
    },
    routeToCloudConnector: (orgId) => {
      dispatch({
        type: "PAGE",
        payload: {
          orgId,
          toplevel: "settings",
          secondlevel: "cloudmodules",
        },
      });
    },
    reload: (force) => dispatch(loadPageData(force)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SummaryDashboard);
