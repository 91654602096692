import { styled } from "@mui/material/styles";
import { Dialog } from "@mui/material";

const PREFIX = "MSPDetailsDialog";
export const tabDialogClasses = {
  dialogPaper: `${PREFIX}-dialogPaper`,
  innerDialogPaper: `${PREFIX}-innerDialogPaper`,
  tabs: `${PREFIX}-tabs`,
  tabPanel: `${PREFIX}-tabPanel`,
  alert: `${PREFIX}-alert`,
  textField: `${PREFIX}-textField`,
  formControl: `${PREFIX}-formControl`,
  formLabel: `${PREFIX}-formLabel`,
  formControlLabel: `${PREFIX}-formControlLabel`,
  subHeaderText: `${PREFIX}-subHeaderText`,
  dialogActions: `${PREFIX}-dialogActions`,
  dialogActionsColumn: `${PREFIX}-dialogActionsColumn`,
  progressDotsCol: `${PREFIX}-progressDotsCol`,
  dialogActionsRightColumnWrapper: `${PREFIX}-dialogActionsRightColumnWrapper`,
  dialogActionsLeftColumnWrapper: `${PREFIX}-dialogActionsLeftColumnWrapper`,
  confirmText: `${PREFIX}-confirmText`,
  dialogActionsCenterColumnWrapper: `${PREFIX}-dialogActionsCenterColumnWrapper`,
  tableContainer: `${PREFIX}-tableContainer`,
  multipleCSV: `${PREFIX}-multipleCSV`,
  csvTemplate: `${PREFIX}-csvTemplate`,
  rotateIcon: `${PREFIX}-rotateIcon`,
  dataRetentionWarningText: `${PREFIX}-dataRetentionWarningText`,
  agentSeatCountTextContainer: `${PREFIX}-agentSeatCountTextContainer`,
  agentSeatPrimaryText: `${PREFIX}-agentSeatPrimaryText`,
  agentSeatSecondaryText: `${PREFIX}-agentSeatSecondaryText`,
  mapToText: `${PREFIX}-mapToText`,
  mappingWrapper: `${PREFIX}-mappingWrapper`,
  mappingSuccess: `${PREFIX}-mappingSuccess`,
  mappingFail: `${PREFIX}-mappingFail`,
};

export const confirmDialogClasses = {
  innerDialogPaper: `${PREFIX}-innerDialogPaper`,
  dialogActionsColumn: `${PREFIX}-dialogActionsColumn`,
  dialogActionsRightColumnWrapper: `${PREFIX}-dialogActionsRightColumnWrapper`,
};

export const removeDialogClasses = {
  alert: `${PREFIX}-alert`,
  dialogActions: `${PREFIX}-dialogActions`,
  dialogWrapper: `${PREFIX}-dialogWrapper`,
  title: `${PREFIX}-title`,
  textContainer: `${PREFIX}-textContainer`,
  radioGroup: `${PREFIX}-radioGroup`,
  radio: `${PREFIX}-radio`,
  extraText: `${PREFIX}-extraText`,
};

export const StyledTabsDialog = styled(Dialog)(({ theme }) => ({
  [`& .${tabDialogClasses.dialogPaper}`]: {
    minHeight: "80vh",
    maxHeight: "80vh",
  },
  [`& .${tabDialogClasses.tabs}`]: {
    marginTop: -theme.shape.padding,
    borderBottom: `1px solid ${theme.palette.divider}`,
    "& span": {
      textTransform: "none",
    },
  },
  [`& .${tabDialogClasses.tabPanel}`]: {
    display: "flex",
    flexDirection: "column",
    padding: `${theme.shape.padding}px 0 ${
      theme.shape.padding * 4
    }px 0 !important`,
  },
  [`& .${tabDialogClasses.alert}`]: {
    marginTop: theme.shape.padding,
  },
  [`& .${tabDialogClasses.textField}`]: {
    marginTop: theme.shape.padding * 2,
  },
  [`& .${tabDialogClasses.formControl}`]: {
    marginTop: theme.shape.padding * 2,
    padding: "0 24px 24px 24px",
  },
  [`& .${tabDialogClasses.formLabel}`]: {
    fontWeight: "bold",
    color: theme.palette.text.primary,
    marginBottom: theme.shape.padding * 2,
  },
  [`& .${tabDialogClasses.formControlLabel}`]: {
    marginTop: -theme.shape.padding,
  },
  [`& .${tabDialogClasses.subHeaderText}`]: {
    fontSize: 15,
    fontWeight: "bold",
  },
  [`& .${tabDialogClasses.dialogActions}`]: {
    padding: theme.shape.padding,
    borderTop: `1px solid ${theme.palette.divider}`,
    justifyContent: "unset",
  },
  [`& .${tabDialogClasses.dialogActionsColumn}`]: {
    flex: "1.4",
    display: "flex",
    justifyContent: "center",
    marginLeft: "0 !important",
  },
  [`& .${tabDialogClasses.progressDotsCol}`]: {
    flex: "0.5",
  },
  [`& .${tabDialogClasses.dialogActionsLeftColumnWrapper}`]: {
    display: "block",
    marginRight: "auto",
  },
  [`& .${tabDialogClasses.confirmText}`]: {
    margin: 0,
    fontSize: 10,
  },
  [`& .${tabDialogClasses.dialogActionsCenterColumnWrapper}`]: {
    display: "block",
  },
  [`& .${tabDialogClasses.dialogActionsRightColumnWrapper}`]: {
    display: "block",
    marginLeft: "auto",
  },
  [`& .${tabDialogClasses.tableContainer}`]: {
    marginTop: theme.shape.padding,
  },
  [`& .${tabDialogClasses.multipleCSV}`]: {
    paddingTop: 25,
  },
  [`& .${tabDialogClasses.csvTemplate}`]: {
    marginTop: "50px",
    textAlign: "center",

    "& a": {
      color: theme.palette.primary.main,
    },
  },
  [`& .${tabDialogClasses.rotateIcon}`]: {
    animation: "$spin 2s linear infinite",
  },
  "@keyframes spin": {
    "0%": {
      transform: "rotate(360deg)",
    },
    "100%": {
      transform: "rotate(0deg)",
    },
  },
  [`& .${tabDialogClasses.dataRetentionWarningText}`]: {
    fontSize: "0.75rem", // to match helper text on MUI inputs
    "& a": {
      color: theme.palette.primary.main,
    },
  },
  [`& .${tabDialogClasses.agentSeatCountTextContainer}`]: {
    marginTop: -10,
    marginLeft: theme.shape.padding * 2,
  },
  [`& .${tabDialogClasses.agentSeatPrimaryText}`]: {
    fontSize: "1rem",
    marginBottom: 5,
  },
  [`& .${tabDialogClasses.agentSeatSecondaryText}`]: {
    fontSize: "0.75rem",
    color: theme.palette.text.secondary,
    "& a": {
      color: theme.palette.primary.main,
      marginLeft: theme.shape.padding,
    },
    "& svg": {
      fontSize: "0.75rem",
    },
  },
  [`& .${tabDialogClasses.mappingWrapper}`]: {
    display: "flex",
    flexDirection: "column",
    padding: 24,
  },
  [`& .${tabDialogClasses.mapToText}`]: {
    margin: "2rem 0",
  },
  [`& .${tabDialogClasses.mappingSuccess}, & .${tabDialogClasses.mappingFail}`]:
    {
      alignItems: "center",
      display: "flex",
      svg: {
        marginRight: 4,
      },
    },
  [`& .${tabDialogClasses.mappingSuccess}`]: {
    color: theme.palette.success.main,
  },
  [`& .${tabDialogClasses.mappingFail}`]: {
    color: theme.palette.error.main,
  },
}));

export const StyledConfirmationDialog = styled(Dialog)(({ theme }) => ({
  [`& .${confirmDialogClasses.innerDialogPaper}`]: {
    width: 450,
  },
  [`& .${confirmDialogClasses.dialogActionsColumn}`]: {
    flex: "1.4",
    display: "flex",
    justifyContent: "center",
    marginLeft: "0 !important",
  },
  [`& .${confirmDialogClasses.dialogActionsRightColumnWrapper}`]: {
    display: "block",
    marginLeft: "auto",
  },
}));

export const StyledRemoveDialog = styled(Dialog)(({ theme }) => ({
  [`& .${removeDialogClasses.alert}`]: {
    marginBottom: "12px",
    marginTop: "5px",
  },
  [`& .${removeDialogClasses.dialogActions}`]: {
    padding: theme.shape.padding,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  [`& .${removeDialogClasses.dialogWrapper}`]: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px 12px 0 24px",
  },
  [`& .${removeDialogClasses.title}`]: {
    padding: 0,
  },
  [`& .${removeDialogClasses.textContainer}`]: {
    marginBottom: "24px",
  },
  [`& .${removeDialogClasses.radioGroup}`]: {
    marginBottom: "40px",
  },
  [`& .${removeDialogClasses.radio}`]: {
    marginBottom: 0,
  },
  [`& .${removeDialogClasses.extraText}`]: {
    padding: "0 0 0 32px",
    fontSize: "14px",
    "& p": {
      marginBottom: "8px",
    },
  },
}));
