import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Alert,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import API from "lib/api/API2";
import { LICENSE_TYPES } from "views/Pages/OrganizationPageView";
import { Message, MessageType } from "lib/models/Message";
import { removeDialogClasses, StyledRemoveDialog } from "./styles";

const REMOVAL_OPTIONS = [
  {
    value: "permanent",
    label: "Permanently remove this account and its data from Blumira",
  },
  {
    value: "self_managed",
    label: "Continue as a self-managed Blumira account",
  },
  { value: "different_msp", label: "Become managed by a different MSP" },
  {
    value: "unknown",
    label: "Unknown; the sub-account will contact Blumira support",
  },
];

let SUPPORT_EMAIL = "support@blumira.com";
let MSP_SUPPORT_EMAIL = "msp@blumira.com";
if (process.env.NODE_ENV === "development") {
  SUPPORT_EMAIL = "jvoigts+test-support@blumira.com";
  MSP_SUPPORT_EMAIL = "jvoigts+test-msp@blumira.com";
}

const sendEmail = async (recipient, content) => {
  const message = new Message({
    recipient: recipient,
    ...content,
  });
  return message.create();
};

const MSPRemoveAccountDialog = ({
  currentUserEmail,
  displayMessage,
  model,
  onClose,
  open,
  reload,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [accountNameInput, setAccountNameInput] = useState("");
  const [validates, setValidates] = useState(false);

  const reset = () => {
    setSelectedOption(null);
    setAccountNameInput("");
    setValidates(false);
  };

  // Reset when opening
  useEffect(() => {
    if (open) {
      reset();
    }
  }, [open]);

  // Validate whenever relevant dependencies change
  useEffect(() => {
    // Make sure model.name exists and matches the input
    const nameMatches =
      model?.name?.trim() && accountNameInput.trim() === model?.name;
    const isValid = !!selectedOption && nameMatches;
    setValidates(isValid);
  }, [selectedOption, accountNameInput, model?.name]);

  const handleOptionChanged = (event) => {
    setSelectedOption(event.target.value);
  };

  const renderFullLicenseName = (license) => {
    return LICENSE_TYPES.find((l) => l.value === license)?.label;
  };

  const handleAccountNameChange = (event) => {
    setAccountNameInput(event.target.value);
  };

  const handleRemoval = async () => {
    if (selectedOption === "permanent") {
      // Workflow A
      // ==========
      //
      // Send email:
      // - Send to support with a list of changes and decommissioned
      //   activity selected/performed (support@blumira.com and CC
      //   msp@blumira.com).
      // - Send email to person that deleted it with recap.

      try {
        const api = new API();
        await api.post(`/org/deprovision/${model.id}`, {});
        displayMessage({
          severity: "success",
          message: `Successfully triggered removal of ${model.name}`,
          noAutoHide: true,
        });

        // Send emails
        const content = {
          subject: `Subaccount removal was triggered for ${model.name}`,
          body:
            `An MSP has deprovisioned a subaccount. It will be fully ` +
            `deleted at the end of the billing cycle.\n\n` +
            `User who initiated the removal: ${currentUserEmail}\n` +
            `Org name: ${model.name}\n` +
            `Org id: ${model.id}\n` +
            `Org license: ${model?.config?.license}`,
          type: MessageType.email,
          sender: currentUserEmail,
        };
        try {
          await Promise.all([
            sendEmail(SUPPORT_EMAIL, content),
            sendEmail(MSP_SUPPORT_EMAIL, content),
            // TODO we may need to plan to template an email sent
            // to customers but leaving it off for now since making
            // the template make take a bit.
            // sendEmail(currentUserEmail, contentForUser).create(),
          ]);
        } catch (err) {
          // Ignore errors since they will also show up in backend error logs
          // and we've already successfully triggered deprovisioning.
        }

        reload(); // Refresh the accounts list
      } catch (err) {
        displayMessage({
          severity: "error",
          message: `Failed to trigger removal of ${model.name}`,
          noAutoHide: true,
        });
      }
    } else {
      // Workflow B
      // ==========
      //
      // Send an email to support and sales to notify working with
      // the customer (support@blumira.com and CC msp@blumira.com)

      const option = REMOVAL_OPTIONS.find((o) => o.value === selectedOption);
      const content = {
        subject: `MSP subaccount move or removal request of ${model.name}`,
        body:
          `An MSP has made the following request for subaccount:\n\n` +
          `Request: ${option.label}\n` +
          `User who initiated the request: ${currentUserEmail}\n` +
          `Org name: ${model.name}\n` +
          `Org id: ${model.id}\n` +
          `Org license: ${model?.config?.license}`,
        type: MessageType.email,
      };
      try {
        await Promise.all([
          sendEmail(SUPPORT_EMAIL, content),
          sendEmail(MSP_SUPPORT_EMAIL, content),
        ]);
        displayMessage({
          severity: "success",
          message:
            `Successfully requested move or removal of ${model.name}. ` +
            "An email has been sent to Blumira support who will be in " +
            "contact shortly to work through the process.",
          noAutoHide: true,
        });
      } catch (err) {
        displayMessage({
          severity: "error",
          message: "Failed to send request",
          noAutoHide: true,
        });
      }
    }
    onClose();
  };

  return (
    <StyledRemoveDialog open={open} onClose={onClose}>
      <div className={removeDialogClasses.dialogWrapper}>
        <DialogTitle className={removeDialogClasses.title}>
          Remove Account
        </DialogTitle>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent>
        <div className={removeDialogClasses.textContainer}>
          <Typography>
            <strong>Account name: </strong>
            {model?.name}
          </Typography>
          <Typography sx={{ marginTop: 0.5 }}>
            <strong>Account license edition: </strong>
            {renderFullLicenseName(model?.config?.license)}
          </Typography>
        </div>
        <FormControl>
          <FormLabel id="account-removal-method-label" focused={false}>
            Choose this sub-account’s status after removal from your account:
          </FormLabel>
          <RadioGroup
            aria-labelledby="account-removal-method-label"
            name="account-removal-method-group"
            className={removeDialogClasses.radioGroup}
            defaultValue={null}
            value={selectedOption}
            onChange={handleOptionChanged}
          >
            {REMOVAL_OPTIONS.map((option) => (
              <div key={option.label}>
                <FormControlLabel
                  value={option.value}
                  control={<Radio />}
                  label={option.label}
                  className={removeDialogClasses.radio}
                />
                {option.value === "permanent" &&
                  selectedOption === "permanent" && (
                    <div className={removeDialogClasses.extraText}>
                      <Alert
                        severity={"warning"}
                        className={removeDialogClasses.alert}
                      >
                        This cannot be undone.
                      </Alert>
                      <p>This removes the following from the sub-account:</p>
                      <ul>
                        <li>All logs</li>
                        <li>All users</li>
                        <li>All sensors, cloud connectors, and agents</li>
                        <li>All scheduled reports</li>
                        <li>All blocklists</li>
                      </ul>
                    </div>
                  )}
              </div>
            ))}
          </RadioGroup>
        </FormControl>
        <TextField
          id="verify-account-name"
          label="Verify account name"
          variant="outlined"
          fullWidth
          value={accountNameInput}
          autoComplete="off"
          type="text"
          helperText={
            "Please enter the account's name exactly as it appears in Blumira"
          }
          onChange={handleAccountNameChange}
        />
      </DialogContent>
      <DialogActions className={removeDialogClasses.dialogActions}>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          color={"error"}
          variant={"contained"}
          disabled={!validates}
          onClick={handleRemoval}
        >
          Remove Sub-Account
        </Button>
      </DialogActions>
    </StyledRemoveDialog>
  );
};

MSPRemoveAccountDialog.propTypes = {
  currentUserEmail: PropTypes.string.isRequired,
  displayMessage: PropTypes.func.isRequired,
  // TODO Why oh why can this be bool??? :S
  model: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]).isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  reload: PropTypes.func.isRequired,
};

export default MSPRemoveAccountDialog;
