import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import _ from "lodash";
import API from "lib/api/API2";

import AlertMessage, { useAlertMessage } from "views/Components/AlertMessage";
import ResponseDialog from "./ResponseDialog";

const PREFIX = "ResponseActions";

const classes = {
  responseActionButton: `${PREFIX}-responseActionButton`,
  responseActions: `${PREFIX}-responseActions`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.responseActionButton}`]: {
    width: "fit-content",
    marginTop: 4,
    marginRight: theme.spacing(1.75),
  },
  [`& .${classes.responseActions}`]: {
    flexDirection: "row",
  },
}));

const ResponseActions = ({ matchId, orgId, responseWorkflows }) => {
  const [activeWorkflow, setActiveWorkflow] = useState(responseWorkflows[0]);
  const [alertMessageProps, displayMessage] = useAlertMessage();
  const [enableButton, setEnabledButton] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [customerIds, setCustomerIds] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const api = new API();
      try {
        const response = await api.get(
          `/response/responseActionEnabled`,
          {
            matchId: matchId,
            outputFields: activeWorkflow.connectorFields[0].evidenceFieldName, // Using [0] is brittle. Find better alternative?
          },
          []
        );
        if (response.data.length > 0) {
          setEnabledButton(true);
          setCustomerIds(response.data);
        }
      } catch (err) {
        setError(err.message || "An unexpected error occurred");
      }
    };
    fetchData();
  }, []);

  return (
    <Root>
      <div className={classes.responseActions}>
        {responseWorkflows.map((workflow) => (
          <Button
            className={classes.responseActionButton}
            color={"primary"}
            key={workflow.responseWorkflowId}
            onClick={() => {
              setActiveWorkflow(_.clone(workflow));
              setOpenDialog(true);
            }}
            variant={"contained"}
            disabled={!enableButton}
          >
            {workflow.buttonName}
          </Button>
        ))}
      </div>
      <AlertMessage {...alertMessageProps} />
      <AlertMessage {...error} />
      <ResponseDialog
        activeWorkflow={activeWorkflow}
        displayMessage={displayMessage}
        handleClose={() => setOpenDialog(false)}
        matchId={matchId}
        customerIds={customerIds}
        openDialog={openDialog}
        orgId={orgId}
      />
    </Root>
  );
};

ResponseActions.propTypes = {
  matchId: PropTypes.string.isRequired,
  orgId: PropTypes.string.isRequired,
  responseWorkflows: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ResponseActions;
