import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { Skeleton } from "@mui/material";
import API from "lib/api/API2";
import Logger from "lib/logger";

const moment = require("moment-timezone");

const PREFIX = "FunnelVisualization";
const classes = {
  baseContainer: `${PREFIX}-baseContainer`,
  funnelContainer: `${PREFIX}-funnelContainer`,
  funnelTitle: `${PREFIX}-funnelTitle`,
  dataText: `${PREFIX}-dataText`,
  baseFunnel: `${PREFIX}-baseFunnel`,
  firstFunnel: `${PREFIX}-firstFunnel`,
  secondFunnel: `${PREFIX}-secondFunnel`,
  thirdFunnel: `${PREFIX}-thirdFunnel`,
  fourthFunnel: `${PREFIX}-fourthFunnel`,
  triangleRight: `${PREFIX}-triangleRight`,
  tallTriangle: `${PREFIX}-tallTriangle`,
  mediumTriangle: `${PREFIX}-mediumTriangle`,
  shortTriangle: `${PREFIX}-shortTriangle`,
  lightGreyBackground: `${PREFIX}-lightGreyBackground`,
  mediumGreyBackground: `${PREFIX}-mediumGreyBackground`,
  darkGreyBackground: `${PREFIX}-darkGreyBackground`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.baseContainer}`]: {
    minHeight: 120,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
  },
  [`& .${classes.funnelContainer}`]: {
    padding: 5,
    width: "100%",
    display: "flex",
    fontWeight: "bold",
    alignItems: "center",
    justifyContent: "space-between",
    height: "100%",
  },
  [`& .${classes.funnelTitle}`]: {
    fontSize: 11,
    marginBottom: 0,
    paddingRight: theme.shape.padding,
  },
  [`& .${classes.dataText}`]: {
    fontSize: 35,
    fontWeight: 400,
    marginTop: 6,
    marginBottom: 0,
    wordBreak: "break-all",
  },
  [`& .${classes.baseFunnel}`]: {
    width: "25%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  [`& .${classes.firstFunnel}`]: {
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    paddingTop: theme.shape.padding - 2,
    paddingLeft: `${theme.shape.padding}px`,
    paddingRight: theme.shape.padding - 2,
    paddingBottom: theme.shape.padding - 10,
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.common.black,
  },
  [`& .${classes.secondFunnel}`]: {
    marginLeft: -20,
    paddingTop: theme.shape.padding - 2,
    paddingLeft: `${theme.shape.padding + 20}px`,
    paddingRight: theme.shape.padding - 2,
    paddingBottom: theme.shape.padding - 10,
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.common.black,
  },
  [`& .${classes.thirdFunnel}`]: {
    marginLeft: -20,
    paddingTop: theme.shape.padding - 2,
    paddingLeft: `${theme.shape.padding + 20}px`,
    paddingRight: theme.shape.padding - 2,
    paddingBottom: theme.shape.padding - 10,
    backgroundColor: theme.palette.grey[400],
    color: theme.palette.common.black,
  },
  [`& .${classes.fourthFunnel}`]: {
    color: "white",
    marginLeft: -20,
    paddingTop: theme.shape.padding - 2,
    paddingLeft: `${theme.shape.padding + 20}px`,
    paddingRight: theme.shape.padding - 2,
    paddingBottom: theme.shape.padding - 10,
    backgroundColor: "#EA4336", // color from designs
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },
  [`& .${classes.triangleRight}`]: {
    width: 0,
    height: 0,
    zIndex: 999,
  },
  [`& .${classes.tallTriangle}`]: {
    borderTop: "113px solid transparent",
    borderBottom: "113px solid transparent",
  },
  [`& .${classes.mediumTriangle}`]: {
    borderTop: "82px solid transparent",
    borderBottom: "82px solid transparent",
  },
  [`& .${classes.shortTriangle}`]: {
    borderTop: "55px solid transparent",
    borderBottom: "55px solid transparent",
  },
  [`& .${classes.lightGreyBackground}`]: {
    borderLeft: `20px solid ${theme.palette.grey[50]}`,
  },
  [`& .${classes.mediumGreyBackground}`]: {
    borderLeft: `20px solid ${theme.palette.grey[100]}`,
  },
  [`& .${classes.darkGreyBackground}`]: {
    borderLeft: `20px solid ${theme.palette.grey[400]}`,
  },
}));

// For formatting numbers in our funnel
const formatter = Intl.NumberFormat("en", { notation: "compact" });

const FunnelVisualization = (props) => {
  const api = new API();
  const logger = Logger("SummaryDashboardFunnelVisualization");

  const [blockedEvents, setBlockedEvents] = useState(null);
  const [logsTotal, setLogsTotal] = useState(null);
  const [suspectsAndThreats, setSuspectsAndThreats] = useState(null);

  const fetchBlockedEvents = async () => {
    const response = await api.get(
      "/bq",
      {
        "query.id.eq": 24,
        "created.gt": moment
          .utc()
          .add(-6, "months")
          .startOf("minute")
          .format("YYYY-MM-DDTHH:mm"),
      },
      []
    );
    const data = response?.data || [];
    const total = data.length > 0 ? data[0].grouped_count ?? 0 : 0;
    setBlockedEvents(formatter.format(total));
  };

  const fetchLogsTotal = async () => {
    const response = await api.get(
      "/bq",
      {
        "query.id.eq": 100,
        "created.gt": moment
          .utc()
          .add(-6, "months")
          .startOf("minute")
          .format("YYYY-MM-DDTHH:mm"),
      },
      []
    );
    const data = response?.data || [];
    const total = data.length > 0 ? data[0].grouped_count ?? 0 : 0;
    setLogsTotal(formatter.format(total));
  };

  const fetchSuspectsAndThreats = async () => {
    const [suspectsResponse, threatsResponse] = await Promise.all([
      api.get(
        "/bq",
        {
          "query.id.eq": 22,
          "created.gt": moment
            .utc()
            .add(-6, "months")
            .startOf("minute")
            .format("YYYY-MM-DDTHH:mm"),
        },
        []
      ),
      api.get(
        "/bq",
        {
          "query.id.eq": 23,
          "created.gt": moment
            .utc()
            .add(-6, "months")
            .startOf("minute")
            .format("YYYY-MM-DDTHH:mm"),
        },
        []
      ),
    ]);

    const suspectsData = suspectsResponse?.data || [];
    const threatsData = threatsResponse?.data || [];

    const suspectsTotal =
      suspectsData.length > 0 ? suspectsData[0].count ?? 0 : 0;
    const threatsTotal = threatsData.length > 0 ? threatsData[0].count ?? 0 : 0;

    const combinedTotal = suspectsTotal + threatsTotal;
    setSuspectsAndThreats(formatter.format(combinedTotal));
  };

  useEffect(() => {
    setBlockedEvents(null);
    setLogsTotal(null);
    setSuspectsAndThreats(null);

    fetchBlockedEvents().catch((err) => {
      logger.error("Error fetching blocked events:", err);
    });
    fetchLogsTotal().catch((err) => {
      logger.error("Error fetching logs total:", err);
    });
    fetchSuspectsAndThreats().catch((err) => {
      logger.error("Error fetching suspects and threats:", err);
    });
  }, [props.orgId]);

  const renderTriangleClassNames = () => {
    if (props.integrations.length === 3) {
      return `${classes.triangleRight} ${classes.tallTriangle}`;
    } else if (props.integrations.length === 2) {
      return `${classes.triangleRight} ${classes.mediumTriangle}`;
    } else {
      return `${classes.triangleRight} ${classes.shortTriangle}`;
    }
  };

  return (
    <Root style={{ marginRight: "15px", width: "40%" }}>
      <div
        className={`${classes.baseContainer} ${classes.funnelContainer}`}
        datacy={"funnelVisualization"}
      >
        <div className={`${classes.baseFunnel} ${classes.firstFunnel}`}>
          <p className={classes.funnelTitle}>Raw logs imported last 6 months</p>
          <p className={classes.dataText}>
            {logsTotal === null ? (
              <Skeleton animation="wave" height={40} width={40} />
            ) : (
              logsTotal
            )}
          </p>
        </div>

        <div
          className={`${renderTriangleClassNames()} ${
            classes.lightGreyBackground
          }`}
        />

        <div className={`${classes.baseFunnel} ${classes.secondFunnel}`}>
          <p className={classes.funnelTitle}>Blocked events</p>
          <p className={classes.dataText}>
            {blockedEvents === null ? (
              <Skeleton animation="wave" height={40} width={40} />
            ) : (
              blockedEvents
            )}
          </p>
        </div>

        <div
          className={`${renderTriangleClassNames()} ${
            classes.mediumGreyBackground
          }`}
        />

        <div className={`${classes.baseFunnel} ${classes.thirdFunnel}`}>
          <p className={classes.funnelTitle}>Suspects identified</p>
          <p className={classes.dataText}>
            {suspectsAndThreats === null ? (
              <Skeleton animation="wave" height={40} width={40} />
            ) : (
              suspectsAndThreats
            )}
          </p>
        </div>

        <div
          className={`${renderTriangleClassNames()} ${
            classes.darkGreyBackground
          }`}
        />

        <div className={`${classes.baseFunnel} ${classes.fourthFunnel}`}>
          <p className={classes.funnelTitle}>Unresolved findings generated</p>
          <p className={classes.dataText}>
            {props.findings === null ? (
              <Skeleton animation="wave" height={40} width={40} />
            ) : (
              props.findings
            )}
          </p>
        </div>
      </div>
    </Root>
  );
};

FunnelVisualization.propTypes = {
  ready: PropTypes.bool.isRequired,
  findings: PropTypes.number,
  integrations: PropTypes.array.isRequired,
};

FunnelVisualization.defaultProps = {
  ready: false,
  integrations: [],
};

export default FunnelVisualization;
