import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";

import { Blocklist as BlocklistModel } from "lib/models/Blocklist";
import { BlocklistEntry as BlocklistEntryModel } from "lib/models/BlocklistEntry";

// COMPONENTS
import ActionDialog from "views/Components/ActionDialog";
import SimpleModelForm from "views/Components/SimpleModelForm";

// ACTIONS
import { fetchConfigForOrg } from "../../redux/actions/Blocking";
import { pageWithQuery } from "../../redux/actions/Page";

// BLOCK HELPERS
import {
  tableDataArray,
  configLayout,
  configFields,
  entryLayout,
  entryFields,
} from "./Blocking/common/blockingHelpers";

import BlockDevices from "./Blocking/BlockDevices";
import { BlockingStatus } from "./Blocking/BlockingStatus";
import BlockTable from "./Blocking/BlockTable";

// MATERIAL FUN
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogTitle,
  Divider,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/DeleteOutline";

const PREFIX = "BlockingPageView";

const classes = {
  infoBannerLink: `${PREFIX}-infoBannerLink`,
  outerContainer: `${PREFIX}-outerContainer`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.infoBannerLink}`]: {
    color: "#315290", // CHAMBRAY from theme file to match text
  },
  [`&.${classes.outerContainer}`]: {
    padding: `0 ${theme.shape.padding}px`,
  },
}));

const useForceUpdate = () => {
  const [, setValue] = useState(0);
  return () => setValue((value) => value + 1);
};

const BlockingPageView = ({
  blocks,
  clearQuery,
  clearFilter,
  dispatchFetchConfigForOrg,
  orgConfig,
  orgId,
  query,
  isFetching,
  gotoFinding,
  license,
  superadmin,
}) => {
  const { findingId } = query;

  const forceUpdate = useForceUpdate();
  const [openForm, setOpenForm] = useState(false);
  const [openAction, setOpenAction] = useState(false);
  const [selectedModel, setSelectedModel] = useState(null);
  const [isEditingConfig, setIsEditingConfig] = useState(false);
  const [refreshTable, setRefreshTable] = useState(0);

  /* Refs */

  // Initialize "component is mounting" flag
  const mounting = useRef(true);

  // Initialize previous orgId
  const previousOrgId = useRef("");

  /* Fetch Data */

  // Fetch config
  useEffect(() => {
    dispatchFetchConfigForOrg(orgId);
  }, [orgId]);

  /* Save orgId */

  useEffect(() => {
    previousOrgId.current = orgId;
  }, [orgId]);

  /* Navigate */

  // Clear the URL query component on orgId change, but not when mounting.
  useEffect(() => {
    if (mounting.current) {
      mounting.current = false;
      return;
    }

    clearQuery();
  }, [orgId]);

  const handleOpenForm = (isConfig) => {
    setOpenForm(true);
    setIsEditingConfig(!!isConfig);
  };

  const handleClose = () => {
    if (isEditingConfig) {
      dispatchFetchConfigForOrg(orgId);
    } else {
      setRefreshTable(refreshTable + 1);
    }
    selectedModel.reset();
    setOpenForm(false);
  };

  const handleDelete = () => {
    selectedModel.delete().then(() => {
      setRefreshTable(refreshTable + 1);
      setOpenAction(false);
    });
  };

  const handleOpenConfigForm = () => {
    handleOpenForm(true);
    setSelectedModel(new BlocklistModel(orgConfig));
  };

  const handleTableRowClick = (params) => {
    const model = new BlocklistEntryModel(params.row);
    setSelectedModel(model);
    handleOpenForm();
  };

  const handleChange = (value, name) => {
    if (!isEditingConfig && name === "negating") {
      if (value) {
        selectedModel.set({ until: 0 });
      }
      // Force a rerender so that entryFields will be updated
      // TODO break the `Dialog` and `ActionDialog` out to a separate
      // component to avoid a whole page rerender.
      forceUpdate();
    }
  };

  const resolveModalTitle = () => {
    if (selectedModel?.id)
      return `Edit Block ${isEditingConfig ? "Configuration" : "Entry"}`;
    else return `New Block ${isEditingConfig ? "Configuration" : "Entry"}`;
  };

  return (
    <Root className={classes.outerContainer}>
      {!isFetching && (
        <>
          <Box
            sx={{
              display: "flex",
              marginTop: 2,
              marginBottom: 4,
              alignItems: "center",
              gap: 2,
            }}
          >
            {findingId && (
              <Button
                onClick={clearFilter({ orgId, query })}
                variant={"outlined"}
              >
                Clear Filter
              </Button>
            )}

            <Button
              onClick={handleOpenConfigForm}
              datacy={"blocklistsConfigureButton"}
              variant={"contained"}
            >
              Configure
            </Button>
            <BlockingStatus config={orgConfig} />
          </Box>

          <div>
            <BlockDevices />
          </div>

          <Divider />
        </>
      )}

      {tableDataArray.map((tableData, i) => (
        <BlockTable
          actions={[
            {
              icon: EditIcon,
              tooltip: "Edit Entry",
              onClick: (params) => {
                setSelectedModel(new BlocklistEntryModel(params.row));
                handleOpenForm();
              },
            },
            {
              icon: DeleteIcon,
              tooltip: "Delete Entry",
              onClick: (params) => {
                setSelectedModel(new BlocklistEntryModel(params.row));
                setOpenAction(true);
              },
            },
          ]}
          key={i}
          onRowClick={handleTableRowClick}
          refresh={refreshTable}
          tableData={tableData}
          toolbarActions={[
            {
              icon: AddCircleIcon,
              tooltip: `Add ${tableData.title} Entry`,
              onClick: (event) => {
                handleOpenForm();
                setSelectedModel(
                  new BlocklistEntryModel({
                    orgId: orgId,
                    blockType: tableData.type,
                  })
                );
              },
            },
          ]}
        />
      ))}

      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={openForm}
        fullWidth={true}
        maxWidth="md"
        datacy={"blocklistsConfigureDialog"}
      >
        <DialogTitle id="simple-dialog-title">
          {resolveModalTitle()}
        </DialogTitle>
        {superadmin ||
        license.hasFeature("BLOCKLISTS") ||
        license.isTagged("limited-to-xdr") ||
        license.isTagged("limited-to-advanced") ? null : (
          <Alert
            severity={"info"}
            style={{ margin: "0 20px" }}
            datacy={"blocklistsConfigureInfoAlert"}
          >
            Blocklists help you reduce your overall attack surface by providing
            your firewall with a dynamic source of threat intelligence.
            Blumira's dynamic blocklists, which join community and threat feeds
            with the block entries you provide, shield your network by stopping
            threats at the firewall. You can also stop access attempts with
            manual blocking in Blumira's finding workflows when a threat is
            detected in your network.
            <br></br>
            <br></br>
            For a faster threat response with automated blocking, upgrade to{" "}
            <a
              href="https://www.blumira.com/pricing/"
              className={classes.infoBannerLink}
            >
              XDR Platform Edition
            </a>
            .
          </Alert>
        )}
        <SimpleModelForm
          spacing={2}
          model={selectedModel}
          onClose={handleClose}
          onChange={handleChange}
          actions={[
            {
              title: "Cancel",
              type: "button",
              action: handleClose,
            },
            {
              title: "Save",
              type: "submit",
              variant: "contained",
            },
          ]}
          layout={isEditingConfig ? configLayout : entryLayout}
          fields={
            isEditingConfig
              ? configFields(selectedModel, license, superadmin)
              : entryFields(selectedModel)
          }
        />
      </Dialog>

      <ActionDialog
        title={`Delete`}
        open={openAction}
        description="Are you sure you want to delete this block entry?"
        actions={[
          {
            title: "Cancel",
            action: () => setOpenAction(false),
          },
          {
            title: "Delete",
            action: handleDelete,
            variant: "contained",
          },
        ]}
      />
    </Root>
  );
};

BlockingPageView.propTypes = {
  dispatchFetchConfigForOrg: PropTypes.func.isRequired,
  orgConfig: PropTypes.shape({}).isRequired,
  orgId: PropTypes.string.isRequired,
  query: PropTypes.shape({}).isRequired,
  clearFilter: PropTypes.func.isRequired,
  clearQuery: PropTypes.func.isRequired,
};

BlockingPageView.defaultProps = {};

const defaultBlockConfig = {
  blockEnabled: false,
  blockConfiguration: {
    automated: false,
    community: true,
    default_number: 7,
    devices: [],
    threat_feeds: 2,
  },
};

const mapStateToProps = (state) => {
  const { blocking = {}, location = {} } = state;

  const { config = {}, blocks = {} } = blocking;
  const { byOrg = {} } = config;

  const { payload = {}, query = {} } = location;
  const { orgId = "" } = payload;

  defaultBlockConfig.orgId = orgId;

  const orgConfig =
    byOrg[orgId] && byOrg[orgId].blockConfiguration
      ? byOrg[orgId]
      : defaultBlockConfig;

  const blocksToReturn = blocks.byOrg[orgId] || [];

  return {
    orgId,
    query,
    orgConfig,
    blocks: blocksToReturn,
    isFetching: _.isEmpty(byOrg),
    license: state.license,
    superadmin: state?.session?.settings?.user?.superadmin,
  };
};

const mapDispatchToProps = (dispatch) => ({
  /* Data fetching */

  dispatchFetchConfigForOrg: (orgId) => dispatch(fetchConfigForOrg(orgId)),

  // Deletes 'findingId' key from the URL query component
  clearFilter:
    ({ query = {} }) =>
    () => {
      const nextQuery = { ...query };

      delete nextQuery.findingId;

      dispatch(pageWithQuery(nextQuery));
    },

  clearQuery: () => {
    dispatch(pageWithQuery({}));
  },

  gotoFinding: ({ id, orgId }) => {
    dispatch({
      type: "PAGE",
      payload: {
        orgId,
        toplevel: "reporting",
        secondlevel: "findings",
        id1: id,
      },
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BlockingPageView);
