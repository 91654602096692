import { Divider, FormControl, TextField } from "@mui/material";

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { cloudModuleConfigFieldLabels } from "./constants";
import SecretField from "./SecretField";
import { classes } from "./styles";
import { areValuesEmpty } from "./ConfigurationForm";

const FieldsetLogging = (props) => {
  const ConfigSection = "configuration";
  const SecretSection = "secret";

  const {
    model,
    schema,
    integrationType,
    disabled,
    selectedCloudModule,
    onChange,
    superadmin,
  } = props;

  const allRequired = (configValues, secretValues) => {
    if (model.id) {
      return !areValuesEmpty(secretValues);
    }
    return !areValuesEmpty(secretValues) || !areValuesEmpty(configValues);
  };

  if (!model.configuration) {
    model.set({ configuration: {} }, false);
  }
  if (!model.secret) {
    model.set({ secret: {} }, false);
  }

  const [configValues, setConfigValues] = useState(model.configuration);
  const [secretValues, setSecretValues] = useState(model.secret);
  const [configFields, setConfigfields] = useState([]);
  const [secretFields, setSecretFields] = useState([]);

  const configurationSchema = schema.configurationSchema;
  const secretSchema = schema.secretSchema;

  useEffect(() => {
    let configFields = [];
    let secretFields = [];

    for (const [prop, propInfo] of Object.entries(
      configurationSchema["properties"] || {}
    )) {
      const hasStringType =
        propInfo.anyOf && propInfo.anyOf.some((p) => p.type === "string");
      if (propInfo.type !== "string" && !hasStringType) {
        console.warn("unimplemented field type", propInfo.type);
        continue;
      }
      const required =
        configurationSchema["required"] &&
        configurationSchema["required"].includes(prop);
      const initRequired = required && allRequired(configValues, secretValues);
      if (
        cloudModuleConfigFieldLabels[integrationType][ConfigSection] &&
        cloudModuleConfigFieldLabels[integrationType][ConfigSection][prop]
      ) {
        configFields = configFields.concat({
          section: ConfigSection,
          label:
            cloudModuleConfigFieldLabels[integrationType][ConfigSection][prop],
          name: prop,
          editable: true,
          required: initRequired,
          placeholder: initRequired ? "(required)" : "(optional)",
          validate: (value, newConfigValues, newSecretValues) => {
            if (required && allRequired(newConfigValues, newSecretValues)) {
              return !!value && value !== "";
            }
            return true;
          },
        });
      }
    }

    for (const [prop, propInfo] of Object.entries(secretSchema["properties"])) {
      if (propInfo["anyOf"] && propInfo["anyOf"].length > 0) {
        propInfo.anyOf.forEach((item) => {
          if (item.type !== "null") {
            for (const [key, value] of Object.entries(item)) {
              propInfo[key] = value;
            }
          }
        });
      }

      if (propInfo.type !== "string" && propInfo.type !== "boolean") {
        // boolean now to allow for is_gcc on M365 CC
        console.warn("unimplemented field type", propInfo.type);
        continue;
      }

      // selectedCloudModule has dropDownValues and,
      // more specifically, provides options for this property
      const isSelectField =
        !!selectedCloudModule.dropDownValues &&
        !!selectedCloudModule.dropDownValues[prop];

      // selectedCloudModule has textArea property and,
      // more specifically, provides boolean for this property
      const isTextAreaField =
        !!selectedCloudModule.textArea && !!selectedCloudModule.textArea[prop];

      const isLicenseFeatureEnabledField =
        !!selectedCloudModule.licenseFeatureEnabledField &&
        !!selectedCloudModule.licenseFeatureEnabledField[prop];

      const doesFieldHaveHelperText =
        !!selectedCloudModule.helperText &&
        !!selectedCloudModule.helperText[prop];

      // the following code is strictly to maintain
      // the order of properties displayed as they
      // are layed out in the constants file
      const secretSectionKeyArray = Object.keys(
        cloudModuleConfigFieldLabels[integrationType][SecretSection]
      );
      const indexOfProp = secretSectionKeyArray.indexOf(prop);

      const required =
        secretSchema["required"] && secretSchema["required"].includes(prop);
      const initRequired = required && allRequired(configValues, secretValues);

      secretFields[indexOfProp] = {
        section: SecretSection,
        label:
          cloudModuleConfigFieldLabels[integrationType][SecretSection][prop],
        name: prop,
        editable: true,
        required: initRequired,
        placeholder: initRequired ? "(required)" : "(optional)",
        options: isSelectField
          ? selectedCloudModule.dropDownValues[prop]
          : isTextAreaField
          ? selectedCloudModule.textArea[prop]
          : null,
        featureFlag: isLicenseFeatureEnabledField
          ? selectedCloudModule.licenseFeatureEnabledField[prop]
          : null,
        helperText: doesFieldHaveHelperText
          ? selectedCloudModule.helperText[prop]
          : null,
        type: propInfo.type,
        validate: (value, newConfigValues, newSecretValues) => {
          // Secret values for integrations that already exist are allowed
          // to be all empty because secrets are not returned to the frontend.
          if (required && allRequired(newConfigValues, newSecretValues)) {
            return !!value && value !== "";
          }
          return true;
        },
      };
    }

    setConfigfields(configFields);
    setSecretFields(secretFields);
  }, [configurationSchema, secretSchema, configValues, secretValues]);

  const updateValidation = (newConfigValues, newSecretValues) => {
    let isOk = true;
    for (const info of configFields) {
      isOk =
        isOk &&
        info.validate(
          newConfigValues[info.name],
          newConfigValues,
          newSecretValues
        );
    }
    for (const info of secretFields) {
      isOk =
        isOk &&
        info.validate(
          newSecretValues[info.name],
          newConfigValues,
          newSecretValues
        );
    }
    onChange({ tabName: "Logging", hasError: !isOk });
  };

  const handleChange = (fieldInfo, value) => {
    // State updates occur asynchronously so we need to save our
    // updates so that we can update the model and do validation
    // afterward.
    let newConfigValues = configValues;
    let newSecretValues = secretValues;

    if (fieldInfo.section === ConfigSection) {
      newConfigValues = {
        ...configValues,
        [fieldInfo.name]: value,
      };
      setConfigValues(newConfigValues);
    } else if (fieldInfo.section === SecretSection) {
      newSecretValues = {
        ...secretValues,
        [fieldInfo.name]: value,
      };
      setSecretValues(newSecretValues);
    } else {
      console.error("unexpected section", fieldInfo);
    }

    model.set({ configuration: newConfigValues }, false);
    model.set({ secret: newSecretValues }, false);

    updateValidation(newConfigValues, newSecretValues);
  };

  return (
    <FormControl component="fieldset" fullWidth={true}>
      {configFields.map((fieldInfo, index) => (
        <TextField
          datacy={fieldInfo.name}
          value={configValues[fieldInfo.name] || ""}
          placeholder={fieldInfo.placeholder}
          label={fieldInfo.label}
          onChange={(e) => handleChange(fieldInfo, e.target.value)}
          className={classes.field}
          disabled={disabled || !fieldInfo.editable}
          key={ConfigSection + index}
          error={
            !fieldInfo.validate(
              configValues[fieldInfo.name],
              configValues,
              secretValues
            )
          }
          helperText={fieldInfo.helperText}
          InputLabelProps={{
            shrink: true,
          }}
        />
      ))}

      <p className={classes.secretsText}>
        <span className={classes.secretsTitle}>Secrets: </span>
        {model.id
          ? "Leave the following fields blank to leave this connector’s secrets unchanged."
          : "The following entries will be hidden when editing this cloud connector in the future."}
      </p>

      {secretFields.map((fieldInfo, index) => (
        <SecretField
          disabled={disabled}
          fieldInfo={fieldInfo}
          handleChange={handleChange}
          key={SecretSection + index}
          secretValue={secretValues[fieldInfo.name]}
          configValues={configValues}
          secretValues={secretValues}
        />
      ))}

      {model.id && superadmin && (
        <div>
          <Divider className={classes.divider} />
          <p className={classes.secretsText}>
            <span className={classes.secretsTitle}>Admin Info: </span>
            This information is only visible to Blumira superadmins.
          </p>
          <p className={classes.secretsText}>
            <span className={classes.secretsTitle}>Connector ID: </span>
            {model.id}
          </p>
        </div>
      )}
    </FormControl>
  );
};

FieldsetLogging.propTypes = {
  model: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired,
  integrationType: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedCloudModule: PropTypes.object.isRequired,
  superadmin: PropTypes.bool.isRequired,
};

export default FieldsetLogging;
