import React from "react";
import PropTypes from "prop-types";
import { Input, Container, Row, Col } from "reactstrap";
import moment from "moment-timezone";

import {
  BLOCKING_BLOCK_TYPE_IP,
  BLOCKING_BLOCK_TYPE_DOMAIN,
  BLOCKING_AUTOMATED_LEGEND,
  BLOCKING_BLOCKING_LEGEND,
  BLOCKING_COMMUNITY_LEGEND,
  BLOCKING_MAX_DURATION,
  BLOCKING_NEGATING_LEGEND,
} from "./blockingConfig";

const DEFAULT_COLS = 35;
const DEFAULT_ROWS = 5;
const NOTE_MAX_LENGTH = 200;
const NOTE_MIN_LENGTH = 0;
const TARGET_MAX_LENGTH = 500;
const TARGET_MIN_LENGTH = 3;

export const DURATION_ERROR_MESSAGE = "Number of days must be integer 0 to 365";
export const TARGET_ERROR_MESSAGE = "Invalid target field";

/*
Generates an string from a prefix string and Math.random()
*/
const domId = (prefixStr) =>
  `${prefixStr}_${Math.random().toString().substring(2)}`;

/*
Configuration create/edit form block duration 'default' field
*/
export const DurationField = ({
  value,
  onChange,
  minValue = 0,
  maxValue = 365,
}) => {
  const labelText1 = "Number of Days to Block";
  const labelText2 =
    minValue <= 0
      ? `(${minValue} to ${maxValue}, 0=forever)`
      : `(${minValue} to ${maxValue})`;

  return (
    <div className="blocking-duration-field">
      <input
        type="text"
        className="blocking-duration-input"
        value={value}
        onChange={onChange}
      />

      <div className="blocking-duration-label control-label">
        <div>
          <span>{labelText1}</span>
          <span className="text-danger">*</span>
        </div>

        <div>{labelText2}</div>
      </div>
    </div>
  );
};

// PropTypes
DurationField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
};

DurationField.defaultProps = {
  value: "",
  onChange: () => {},
  minValue: 0,
  maxValue: BLOCKING_MAX_DURATION,
};

export const validateDuration = ({ value, minValue = 0, maxValue = 365 }) => {
  let numValue = null;

  if (value === undefined || value === null) {
    return { valid: false, numValue };
  }

  const reg = /^\d+$/;

  if (!reg.test(value)) {
    return { valid: false, numValue };
  }

  numValue = Number.parseInt(value, 10);

  if (numValue < minValue || numValue > maxValue) {
    return { valid: false, numValue };
  }

  return { valid: true, numValue };
};

/*
Block create/edit form 'note' field
*/
export const NoteField = ({
  value,
  maxLength = NOTE_MAX_LENGTH,
  minLength = NOTE_MIN_LENGTH,
  onChange,
  title = "",
}) => {
  const inputId = domId("notefield");

  return (
    <div>
      <label className="control-label" htmlFor={inputId}>
        {title}
      </label>

      <Input
        type="textarea"
        className="blocking-form-textarea"
        id={inputId}
        cols={DEFAULT_COLS}
        maxLength={maxLength}
        minLength={minLength}
        rows={DEFAULT_ROWS}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

// PropTypes
NoteField.propTypes = {
  value: PropTypes.string,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  onChange: PropTypes.func,
  title: PropTypes.string,
};

NoteField.defaultProps = {
  value: "",
  maxLength: NOTE_MAX_LENGTH,
  minLength: NOTE_MIN_LENGTH,
  onChange: () => {},
  title: "",
};

/*
Block create form 'target' field
*/
export const TargetField = ({ value, onChange }) => {
  const inputId = domId("targetfield");

  return (
    <div>
      <label className="control-label" htmlFor={inputId}>
        Target
        <span className="text-danger">*</span>
      </label>
      <input
        className="blocking-form-textarea"
        id={inputId}
        maxLength={TARGET_MAX_LENGTH}
        minLength={TARGET_MIN_LENGTH}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

// PropTypes
TargetField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

TargetField.defaultProps = {
  value: "",
  onChange: () => {},
};

export const validateTarget = ({
  value,
  minLength = TARGET_MIN_LENGTH,
  maxValue = TARGET_MAX_LENGTH,
}) => {
  const reg = /^[0-9A-Za-z-._~!$&'()*+,;=:@%/]+$/;

  if (!reg.test(value)) {
    return false;
  }

  if (value.length < minLength || value.length > maxValue) {
    return false;
  }

  return true;
};

export const formatExpirationDate = ({
  expirationDate,
  until,
  foreverStr = "Forever",
  inactiveStr = "Not Activated",
}) => {
  if (until === 0) {
    return foreverStr;
  }
  if (until === null || expirationDate === null) {
    return inactiveStr;
  }

  return moment
    .tz(expirationDate, "utc")
    .tz(moment.tz.guess(true))
    .format("lll z");
};

// "Automated" meaning explained
export const AutomatedLegend = () => {
  const text1 = BLOCKING_AUTOMATED_LEGEND.text1;
  const text2 = BLOCKING_AUTOMATED_LEGEND.text2;

  return (
    <div className="blocking-legend">
      <p>{text1}</p>
      <p>{text2}</p>
    </div>
  );
};

// "Automated" meaning explained
export const BlockingLegend = () => {
  const text = BLOCKING_BLOCKING_LEGEND;

  return (
    <div className="blocking-legend">
      <p>{text}</p>
    </div>
  );
};

// "Community" meaning explained
export const CommunityLegend = () => {
  const text1 = BLOCKING_COMMUNITY_LEGEND.text1;
  const text2 = BLOCKING_COMMUNITY_LEGEND.text2;

  return (
    <div className="blocking-legend">
      <p>{text1}</p>
      <p>{text2}</p>
    </div>
  );
};

// "Feed Properties" meaning explained
export const FeedsLegend = ({ feedProperties }) => {
  // Feeds items list
  const feedsLegendItems = feedProperties.map((elt) => (
    <Row key={elt.name}>
      <Col xs="4" className="blocking-feeds-name">
        <span>{`${elt.name}:`}</span>
      </Col>
      <Col xs="8" className="blocking-feeds-description">
        <span>{elt.description}</span>
      </Col>
    </Row>
  ));

  return (
    <Container className="field blocking-feeds-list">
      {feedsLegendItems}
    </Container>
  );
};

// PropTypes
FeedsLegend.propTypes = {
  feedProperties: PropTypes.arrayOf(PropTypes.shape({})),
};

FeedsLegend.defaultProps = {
  feedProperties: [],
};

// "Negating" meaning explained
export const NegatingLegend = () => {
  const text = BLOCKING_NEGATING_LEGEND;

  return (
    <div className="blocking-legend">
      <p>{text}</p>
    </div>
  );
};

// HELPERS AND CONSTANTS FOR BLOCKING FORMS AND TABLES
export const tableDataArray = [
  { title: "IP", type: BLOCKING_BLOCK_TYPE_IP },
  { title: "Domain", type: BLOCKING_BLOCK_TYPE_DOMAIN },
];

const deviceOptions = [
  { value: "ASA", label: "ASA" },
  { value: "CheckPoint", label: "CheckPoint" },
  { value: "Firepower", label: "Firepower" },
  { value: "Fortigate", label: "Fortigate" },
  { value: "Generic", label: "Generic" },
  { value: "PaloAlto", label: "PaloAlto" },
];

export const getColumns = (type) => [
  {
    // We cannot sort by `target` because it is a hybrid property
    field: type === BLOCKING_BLOCK_TYPE_IP ? "targetInet" : "targetString",
    flex: 0.9,
    title: type === BLOCKING_BLOCK_TYPE_IP ? "IP" : "Domain",
    type: "string",
  },
  {
    title: "Allowlist",
    field: "negating",
    flex: 0.4,
    type: "boolean",
  },
  {
    title: "Automated",
    field: "automated",
    flex: 0.4,
    type: "boolean",
  },
  {
    title: "Community",
    field: "community",
    flex: 0.4,
    type: "boolean",
  },
  {
    title: "Finding",
    field: "findingId",
    type: "string",
  },
  {
    title: "Blocked Until",
    // We cannot sort by `expirationDate` because it is a hybrid property
    field: "until",
    flex: 0.75,
    type: "string",
    valueGetter: ({ value, row }) => {
      if (!value) return "";
      const start = row.modified ? row.modified : row.created;
      const date = moment(start).add(value, "days");
      return date.tz(moment.tz.guess(true)).format("MMM D, YYYY h:mm A z");
    },
  },
];

export const configLayout = [
  { field: "blockEnabled", xs: 6 },
  { field: "blockConfiguration.default_number", xs: 6 },
  { field: "blockConfiguration.automated", xs: 6 },
  { field: "blockConfiguration.community", xs: 6 },
  { field: "blockConfiguration.devices", xs: 12 },
  { field: "blockConfiguration.threat_feeds", xs: 12 },
];

export const configFields = (model, license, superadmin) => ({
  blockEnabled: {
    type: "select",
    default: false,
    label: "Blocking",
    lookup: [
      { value: true, label: "Enabled" },
      { value: false, label: "Disabled" },
    ],
    tooltip:
      "The organization will accept blocking resolutions for findings and blocklist updates. This exposes 3 separate blocklists - IP, domain and URL. These will be populated based on your threat feed option choice and finding completion. These blocklists will be updated every 5 minutes. Manage them through the blocks found on this page to the left.",
  },
  "blockConfiguration.default_number": {
    default: 7,
    ignore: true,
    type: "number",
    label: "Number of days to block",
    value: model && model.blockConfiguration.default_number,
    helperText: "Enter a value from 1 to 365",
  },
  "blockConfiguration.automated": {
    type: "select",
    default: false,
    ignore: true,
    label: "Automated",
    lookup: [
      { value: true, label: "Enabled" },
      { value: false, label: "Disabled" },
    ],
    value: model && model.blockConfiguration.automated,
    tooltip:
      "All workflows with blocking actions will be closed and the IPs or domains involved in the finding will be automatically added to the blocklist for your organization.",
    readOnly:
      superadmin ||
      license.hasFeature("BLOCKLISTS") ||
      license.isTagged("limited-to-xdr") ||
      license.isTagged("limited-to-advanced")
        ? false
        : true,
  },
  "blockConfiguration.community": {
    type: "select",
    default: false,
    ignore: true,
    label: "Community",
    lookup: [
      { value: true, label: "Enabled" },
      { value: false, label: "Disabled" },
    ],
    value: model && model.blockConfiguration.community,
    tooltip:
      "Once enabled, you will join the Blumira blocking community. This allows your organization to accept blocked IPs and domains from other organizations that have opted in. All blocks except private IPs are shared within the community to help you respond early to new attack trends identified by Blumira.",
  },
  "blockConfiguration.devices": {
    type: "multiselect",
    default: [],
    ignore: true,
    label: "Devices",
    lookup: deviceOptions,
    value: deviceOptions
      .map((opt) => opt.value)
      .filter(
        (v) => model && model.blockConfiguration.devices.find((d) => d === v)
      ),
  },
  "blockConfiguration.threat_feeds": {
    type: "select",
    ignore: true,
    label: "Threat Feeds",
    lookup: [
      { value: 0, label: "Disabled" },
      { value: 1, label: "Low" },
      { value: 2, label: "Moderate" },
      { value: 3, label: "High" },
    ],
    value: model && model.blockConfiguration.threat_feeds,
  },
});

export const entryLayout = [
  { field: "target", xs: 6 },
  { field: "negating", xs: 6 },
  { field: "until", xs: 12 },
  { field: "description", xs: 12 },
  { field: "note", xs: 12 },
];

export const entryFields = (model) => ({
  target: {
    type: "text",
    default: "",
    label: "Target",
    readOnly: !!model?.id, // we do not allow updating target
  },
  negating: {
    type: "select",
    default: false,
    label: "Allowlist",
    lookup: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
  },
  until: {
    type: "number",
    default: model?.negating ? 0 : 7,
    label: "Number of days to block",
    helperText: model?.negating
      ? "Allowlist entries are always set to forever (0 = forever)"
      : "0 to 365, 0 = forever",
    readOnly: model?.negating,
  },
  description: {
    type: "text",
    default: "",
    label: "Description",
  },
  note: {
    type: "text",
    default: "",
    label: "Note",
  },
});
