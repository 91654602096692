import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DataGridPro } from "@mui/x-data-grid-pro";
import API from "lib/api/API2";
import { styled } from "@mui/material/styles";

import Logger from "lib/logger";
import ResponseCompleted from "views/Components/Finding/ResponseCompleted";
import ResponsePending from "views/Components/Finding/ResponsePending";

const PREFIX = "ResponseDialog";

const classes = {
  dialogHeader: `${PREFIX}-dialogHeader`,
  dialogContent: `${PREFIX}-dialogContent`,
  dataGrid: `${PREFIX}-dataGrid`,
  errorContainer: `${PREFIX}-errorContainer`,
  loadingOverlay: `${PREFIX}-loadingOverlay`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.dialogHeader}`]: {
    display: "flex",
    paddingRight: theme.spacing(1),
    alignItems: "center",
    justifyContent: "space-between",
  },
  [`&.${classes.errorContainer}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: 100,
    padding: "20px",
  },
  [`&.${classes.loadingOverlay}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
}));

const StyledDataGridPro = styled(DataGridPro)(({ theme }) => ({
  [`&.${classes.dataGrid}`]: {
    height: 525,
    "& .MuiDataGrid-row:hover": {
      backgroundColor: "transparent",
    },
    "& .MuiDataGrid-cell": {
      outline: "none !important",
    },
    "& .MuiDataGrid-columnHeader": {
      outline: "none !important",
    },
  },
}));

export const Status = {
  NONE: "none",
  PENDING_ACTION: "pending_action",
  RUNNING_ACTION: "running_action",
  SUCCESS_ACTION: "success_action",
  FAILED_ACTION: "failed_action",
};

// Get a mapping of `workflowInputName` to `evidenceFieldName`
const getFieldMapping = (activeWorkflow) => {
  const fieldMap = new Map();
  for (const field of activeWorkflow.connectorFields) {
    fieldMap.set(field.workflowInputName, field.evidenceFieldName);
  }
  for (const field of activeWorkflow.targetFields) {
    fieldMap.set(field.workflowInputName, field.evidenceFieldName);
  }
  return fieldMap;
};

// Get an `orderBy` for FEA from the current `sortModel`. The `sortModel`
// uses `workflowInputName` but the backend needs `evidenceFieldName`.
const getOrderBy = (sortModel, fieldMap) => {
  if (!sortModel) {
    return fieldMap.values().next().value; // Default sort field
  }
  const sortFields = [];
  for (const item of sortModel) {
    const sortDir = item.sort === "desc" ? "-" : "";
    const sortField = fieldMap.get(item.field);
    sortFields.push(`${sortDir}${sortField}`);
  }
  return sortFields.join(",");
};

const ResponseDialog = ({
  activeWorkflow,
  displayMessage,
  handleClose,
  matchId,
  customerIds,
  openDialog,
  orgId,
}) => {
  const logger = Logger("ResponseDialog");

  // Note that fields used for querying the backend use `evidenceFieldName`
  // but fields displayed to the user in the DataGrid use `workflowInputName`.
  const fieldMap = getFieldMapping(activeWorkflow);

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [sortModel, setSortModel] = useState([
    { field: fieldMap.keys().next().value, sort: "asc" },
  ]);
  const [currentAction, setCurrentAction] = useState({});
  const [currentStatus, setCurrentStatus] = useState(Status.NONE);

  const handlePendingAction = (action) => {
    setCurrentAction(action);
    setCurrentStatus(Status.PENDING_ACTION);
  };

  const verifyCustomerIdMatch = (customerId) => {
    return customerIds.length > 0 && customerIds.includes(customerId);
  };

  const columns = useMemo(() => {
    let fieldColumns = [];
    for (const field of activeWorkflow.connectorFields) {
      if (field.hidden === true) {
        continue;
      }
      fieldColumns.push({
        field: field.workflowInputName,
        // TODO display nicer header name somehow?
        headerName: field.workflowInputName,
        flex: 1.25,
        valueGetter: (params) => params.row[field.evidenceFieldName],
        sortable: true,
        resizable: true,
      });
    }
    for (const field of activeWorkflow.targetFields) {
      if (field.hidden === true) {
        continue;
      }
      fieldColumns.push({
        field: field.workflowInputName,
        // TODO display nicer header name somehow?
        headerName: field.workflowInputName,
        flex: 1,
        valueGetter: (params) => params.row[field.evidenceFieldName],
        sortable: true,
        resizable: true,
      });
    }
    if (!fieldColumns.length) {
      logger.error("Missing fieldColumns");
      return;
    }
    return [
      ...fieldColumns,
      {
        field: "button",
        headerName: "",
        align: "right",
        flex: 0.75,
        sortable: false,
        resizable: false,
        filterable: false,
        renderCell: (params) => (
          <Box>
            <Button
              size="small"
              style={{ marginLeft: 16 }}
              onClick={() => handlePendingAction(params.row)}
              tabIndex={params.hasFocus ? 0 : -1}
              variant="outlined"
              disabled={
                !verifyCustomerIdMatch(
                  params.row[
                    activeWorkflow.connectorFields[0].evidenceFieldName
                  ]
                )
              } // Using [0] is brittle. Find better alternative?
            >
              <strong>{activeWorkflow.buttonName}</strong>
            </Button>
          </Box>
        ),
      },
    ];
  }, [activeWorkflow.buttonName, handlePendingAction]);

  const fetchEvidence = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const api = new API();
      const response = await api.get(
        `/match/${matchId}/evidence`,
        {
          orgId: orgId,
          groupBy: Array.from(fieldMap.values()).join(","),
          orderBy: getOrderBy(sortModel, fieldMap),
          outputFields: Array.from(fieldMap.values()).join(","),
          page: `${paginationModel.page + 1}:${paginationModel.pageSize}`,
        },
        []
      );

      setRows(response.data || []);
      setTotalRows(response?.meta?.totalItems || 0);
    } catch (err) {
      setError(err.message || "An unexpected error occurred");
      setRows([]);
      setTotalRows(0);
    } finally {
      setLoading(false);
    }
  }, [matchId, paginationModel.page, paginationModel.pageSize, sortModel]);

  useEffect(() => {
    if (openDialog) {
      fetchEvidence();
    }
  }, [fetchEvidence, openDialog]);

  // Custom loading overlay
  const CustomLoadingOverlay = () => {
    return (
      <StyledBox className={classes.loadingOverlay}>
        <CircularProgress />
      </StyledBox>
    );
  };

  // Custom error component
  const CustomNoRowsOverlay = () => {
    if (error) {
      return (
        <StyledBox className={classes.errorContainer}>
          <Alert
            severity="error"
            sx={{ width: "100%", marginBottom: 2 }}
            action={
              <Button
                color="inherit"
                size="small"
                onClick={() => fetchEvidence()}
              >
                Retry
              </Button>
            }
          >
            Error loading data: {error}
          </Alert>
        </StyledBox>
      );
    }
    return <div>No rows</div>;
  };

  const handleSortModelChange = useCallback((newSortModel) => {
    setSortModel(newSortModel);
  }, []);

  return (
    <Dialog open={openDialog} onClose={handleClose} maxWidth="md" fullWidth>
      <StyledBox className={classes.dialogHeader}>
        <DialogTitle>{"Response Actions"}</DialogTitle>
        <IconButton title="Close without saving" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </StyledBox>
      <DialogContent sx={{ paddingTop: "2px" }}>
        <StyledDataGridPro
          className={classes.dataGrid}
          rows={rows}
          columns={columns}
          rowCount={totalRows}
          loading={loading}
          error={error}
          components={{
            LoadingOverlay: CustomLoadingOverlay,
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          disableRowSelectionOnClick
          getRowId={(row) => Object.values(row).join("")}
          isRowSelectable={() => false}
          onPaginationModelChange={setPaginationModel}
          onSortModelChange={handleSortModelChange}
          pageSizeOptions={[25, 50, 100, 250]}
          pagination={true}
          paginationMode="server"
          paginationModel={paginationModel}
          sortingMode="server"
          sortingOrder={["asc", "desc"]}
        />
      </DialogContent>
      <ResponsePending
        activeWorkflow={activeWorkflow}
        currentAction={currentAction}
        currentStatus={currentStatus}
        setCurrentStatus={setCurrentStatus}
        displayMessage={displayMessage}
        logger={logger}
        orgId={orgId}
      />
      <ResponseCompleted
        activeWorkflow={activeWorkflow}
        currentAction={currentAction}
        currentStatus={currentStatus}
        setCurrentStatus={setCurrentStatus}
        fieldMap={fieldMap}
        logger={logger}
      />
    </Dialog>
  );
};

ResponseDialog.propTypes = {
  activeWorkflow: PropTypes.object.isRequired,
  displayMessage: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  matchId: PropTypes.string.isRequired,
  openDialog: PropTypes.bool.isRequired,
  orgId: PropTypes.string.isRequired,
};

export default ResponseDialog;
