import React from "react";
import { Box } from "@mui/material";

const InfoTag = ({ children, sx = {} }) => {
  return (
    <Box
      component="span"
      sx={{
        fontSize: "0.75rem",
        boxShadow: "0 0.1em 0.5em rgba(0, 0, 0, 0.25)",
        padding: "4px 8px 5px 8px",
        borderRadius: "6px",
        background: "linear-gradient(135deg, #d446b7, #2f57bd)",
        color: "white",
        textTransform: "none",
      }}
    >
      {children}
    </Box>
  );
};

export default InfoTag;
