import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Button, InputAdornment, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import LicenseRestriction from "views/Components/License";

const PREFIX = "ServerToolbar";

const classes = {
  actions: `${PREFIX}-actions`,
  actionsContainer: `${PREFIX}-actionsContainer`,
  alignRightAction: `${PREFIX}-alignRightAction`,
  primaryActionContainer: `${PREFIX}-primaryActionContainer`,
  primaryActionsContainer: `${PREFIX}-primaryActionsContainer`,
  searchField: `${PREFIX}-searchField`,
  searchFieldContainer: `${PREFIX}-searchFieldContainer`,
  toolbar: `${PREFIX}-toolbar`,
  toolbarActionButton: `${PREFIX}-toolbarActionButton`,
  toolbarSearchOnly: `${PREFIX}-toolbarBarSearchOnly`,
  tooltip: `${PREFIX}-tooltip`,
};

export const Root = styled("div")(({ theme }) => ({
  marginBottom: "20px",
  [`& .${classes.actions}`]: {
    padding: theme.spacing(0.5),
  },
  [`& .${classes.actionsContainer}`]: {
    display: "flex",
    paddingBottom: 20,
    alignItems: "center",
    justifyContent: "space-between",
  },
  [`& .${classes.alignRightAction}`]: {
    position: "absolute",
    right: 0,
  },
  [`& .${classes.primaryActionContainer}`]: {
    "@media (max-width: 1120px)": {
      marginTop: -15,
      marginBottom: 30,
    },
  },
  [`& .${classes.primaryActionsContainer}`]: {
    width: "100%",
    display: "flex",
    alignItems: "flex-end",
    "@media (max-width: 1120px)": {
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "space-between",
    },
  },
  [`& .${classes.searchField}`]: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
  },
  [`& .${classes.searchFieldContainer}`]: {
    display: "flex",
    alignItems: "center",
  },
  [`& .${classes.toolbar}`]: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  [`& .${classes.toolbarActionButton}`]: {
    margin: "0 10px",
    fontWeight: "bold",
    padding: "9px 18px",
    textTransform: "none",
    color: theme.palette.text.primary,
    "&.Mui-disabled": {
      color: theme.palette.action.disabled,
    },
  },
  [`& .${classes.toolbarSearchOnly}`]: {
    padding: 0,
  },
  [`& .${classes.tooltip}`]: {
    boxShadow: theme.shadows[4],
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
  },
}));

export const SearchField = styled(TextField)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  marginBottom: "20px",
}));

const ServerToolbar = ({ actions, licenseRestriction, onSearchChange }) => {
  const getToolbarButton = () => {
    return (
      actions &&
      actions
        .filter((a) => !a.isPrimary)
        .map((action) => (
          <Button
            key={action.tooltip}
            onClick={action.onClick}
            datacy={action.dataCy ? action.dataCy : "simpleTableToolbarBtn"}
            className={
              action.alignRight
                ? [classes.toolbarActionButton, classes.alignRightAction]
                : classes.toolbarActionButton
            }
            startIcon={action.icon && <action.icon />}
            disabled={action.disabled ? action.disabled : false}
          >
            {action.tooltip}
          </Button>
        ))
    );
  };

  const handleSearchChange = _.debounce((event) => {
    onSearchChange(event.target.value);
  }, 500);

  return (
    <Root className={classes.actionsContainer}>
      <div className={classes.primaryActionsContainer}>
        {actions &&
          actions
            .filter((a) => a.isPrimary)
            .map((action, idx) => (
              <div className={classes.primaryActionContainer} key={idx}>
                {action.component}
              </div>
            ))}
        <div className={classes.searchFieldContainer}>
          <TextField
            className={classes.searchField}
            onChange={handleSearchChange}
            InputProps={{
              id: "simpleTableSearch",
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          {licenseRestriction ? (
            <LicenseRestriction requires={licenseRestriction}>
              {getToolbarButton()}
            </LicenseRestriction>
          ) : (
            getToolbarButton()
          )}
        </div>
      </div>
    </Root>
  );
};

ServerToolbar.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.object).isRequired,
  licenseRestriction: PropTypes.object,
  onSearchChange: PropTypes.func.isRequired,
};

export default ServerToolbar;
