import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { alpha } from "@mui/material/styles";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { resetOrgData, logoutFromBlumira } from "../redux/actions/Page";
import BluLicense from "../lib/license";
import { LicenseAPI } from "../lib/api/LicenseAPI";

import {
  Badge,
  Avatar,
  Button,
  Select,
  Tooltip,
  Toolbar,
  MenuItem,
  IconButton,
  FormControl,
} from "@mui/material";

import { WILDCARD_ORG_ID } from "../lib/api/API";
import logo from "../images/blumira-logo-white.svg";

import TopbarView from "./TopbarView";
import SearchableListDialog from "./Components/SearchableListDialog";

const PREFIX = "HeaderViewStyles";

const classes = {
  title: `${PREFIX}-title`,
  backButton: `${PREFIX}-backButton`,
  keyboardBackspaceIcon: `${PREFIX}-keyboardBackspaceIcon`,
  formControl: `${PREFIX}-formControl`,
  toolbarActions: `${PREFIX}-toolbarActions`,
  userAvatar: `${PREFIX}-userAvatar`,
  userLogout: `${PREFIX}-userLogout`,
  topbarNav: `${PREFIX}-topbarNav`,
  orgDialogButton: `${PREFIX}-orgDialogButton`,
  inputRoot: `${PREFIX}-inputRoot`,
  orgFieldLabel: `${PREFIX}-orgFieldLabel`,
};

const Root = styled(Toolbar)(({ theme }) => ({
  [`& .${classes.title}`]: {
    fontWeight: 700,
    margin: theme.spacing(1),
  },
  [`& .${classes.backButton}`]: {
    color: theme.palette.common.white,
    boxShadow: "none",
    marginLeft: theme.shape.padding,
    border: `1px solid ${theme.palette.common.white}`,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
  },
  [`& .${classes.keyboardBackspaceIcon}`]: {
    paddingRight: 5,
  },
  [`& .${classes.formControl}`]: {
    margin: theme.spacing(1),
    minWidth: 200,
    borderRadius: theme.shape.borderRadius,
    position: "relative",
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    transition: theme.transitions.create(["background-color"], {
      duration: theme.transitions.duration.short,
    }),
  },
  [`& .${classes.toolbarActions}`]: {
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: "4px",
  },
  [`& .${classes.userAvatar}`]: {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  [`& .${classes.userLogout}`]: {
    margin: theme.spacing(1),
  },
  [`& .${classes.topbarNav}`]: {
    flexGrow: 1,
  },
  [`& .${classes.orgDialogButton}`]: {
    padding: 10,
    minWidth: 200,
    "& p": {
      margin: 0,
    },
    color: theme.palette.common.white,
    boxShadow: "none",
    marginLeft: theme.shape.padding,
    border: `1px solid ${theme.palette.common.white}`,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
  },
  [`& .${classes.inputRoot}`]: {
    backgroundColor: "transparent",
    height: 46,
    color: "white",
    "& svg": {
      color: alpha(theme.palette.common.white, 0.54),
    },
  },
  [`& .${classes.orgFieldLabel}`]: {
    color: "white",
  },
}));

export const ADMIN_MODE_ORG = {
  id: WILDCARD_ORG_ID,
  name: "Administer All Orgs",
};

const setRoute = (currentOrg, payload) => {
  const { toplevel, secondlevel } = payload;

  // to account for administering all orgs for SAs
  if (!currentOrg) return { toplevel, secondlevel };

  const shouldRedirectToDashboard =
    !(currentOrg.market === 20 && currentOrg.parentId === null) &&
    payload.toplevel === "mspportal";

  if (shouldRedirectToDashboard) {
    return { toplevel: "dashboard", secondlevel: "summary" };
  }

  return { toplevel, secondlevel };
};

const HeaderView = (props) => {
  const [orgList, setOrgList] = useState([]);
  const [currentParentId, setCurrentParentId] = useState(null);
  const [isOrgDialogOpen, setIsOrgDialogOpen] = useState(false);
  const [showGoToPortalButton, setShowGoToPortalButton] = useState(false);

  const {
    first_name = "",
    last_name = "",
    picture = "",
    superadmin = false,
    orgRoles = [],
  } = props.user;

  useEffect(() => {
    const newOrgList = superadmin
      ? [ADMIN_MODE_ORG, ...props.session.settings.userOrgs]
      : props.session.settings.userOrgs;
    setOrgList(newOrgList);

    if (props.session?.settings?.userOrgs) {
      // find current org to determine if child
      const currentOrg = props.session.settings.userOrgs.find(
        ({ id }) => id === props.orgId
      );
      let parentId = currentOrg ? currentOrg.parentId : null;

      // if the current org is a child account
      // find the user's role in parent account
      if (parentId) {
        setCurrentParentId(parentId);
        // get the current user's roles in parent account
        const parentRoles = orgRoles.filter(({ orgId }) => orgId === parentId);

        // if current user is viewing a child account and
        // is assigned 'administrator' in the parent account
        // display 'Go to MSP Portal' button
        const isAdministratorInParentOrg = parentRoles.some(
          ({ roleId }) => roleId === 10
        );
        if (!!isAdministratorInParentOrg || superadmin)
          setShowGoToPortalButton(true);
        else setShowGoToPortalButton(false);
      } else {
        setShowGoToPortalButton(false);
      }
    }
  }, [
    props.session,
    props.session.settings.userOrgs,
    superadmin,
    props.orgId,
    orgRoles,
  ]);

  const fullName = `${first_name || ""} ${last_name || ""}`;

  const routeToOrg = async (orgId) => {
    const { routeToOrg, type, query = {}, session, payload } = props;
    const { userOrgs } = session.settings;
    const currentOrg = userOrgs.find((obj) => obj.id === orgId);

    const licenseAPI = new LicenseAPI();
    const license = await licenseAPI.get(orgId);
    BluLicense.orgId = orgId;
    BluLicense.activeLicense = license.data;

    const newPayload = setRoute(currentOrg, payload);
    routeToOrg({
      type,
      payload: { ...newPayload, orgId },
      query: { ...query },
    });
  };

  const handleNotificationClick = () => {
    props.dispatch({
      type: "PAGE",
      payload: {
        orgId: props.payload.orgId,
        toplevel: "user",
        secondlevel: "alertsettings",
      },
    });
  };

  const handleUserClick = () => {
    props.dispatch({
      type: "PAGE",
      payload: {
        orgId: props.payload.orgId,
        toplevel: "user",
        secondlevel: "myaccount",
      },
    });
  };

  const toggleOrgDialog = () => {
    setIsOrgDialogOpen(!isOrgDialogOpen);
  };

  const handleBackToPortal = async () => {
    const { routeToOrg } = props;

    // fetch license for parent org
    const licenseAPI = new LicenseAPI();
    const license = await licenseAPI.get(currentParentId);
    BluLicense.orgId = currentParentId;
    BluLicense.activeLicense = license.data;

    routeToOrg({
      type: "PAGE",
      payload: {
        toplevel: "mspportal",
        orgId: currentParentId,
        secondlevel: "accounts",
      },
    });
  };

  const handleLogout = async () => {
    if (localStorage.getItem("viewingQuery")) {
      localStorage.removeItem("viewingQuery");
    }
    props.routeToLogin();
  };

  return (
    <Root>
      <div>
        <img style={{ height: "32px" }} src={logo} alt="Logo" />
        {showGoToPortalButton && (
          <Button
            className={classes.backButton}
            variant={"contained"}
            color={"primary"}
            onClick={handleBackToPortal}
            datacy={"goToMSPPortalButton"}
          >
            Go to MSP Portal
          </Button>
        )}
      </div>
      <div className={classes.topbarNav}>
        {props.withNav && (
          <TopbarView
            toplevel={props.payload.toplevel}
            secondlevel={props.payload.secondlevel}
            dispatch={props.dispatch}
            orgId={props.payload.orgId}
          />
        )}
      </div>

      {orgList.length > 0 && orgList.length <= 5 ? (
        <FormControl className={classes.formControl}>
          <Select
            className={classes.inputRoot}
            align="center"
            value={props.payload.orgId}
            onChange={(e) => routeToOrg(e.target.value)}
            data-cy={"selectOrgBtn"}
            inputProps={{ "aria-label": "Select an Organization" }}
          >
            {orgList
              .sort((a, b) =>
                a.name.localeCompare(b.name, "en", { sensitivity: "base" })
              )
              .map((org) => (
                <MenuItem key={org.id} value={org.id} data-cy={"selectOrgItem"}>
                  {org.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      ) : orgList.length > 5 ? (
        <Button
          className={classes.orgDialogButton}
          onClick={toggleOrgDialog}
          data-cy={"selectOrgBtn"}
        >
          <p>
            {orgList.find(({ id }) => id === props.payload.orgId).name || ""}
          </p>
        </Button>
      ) : null}

      <IconButton
        data-cy={"notificationSettingsBtn"}
        className={classes.toolbarActions}
        onClick={handleNotificationClick}
        sx={{ marginLeft: "12px" }}
      >
        <Tooltip title="Notification Settings" overlap={"rectangular"}>
          <Badge badgeContent={0} color="secondary">
            <NotificationsIcon />
          </Badge>
        </Tooltip>
      </IconButton>

      <IconButton
        data-cy={"accountSettingsBtn"}
        className={classes.toolbarActions}
        onClick={handleUserClick}
      >
        <Tooltip title="Account Settings">
          <Avatar alt={fullName} src={picture} className={classes.userAvatar} />
        </Tooltip>
      </IconButton>

      <IconButton
        datacy={"logoutBtn"}
        className={classes.toolbarActions}
        onClick={handleLogout}
      >
        <Tooltip title="Logout">
          <ExitToAppIcon />
        </Tooltip>
      </IconButton>
      <SearchableListDialog
        open={isOrgDialogOpen}
        onItemClick={routeToOrg}
        onClose={toggleOrgDialog}
        title={"Switch organizations"}
        items={orgList.sort((a, b) =>
          a.name.localeCompare(b.name, "en", { sensitivity: "base" })
        )}
        actionItem={
          superadmin && (
            <Button
              variant={"contained"}
              color={"primary"}
              onClick={() => {
                toggleOrgDialog();
                routeToOrg(WILDCARD_ORG_ID);
              }}
              datacy={"adminAllOrgsOrgSwitcherButton"}
            >
              Administer all orgs
            </Button>
          )
        }
      />
    </Root>
  );
};

HeaderView.propTypes = {
  payload: PropTypes.shape({}),
  query: PropTypes.shape({}),
  routeToLogin: PropTypes.func.isRequired,
  routeToOrg: PropTypes.func.isRequired,
};

HeaderView.defaultProps = {
  payload: {},
  query: {},
};

const mapDispatchToProps = (dispatch, getState) => ({
  routeToLogin: () => {
    dispatch(logoutFromBlumira);
  },
  routeToOrg: (props) => {
    dispatch(resetOrgData);
    dispatch({ type: "PAGE", payload: props.payload });
  },
});

const mapStateToProps = (state) => {
  const { session = {} } = state;
  return {
    session: session,
    user: session.settings.user,
    orgId: state.page.payload.orgId,
    payload: state.location.payload,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(HeaderView);
