import React from "react";
import { connect } from "react-redux";
import FindingsDetailView from "./FindingsDetailView";
import FindingsListView from "./FindingsListView";
import LoadingTable from "views/Components/LoadingTable";
import { filterSampleFindings } from "lib/util/FindingUtils";
import { styled } from "@mui/material/styles";

const PREFIX = "FindingsPageView";
const classes = {
  outerContainer: `${PREFIX}-outerContainer`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.outerContainer}`]: {
    padding: `0 ${theme.shape.padding}px`,
  },
}));

const renderInnerView = (props) => {
  if (props.ready) {
    if (props.isDetailsPage) {
      if (props.finding) {
        return <FindingsDetailView {...props} finding={props.finding[0]} />;
      } else {
        return <LoadingTable isMaterial />;
      }
    } else {
      const findings = filterSampleFindings(
        props.findings,
        props.license,
        props.integrations
      );
      return <FindingsListView {...props} findings={findings} />;
    }
  }
  return <LoadingTable isMaterial />;
};

const FindingsView = (props) => {
  return (
    <Root className={classes.outerContainer}>{renderInnerView(props)}</Root>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    license: state.license,
    isDetailsPage: !!state.location.payload.id1,
  };
};

export default connect(mapStateToProps)(FindingsView);
