import React, { useState, useEffect, Fragment, useRef } from "react";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from "@mui/icons-material/Help";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import LoadingTable from "./LoadingTable";
import LicenseRestriction from "views/Components/License";
import FormControl from "@mui/material/FormControl";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";
import Link from "redux-first-router-link";
import { useInView } from "react-intersection-observer";
import _ from "lodash";

import moment from "moment-timezone";

import {
  rootClasses,
  Root,
  contextMenuClasses,
  StyledMenu,
} from "./simpleTableStyles";

const MAX_BATCH_COUNT = 4000;

const renderValue = (column, model) => {
  const { field, lookup, renderValue } = column;
  if (renderValue) {
    return renderValue(column, model);
  } else if (lookup) {
    const val = model[field];
    return _.isFunction(lookup) ? lookup(val) : lookup[val];
  } else if (typeof model[field] === "string" && model[field].includes("{")) {
    return <pre>{model[field]}</pre>;
  } else {
    return model[field];
  }
};

const isRuleEnabled = (rule) => rule.status === 1 || rule.status === 101; //1=enabled, 101=pending_enabled

export const sortModelData = (orderBy, order, tableData, column) => {
  return _(tableData)
    .orderBy(
      [
        (obj) => {
          const filterByKey =
            column && column.sortByUnrendered
              ? _.get(obj._model, orderBy, "")
              : _.get(obj, orderBy, "");
          if (["created", "modified"].includes(orderBy)) {
            const format = "MMM D, YYYY h:mm A z";
            return moment.tz(filterByKey, format, moment.tz.guess(true));
          }
          if (_.isString(filterByKey)) {
            return filterByKey.toLowerCase();
          }
          return filterByKey;
        },
      ],
      [order]
    )
    .value();
};

export const getRenderedValue = (column, model) => {
  return Object.hasOwnProperty.call(column, "renderValue")
    ? column.renderValue(column, model)
    : model[column.field];
};

export const wrapModelList = (columns, data) => {
  return data.map((model) => {
    const obj = {
      id: model.id,
      _model: model,
    };

    columns
      .filter((column) => column.sortable !== false)
      .map((column) => {
        obj[column.field] = getRenderedValue(column, model);
      });
    return obj;
  });
};

function TableSelectionControls(props) {
  const {
    selectedIds,
    shownIds,
    allData,
    renderMultiselectActions,
    setSelectedTableData,
  } = props;
  // Only show the Select All button if all shown IDs are already selected
  const showSelectAll =
    selectedIds.length < allData.length &&
    shownIds.every((id) => selectedIds.includes(id));

  const handleSelectNone = () => {
    setSelectedTableData([]);
  };
  const handleSelectAll = () => {
    setSelectedTableData(allData.map((model) => model.id));
  };
  return (
    <div className={rootClasses.multiselectContainerV2}>
      <IconButton
        title="Unselect all items"
        className={rootClasses.multiSelectCloseButton}
        onClick={handleSelectNone}
      >
        <CloseIcon />
      </IconButton>
      <span className={rootClasses.multiSelectionText}>
        {selectedIds.length > 1 && selectedIds.length === allData.length ? (
          <span className={rootClasses.multiSelectionText}>
            {"All "}
            <span className={rootClasses.multiSelectionNumber}>
              {selectedIds.length}
            </span>
            {" items are selected"}
          </span>
        ) : showSelectAll &&
          selectedIds.length === shownIds.length &&
          selectedIds.length > 1 ? (
          <span className={rootClasses.multiSelectionText}>
            {"All "}
            <span className={rootClasses.multiSelectionNumber}>
              {selectedIds.length}
            </span>
            {" items on this page are selected"}
          </span>
        ) : showSelectAll && selectedIds.length === shownIds.length ? (
          <span className={rootClasses.multiSelectionText}>
            {"The "}
            <span className={rootClasses.multiSelectionNumber}>
              {selectedIds.length}
            </span>
            {" item on this page is selected"}
          </span>
        ) : showSelectAll ? (
          <span className={rootClasses.multiSelectionText}>
            <span className={rootClasses.multiSelectionNumber}>
              {selectedIds.length}
            </span>
            {" items are selected"}
          </span>
        ) : selectedIds.length > 1 ? (
          <span className={rootClasses.multiSelectionText}>
            <span className={rootClasses.multiSelectionNumber}>
              {selectedIds.length}
            </span>
            {` items out of ${allData.length} are selected`}
          </span>
        ) : (
          <span className={rootClasses.multiSelectionText}>
            <span className={rootClasses.multiSelectionNumber}>
              {selectedIds.length}
            </span>
            {` item out of ${allData.length} is selected`}
          </span>
        )}
      </span>
      {showSelectAll && (
        <Button
          className={rootClasses.multiSelectButton}
          onClick={handleSelectAll}
        >
          {`Select all ${allData.length}`}
        </Button>
      )}
      <div className={rootClasses.multiSelectSpacer} />
      {renderMultiselectActions(selectedIds, allData)}
    </div>
  );
}

function SimpleRow(props) {
  const [isExpanded, setIsExpanded] = useState(false);
  const {
    handleSelect,
    selected = false,
    hasMultiSelect = false,
    switchProps,
    disabled = false,
    renderExpanded,
    className,
  } = props;
  const onClick = (event, model, fieldName) => {
    if (props.onClick) {
      props.onClick(event, model, fieldName);
    }
  };

  const handleOnChange = () => {
    handleSelect();
  };

  const handleSwitchOnChange = (model) => {
    props.handleSwitchToggle(model);
  };

  return (
    <>
      <TableRow
        key={props.model.id}
        className={`${rootClasses.tableRow} ${className}`}
        datacy={"simpleTableRow"}
      >
        {renderExpanded && (
          <TableCell padding="checkbox">
            <IconButton onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        )}
        {hasMultiSelect && (
          <TableCell key="multiselect" className={rootClasses.tableCell}>
            <Checkbox
              color={"primary"}
              checked={selected}
              disabled={disabled}
              onChange={handleOnChange}
              inputProps={{ value: props.model.id }}
            />
          </TableCell>
        )}
        {switchProps && (
          <TableCell
            key="switchProps"
            className={rootClasses.tableCell}
            style={{ width: "90px" }}
          >
            {/* if sample finding, then hide the enable/disable switch - to ensure it can't be toggled */}
            {props.model.name.substr(0, 8) !== "[SAMPLE]" && (
              <Tooltip
                classes={{ tooltip: rootClasses.tooltip }}
                placement="right"
                title={
                  isRuleEnabled(props.model)
                    ? "Disable this rule"
                    : "Enable this rule"
                }
              >
                <Switch
                  color={"primary"}
                  disabled={
                    !switchProps.user.superadmin &&
                    !switchProps.user.orgRoles.some(
                      (r) =>
                        r.orgId === switchProps.orgId &&
                        (r.roleId === 10 || r.roleId === 20)
                    )
                  }
                  checked={isRuleEnabled(props.model)}
                  onChange={() => handleSwitchOnChange(props.model)}
                  inputProps={{ value: props.model.id }}
                  datacy={`rulesSwitchBtn${
                    isRuleEnabled(props.model) ? "Enabled" : "Disabled"
                  }`}
                />
              </Tooltip>
            )}
          </TableCell>
        )}
        {props.columns &&
          props.columns.map((column, index) => (
            <TableCell
              key={`${column.field}-${index}`.replace(/\s+/g, "")}
              onClick={(event) => onClick(event, props.model, column.field)}
              style={column.css}
              className={
                props.contextMenuByColumn[column.field]
                  ? rootClasses.tableCellHasMenu
                  : rootClasses.tableCell
              }
              datacy="simpleTableCell"
            >
              {column.icon ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ marginRight: 5 }}>
                    {column.icon(column, props.model)}
                  </div>
                  {renderValue(column, props.model)}
                </div>
              ) : (
                renderValue(column, props.model)
              )}
            </TableCell>
          ))}
        {props.actions ? (
          <TableCell key="actions" align="right" style={{ width: "90px" }}>
            {props.actions &&
              props.actions.map((action) => (
                <Tooltip
                  key={action.tooltip}
                  title={action.tooltip}
                  classes={{ tooltip: rootClasses.tooltip }}
                >
                  <IconButton
                    datacy={`simpleTableRowIconButton`}
                    className={rootClasses.actions}
                    color={action.color || "default"}
                    onClick={(e) =>
                      action.onClick(e, props.model, props.columns)
                    }
                  >
                    <action.icon fontSize="small" />
                  </IconButton>
                </Tooltip>
              ))}
          </TableCell>
        ) : Object.keys(props.contextMenuByColumn).length > 0 ? (
          <TableCell key="actions" align="right" style={{ width: "90px" }}>
            <Tooltip
              key={"ellipsisMenu"}
              title={"Open Context Menu"}
              classes={{ tooltip: rootClasses.tooltip }}
            >
              <IconButton
                className={rootClasses.actions}
                color={"default"}
                onClick={(e) => props.handleClick(e, props.model, null)}
              >
                <MoreHorizIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </TableCell>
        ) : null}
      </TableRow>
      {isExpanded && (
        <TableRow>
          <TableCell padding="checkbox" />
          <TableCell colSpan={props.columns.length}>
            {renderExpanded(props.model)}
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

export default function SimpleTable(props) {
  const {
    isFetching,
    emptyText,
    searchData,
    isNorthStar,
    licenseRestriction,
    disabledTableData = [],
  } = props;

  const [maxBatchCount, setMaxBatchCount] = useState(MAX_BATCH_COUNT);
  const [hasSorted, setHasSorted] = useState(false);
  const [selectedTableData, setSelectedTableData] = useState([]);
  const [tableData, setTableData] = useState(props.data);
  const [resultsCount, setResultsCount] = useState(props.resultsCount);
  const [anchorEl, setAnchorEl] = useState(null);
  const [multiSelectAnchorEl, setMultiSelectAnchorEl] = useState(null);
  const [count, setCount] = useState(props.data ? props.data.length : 0);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [textFilter, setTextFilter] = useState("");
  const [contextMenu, setContextMenu] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [textIndex, setTextIndex] = useState([]);
  const [idIndex, setIdIndex] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentDataLength, setCurrentDataLength] = useState(0); // eslint-disable-line no-unused-vars
  const [limitIsReached, setlimitReached] = useState(false);
  const [contextMenuByColumn, setContextMenuByColumn] = useState({});
  const [contextMenuSwitchTxt, setContextMenuSwitchTxt] =
    useState("Enable/Disable");

  useEffect(() => {
    if (props.maxBatchCount) setMaxBatchCount(props.maxBatchCount);
    else setMaxBatchCount(MAX_BATCH_COUNT);
  }, [props.maxBatchCount]);

  useEffect(() => {
    if (props.selectedTableData.length)
      setSelectedTableData(props.selectedTableData);
  }, [props.selectedTableData]);

  useEffect(() => {
    if (selectedTableData.length) setSelectedTableData([]);
  }, [isFetching]);

  useEffect(() => {
    if (props.initialOrderBy && !hasSorted) {
      const [initialOrderByProperty, initialOrder] = props.initialOrderBy;
      setOrder(initialOrder);
      setOrderBy(initialOrderByProperty);
    }
  }, [props.initialOrderBy]);

  useEffect(() => {
    if (props.isFetching) {
      return;
    }

    if (props.data) {
      setCount(props.data ? props.data.length : 0);
      buildTextIndex(props.data);
    }

    const existingRowsPerPage = parseInt(localStorage.getItem("rowsPerPage"));

    if (existingRowsPerPage) {
      setRowsPerPage(existingRowsPerPage);
    }

    const rowsPerPageToUse = existingRowsPerPage
      ? existingRowsPerPage
      : rowsPerPage;

    if (props.data.length - page * rowsPerPageToUse <= 0) {
      const backPage = Math.floor(props.data.length / rowsPerPageToUse) - 1;
      setPage(backPage < 0 ? 0 : backPage);
    }

    const tableData = wrapModelList(props.columns, props.data);
    setTableData(tableData);
    setFilteredData(tableData);
    setResultsCount(props.resultsCount);
    setCurrentDataLength(
      filteredData.slice(
        page * rowsPerPageToUse,
        page * rowsPerPageToUse + rowsPerPageToUse
      ).length
    );
  }, [props.data, props.columns, props.isFetching]);

  useEffect(() => {
    const _cMenus = {};
    if (props.contextMenuItems && props.columns) {
      props.columns.forEach((column) => {
        const _found = props.contextMenuItems.filter((cm) => {
          if (cm.limitToFields) {
            return cm.limitToFields.includes(column.field);
          }
          return true;
        });
        if (_found.length > 0) {
          _cMenus[column.field] = _found;
        }
      });
    }
    setContextMenuByColumn(_cMenus);
  }, [props.columns, props.contextMenuItems]);

  useEffect(() => {
    const column = props.columns.find((col) => col.field === orderBy);
    const filterData = sortModelData(orderBy, order, tableData, column).filter(
      (model) => idIndex.indexOf(model.id) >= 0
    );

    setFilteredData(filterData);
    setCount(filterData.length);

    const res =
      filterData.length === 1
        ? filterData.length + " result"
        : filterData.length + " results";

    setResultsCount(res);
    setCurrentDataLength(
      filteredData.slice(0 * rowsPerPage, 0 * rowsPerPage + rowsPerPage).length
    );
  }, [idIndex, orderBy, order]);

  useEffect(() => {
    let data = searchData !== undefined ? searchData : textFilter;
    setTextFilter(data);
    updateFilterIds(data, textIndex);
  }, [searchData, textIndex]);

  const buildTextIndex = (data) => {
    const _textIndex = [];

    const searchColumns = props.columns.filter((column) => column.searchable);

    data.map((model) => {
      const _text = searchColumns.map((column) => {
        const value = renderValue(column, model) || "";
        if (typeof value === "string") return value.toLowerCase();
        return value;
      });
      _textIndex.push({
        id: model.id,
        textIndex: _text.join(" "),
      });
    });
    setTextIndex(_textIndex);
    updateFilterIds(textFilter, _textIndex);
  };

  const handleTextFilterChange = (event) => {
    var _textFilter = "";
    if (event) {
      _textFilter = event.target.value;
    }

    // set the filter text from user input, or clear
    setTextFilter(_textFilter);

    // set the ids of models found be searchable text
    updateFilterIds(_textFilter, textIndex);

    // reset to the first page so search results show up
    setPage(0);
  };

  const updateFilterIds = (textFilter, textIndex) => {
    const _idIndex = [];
    const _filters = textFilter
      .toLowerCase()
      .split(" ")
      .filter((i) => i);
    if (_filters.length === 0) {
      textIndex.map((i) => _idIndex.push(i.id));
    } else {
      textIndex.map((index) => {
        var found = true;
        _filters.map((_textFilter) => {
          if (index.textIndex.indexOf(_textFilter) === -1) {
            found = false;
          }
        });
        if (found) {
          _idIndex.push(index.id);
        }
      });
    }
    setIdIndex(_idIndex);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setCurrentDataLength(
      filteredData.slice(
        newPage * rowsPerPage,
        newPage * rowsPerPage + rowsPerPage
      ).length
    );
  };

  const handleChangeCount = (event) => {
    const newRowsPerPage = event.target.value;
    localStorage.setItem("rowsPerPage", newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    setCurrentDataLength(
      filteredData.slice(
        0 * newRowsPerPage,
        0 * newRowsPerPage + newRowsPerPage
      ).length
    );
  };

  const createSortHandler = (property) => (event) => {
    setHasSorted(true);
    handleRequestSort(event, property);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";

    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const getContextMenuItems = (fieldName) => {
    return (
      props.contextMenuItems?.filter(
        (cmi) => !(cmi.limitToFields && !cmi.limitToFields.includes(fieldName))
      ) || undefined
    );
  };

  const handleClick = (event, model, fieldName) => {
    if (props.rowClick) props.rowClick(model);
    const filteredContextMenuItems = getContextMenuItems(fieldName);

    if (filteredContextMenuItems && filteredContextMenuItems.length > 0) {
      if (props.rowToggleSwitches) {
        //if current user has permissions to enable/disable, show context menu
        //adding another check here to ensure that sample findings can't be toggled
        if (
          model.name.substr(0, 8) !== "[SAMPLE]" &&
          (props.rowToggleSwitches.user.superadmin ||
            props.rowToggleSwitches.user.orgRoles.some(
              (r) =>
                r.orgId === props.rowToggleSwitches.orgId &&
                (r.roleId === 10 || r.roleId === 20)
            ))
        ) {
          isRuleEnabled(model)
            ? setContextMenuSwitchTxt("Disable this rule")
            : setContextMenuSwitchTxt("Enable this rule");
          setContextMenu({
            anchorEl: event.currentTarget,
            model: model,
            fieldName,
          });
        } else {
          //else, just show the details modal
          props.handleRulesModal(model);
        }
      } else {
        setContextMenu({
          anchorEl: event.currentTarget,
          model: model,
          fieldName,
        });
      }
    }
  };

  const menuClick = (menuItem) => {
    if (menuItem.modalTabValue) {
      props.setTabValue(menuItem.modalTabValue);
    }
    menuItem.onClick(contextMenu);
    setContextMenu({});
  };

  // isDisabled on the menu item is a func that returns
  // true or false dependent on whatever criteria you define
  // in your contextMenuItems definition
  const isDisabled = (menuItem) => {
    if (menuItem.isDisabled) return menuItem.isDisabled(contextMenu);

    return false;
  };

  const toggleExportMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const toggleMultiSelectMenu = (e) => {
    setMultiSelectAnchorEl(e.currentTarget);
  };

  const myScrollToAlertRef = useRef(null);

  const [inViewRef, inView] = useInView();

  const executeScroll = () =>
    myScrollToAlertRef.current.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });

  const handleLimitReached = () => {
    //if Alert is in the viewport already, update the setlimitReach state
    //which will add the .attention class to the Alert applying the custom css animation to make it flash
    //drawing the user's attention to it, hopefully :)
    if (inView) {
      setlimitReached(true);
      //then just remove the class right after the animation is done,
      //so this process can repeat again if the user tries to click a checkbox again
      setTimeout(function () {
        setlimitReached(false);
      }, 1000);
    }
    //otherwise (if it's not already in viewport), scroll it into view first
    //then do the state/class changes
    else {
      executeScroll();
      //add a slight delay before applying the .attention class this time
      //to ensure that it has time to scroll the Alert into view first
      setTimeout(function () {
        setlimitReached(true);
      }, 500);
      setTimeout(function () {
        setlimitReached(false);
      }, 1500);
    }
  };

  const getCurrentlyShownData = () =>
    filteredData
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((data) => data.id);

  const handleSelectCurrentPage = () => {
    //if the "select all" checkbox is already checked here when the user clicks it,
    //then we ignore this limitReached logic
    //if it's NOT already checked,
    //then we need to see if the maximum limit has been hit
    //and if it has reached the limit, then we need to handle it (show the Alert)
    if (
      !isTableCheckboxChecked() &&
      selectedTableData.length === maxBatchCount
    ) {
      handleLimitReached();
      return;
    }
    //then whether the limit has been reached or not
    //we need to update the state item that we're using to track which items have been selected
    const currentlyShownData = getCurrentlyShownData();
    const boxesSelectedOnOtherPages = selectedTableData.filter(
      (id) => currentlyShownData.indexOf(id) === -1
    );
    const selectedDisabledTableData = selectedTableData.filter((id) =>
      disabledTableData.includes(id)
    );
    //if the user is UNchecking the "select all",
    //then we only want the checked boxes from all the other pages (if any)
    //else the box is not yet checked, meaning the user is checking "select all"
    //so we update our state item with all the checked boxes on the current page
    //as well as any that are already checked on all other pages
    const newSelectedTableData = isTableCheckboxChecked()
      ? _.uniq(boxesSelectedOnOtherPages.concat(selectedDisabledTableData))
      : _.uniq(
          boxesSelectedOnOtherPages.concat(
            currentlyShownData,
            selectedDisabledTableData
          )
        );

    setSelectedTableData(newSelectedTableData);
    props.handleSelectTableItem(newSelectedTableData);
  };

  const isTableCheckboxChecked = () => {
    const currentlyShownData = getCurrentlyShownData();
    const boxesSelectedOnCurrentPage = currentlyShownData.filter((id) =>
      selectedTableData.includes(id)
    );
    //if at least one box has been checked on at least one page
    //AND all of the current page data equals the currently selected boxes on the current page
    //then return true to ensure the "select all" checkbox as checked
    //else return false to ensure the "select all" checkbox is NOT checked
    return (
      selectedTableData.length > 0 &&
      currentlyShownData.length === boxesSelectedOnCurrentPage.length
    );
  };

  const handleSelectItem = (itemId) => {
    let itemPresent = isItemSelected(itemId);
    if (!itemPresent && selectedTableData.length === maxBatchCount) {
      // TODO: delete when our API supports batch resolutions
      handleLimitReached();
      return;
    }
    let newSelectedTableData = itemPresent
      ? [...selectedTableData].filter((id) => id !== itemId)
      : [...selectedTableData].concat([itemId]);
    setSelectedTableData(newSelectedTableData);
    props.handleSelectTableItem(newSelectedTableData);
  };

  const isItemDisabled = (itemId) => disabledTableData.includes(itemId);

  const isItemSelected = (itemId) => {
    return selectedTableData.find((id) => {
      return itemId === id;
    });
  };

  const getToolbarButton = () => {
    return (
      props.toolbarActions &&
      props.toolbarActions
        .filter((a) => !a.isPrimary)
        .map((action) => (
          <Button
            key={action.tooltip}
            onClick={action.onClick}
            datacy={action.dataCy ? action.dataCy : "simpleTableToolbarBtn"}
            className={
              action.alignRight
                ? [
                    rootClasses.toolbarActionButton,
                    rootClasses.alignRightAction,
                  ]
                : rootClasses.toolbarActionButton
            }
            startIcon={action.icon && <action.icon />}
            disabled={action.disabled ? action.disabled : false}
          >
            {action.tooltip}
          </Button>
        ))
    );
  };

  const hasMultiSelect =
    !!props.renderMultiselectActions ||
    !!props.multiselectOptions ||
    props.hasMultiSelect;

  return (
    <Root ref={myScrollToAlertRef}>
      {isNorthStar && !isFetching && (
        <div>
          {props.title && (
            <div className={rootClasses.simpleTableTitleContainer}>
              <p className={rootClasses.simpleTableTitle}>{props.title}</p>
              {props.titleTooltip && (
                <Tooltip
                  title={props.titleTooltip}
                  placement={"bottom-start"}
                  classes={{ tooltip: rootClasses.tooltip }}
                >
                  <HelpIcon className={rootClasses.titleTooltipIcon} />
                </Tooltip>
              )}
              {props.description && (
                <div className={rootClasses.simpleTableDescription}>
                  {props.description}
                </div>
              )}
            </div>
          )}
          <div className={rootClasses.actionsContainer}>
            <div className={rootClasses.primaryActionsContainer}>
              {props.toolbarActions &&
                props.toolbarActions
                  .filter((a) => a.isPrimary)
                  .map((action, idx) => (
                    <div
                      className={rootClasses.primaryActionContainer}
                      key={idx}
                    >
                      {action.component}
                    </div>
                  ))}
              <div
                className={rootClasses.searchFieldContainer}
                style={{ marginBottom: props.isFindingsTable ? "23px" : "" }}
              >
                <TextField
                  value={textFilter}
                  onChange={handleTextFilterChange}
                  className={rootClasses.newSearchField}
                  InputProps={{
                    id: "simpleTableSearch",
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                {licenseRestriction ? (
                  <LicenseRestriction requires={licenseRestriction}>
                    {getToolbarButton()}
                  </LicenseRestriction>
                ) : (
                  getToolbarButton()
                )}
              </div>
            </div>
            {props.moreMenuItems && (
              <div>
                <IconButton
                  onClick={toggleExportMenu}
                  aria-controls={"simple-menu"}
                  datacy={"moreMenuItemsBtn"}
                >
                  <MoreHorizIcon />
                </IconButton>
                <Menu
                  keepMounted
                  id="simple-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  disableScrollLock={true}
                  onClose={() => setAnchorEl(null)}
                >
                  {props.moreMenuItems.map((item, idx) => {
                    if (item.licenseRestriction) {
                      return (
                        <LicenseRestriction
                          key={`l${idx}`}
                          requires={item.licenseRestriction}
                        >
                          <MenuItem
                            onClick={() => {
                              item.onClick();
                              setAnchorEl(null);
                            }}
                            datacy={item.datacy}
                          >
                            {item.label}
                          </MenuItem>
                        </LicenseRestriction>
                      );
                    }
                    return (
                      <MenuItem
                        key={`m${idx}`}
                        onClick={() => {
                          item.onClick();
                          setAnchorEl(null);
                        }}
                      >
                        {item.label}
                      </MenuItem>
                    );
                  })}
                  {props.exportData && <Divider orientation="horizontal" />}
                  {props.exportData && (
                    <MenuItem
                      onClick={() => props.exportData("csv")}
                      disabled={props.isExporting}
                    >
                      Export CSV
                    </MenuItem>
                  )}
                  {props.exportData && (
                    <MenuItem
                      onClick={() => props.exportData("jsonl")}
                      disabled={props.isExporting}
                    >
                      Export JSON-L
                    </MenuItem>
                  )}
                </Menu>
              </div>
            )}
          </div>
        </div>
      )}
      {!isFetching &&
        props.advancedFilterComponent &&
        props.advancedFilterComponent}
      {!isFetching && props.quickFilterComponent && props.quickFilterComponent}
      <div className={rootClasses.paper}>
        {!isNorthStar && !props.isSearchHidden && (
          <Toolbar
            variant="dense"
            className={
              props.title || props.toolbarActions
                ? rootClasses.toolBar
                : rootClasses.toolBarSearchOnly
            }
          >
            {(props.title || props.toolbarActions) && (
              <Grid
                container
                alignItems="center"
                className={rootClasses.titleBar}
              >
                <Typography variant="h6" id="tableTitle" component="div">
                  {props.title || ""}
                </Typography>
                <Divider orientation="vertical" flexItem />
                {props.toolbarActions &&
                  props.toolbarActions.map((action) => (
                    <Button
                      key={action.tooltip}
                      size="small"
                      color="primary"
                      onClick={action.onClick}
                      startIcon={<action.icon />}
                      datacy={"simpleTableToolbarBtn"}
                    >
                      {action.tooltip}
                    </Button>
                  ))}
              </Grid>
            )}

            <FormControl className={rootClasses.searchField}>
              <TextField
                onChange={handleTextFilterChange}
                size="small"
                label="Search"
                value={textFilter}
                InputProps={{
                  id: "simpleTableSearch",
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={() => handleTextFilterChange()}
                      >
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Toolbar>
        )}
        <StyledMenu
          id="context-menu"
          anchorEl={contextMenu.anchorEl}
          keepMounted
          open={Boolean(contextMenu.anchorEl)}
          onClose={() => setContextMenu({})}
          disableScrollLock={true}
        >
          {props.contextMenuItems &&
            props.contextMenuItems.map((menuItem, idx) => {
              const itemText = menuItem.detectionRule
                ? contextMenuSwitchTxt
                : menuItem.text;
              if (menuItem.link) {
                return (
                  <MenuItem
                    key={idx}
                    className={contextMenuClasses.linkLi}
                    datacy={menuItem.datacy}
                  >
                    <Link
                      to={menuItem.link}
                      target={"_blank"}
                      onClick={() => menuClick(menuItem)}
                    >
                      {itemText}
                    </Link>
                  </MenuItem>
                );
              } else {
                if (isDisabled(menuItem))
                  return (
                    <Tooltip
                      key={idx}
                      title={menuItem.disabledTooltip}
                      placement={"left"}
                      classes={{ tooltip: contextMenuClasses.tooltip }}
                    >
                      <MenuItem
                        datacy={menuItem.datacy}
                        className={contextMenuClasses.disabledMenuItem}
                      >
                        {itemText}
                      </MenuItem>
                    </Tooltip>
                  );

                return (
                  <MenuItem
                    key={idx}
                    datacy={menuItem.datacy}
                    className={contextMenuClasses.buttonLi}
                    onClick={() => menuClick(menuItem)}
                  >
                    <Button
                      fullWidth
                      className={contextMenuClasses.menuItemButton}
                    >
                      {itemText}
                    </Button>
                  </MenuItem>
                );
              }
            })}
        </StyledMenu>
        {isFetching ? (
          <LoadingTable isMaterial />
        ) : (
          <div className={rootClasses.tableOuterContainer}>
            <div className={rootClasses.paginationContainer}>
              {(selectedTableData.length <= 0 ||
                (!props.multiselectOptions &&
                  !props.renderMultiselectActions)) &&
                resultsCount}
              {!!selectedTableData.length && props.multiselectOptions ? (
                <div className={rootClasses.multiselectContainer}>
                  <Button
                    onClick={toggleMultiSelectMenu}
                    className={rootClasses.multiSelectButton}
                    endIcon={<ArrowDropDownIcon />}
                  >
                    Resolve selected as
                  </Button>
                  <Menu
                    keepMounted
                    id="simple-menu"
                    anchorEl={multiSelectAnchorEl}
                    open={Boolean(multiSelectAnchorEl)}
                    disableScrollLock={true}
                    onClose={() => setMultiSelectAnchorEl(null)}
                  >
                    {props.multiselectOptions &&
                      props.multiselectOptions.map((option, idx) => (
                        <MenuItem
                          key={idx}
                          onClick={() => {
                            props.handleMultiSelection(
                              option,
                              selectedTableData
                            );
                            setMultiSelectAnchorEl(null);
                          }}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                  </Menu>

                  {selectedTableData.length >= maxBatchCount && (
                    <Alert
                      ref={inViewRef}
                      icon={false}
                      className={
                        limitIsReached === true
                          ? rootClasses.multiSelectAlert + " attention"
                          : rootClasses.multiSelectAlert
                      }
                    >
                      <span>
                        Maximum number of items ({maxBatchCount}) selected
                      </span>
                    </Alert>
                  )}
                </div>
              ) : !!selectedTableData.length &&
                props.renderMultiselectActions ? (
                <TableSelectionControls
                  selectedIds={selectedTableData}
                  shownIds={getCurrentlyShownData()}
                  allData={tableData}
                  renderMultiselectActions={props.renderMultiselectActions}
                  setSelectedTableData={setSelectedTableData}
                />
              ) : (
                <div />
              )}
              {count > 10 &&
                !(
                  selectedTableData.length && props.renderMultiselectActions
                ) && (
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100, 250]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeCount}
                    classes={{
                      selectLabel: rootClasses.paginationText,
                      displayedRows: rootClasses.paginationText,
                    }}
                  />
                )}
            </div>
            <TableContainer className={rootClasses.table}>
              <Table aria-label="simple table" datacy="simpleTable">
                <TableHead>
                  <TableRow key="headRow">
                    {props.renderExpanded && <TableCell padding="checkbox" />}
                    {/*
                    TODO: code below will be used
                    when our API supports batch resolutions
                  */}
                    {hasMultiSelect && (
                      <TableCell key="multiselect">
                        <Checkbox
                          color={"primary"}
                          onChange={handleSelectCurrentPage}
                          checked={isTableCheckboxChecked()}
                          inputProps={{
                            "aria-label": "Select all",
                          }}
                        />
                      </TableCell>
                    )}

                    {!!props.rowToggleSwitches && (
                      <TableCell key="toggleSwitches"></TableCell>
                    )}

                    {props.columns &&
                      props.columns.map((column, index) => (
                        <TableCell
                          key={`${column.field}-${index}}`.replace(/\s+/g, "")}
                          sortDirection={
                            orderBy === column.field ? order : false
                          }
                        >
                          {column.title && column.sortable === false ? (
                            <div className={rootClasses.tableSortLabel}>
                              {column.title}
                            </div>
                          ) : (
                            <TableSortLabel
                              className={rootClasses.tableSortLabel}
                              active={orderBy === column.field}
                              direction={
                                orderBy === column.field ? order : "asc"
                              }
                              onClick={createSortHandler(column.field)}
                            >
                              {column.title}
                              {orderBy === column.field ? (
                                <span className={rootClasses.visuallyHidden}>
                                  {order === "desc"
                                    ? "sorted descending"
                                    : "sorted ascending"}
                                </span>
                              ) : null}
                            </TableSortLabel>
                          )}
                          {column.subTitle && (
                            <>
                              <br></br>
                              {column.subTitle}
                            </>
                          )}
                        </TableCell>
                      ))}
                    {(props.actions || props.contextMenuItems) && (
                      <TableCell key="actions" align="right" />
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData.length ? (
                    filteredData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((model, index) => (
                        <SimpleRow
                          index
                          key={model.id}
                          model={model._model}
                          columns={props.columns}
                          actions={props.actions}
                          onClick={handleClick}
                          selected={!!isItemSelected(model.id)}
                          disabled={isItemDisabled(model.id)}
                          switchProps={props.rowToggleSwitches}
                          renderExpanded={props.renderExpanded}
                          handleSwitchToggle={props.handleSwitchToggle}
                          handleSelect={() => handleSelectItem(model.id)}
                          hasMultiSelect={hasMultiSelect}
                          contextMenuItems={props.contextMenuItems}
                          contextMenuByColumn={contextMenuByColumn}
                          handleClick={handleClick}
                          className={
                            props.rowClick && rootClasses.tableRowInteractive
                          }
                        />
                      ))
                  ) : (
                    <Fragment />
                  )}
                  {!filteredData.length && emptyText ? (
                    <TableRow className={rootClasses.tableRowEmpty}>
                      <TableCell
                        key="empty"
                        datacy={`emptyTableCell`}
                        className={rootClasses.tableCellEmpty}
                        colSpan={1000}
                      >
                        {emptyText}
                      </TableCell>
                    </TableRow>
                  ) : (
                    <Fragment />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {count > 10 && (
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100, 250]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeCount}
                classes={{
                  selectLabel: rootClasses.paginationText,
                  displayedRows: rootClasses.paginationText,
                }}
              />
            )}
          </div>
        )}
      </div>
    </Root>
  );
}

SimpleTable.propTypes = {
  isFetching: PropTypes.bool,
  emptyText: PropTypes.object,
  pagination: PropTypes.bool,
  selectedTableData: PropTypes.array,
  handleSelectTableItem: PropTypes.func,
};

SimpleTable.defaultProps = {
  data: [],
  columns: [],
  isFetching: false,
  emptyText: null,
  selectedTableData: [],
  handleSelectTableItem: () => {
    return null;
  },
};

SimpleRow.propTypes = {
  model: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
};
