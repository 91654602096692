import React, { useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import ServerToolbar from "views/Components/ServerToolbar";
import ServerTable from "views/Components/ServerTable";
import { getColumns } from "./common/blockingHelpers";

const PREFIX = "BlockTable";

const classes = {
  tableTitle: `${PREFIX}-tableTitle`,
  tableTitleContainer: `${PREFIX}-tableTitleContainer`,
};

export const Root = styled("div")(({ theme }) => ({
  paddingTop: 30,
  [`& .${classes.tableTitle}`]: {
    fontSize: 22,
    marginRight: 8,
    marginBottom: 8,
    fontWeight: "bold",
  },
  [`& .${classes.tableTitleContainer}`]: {
    display: "flex",
    alignItems: "center",
  },
}));

const BlockTable = ({
  actions,
  onRowClick,
  refresh,
  toolbarActions,
  tableData,
}) => {
  const defaultParams = [
    {
      field: "until",
      operator: "eq",
      negate: true,
      value: null,
    },
    {
      field: "blockType",
      operator: "eq",
      value: tableData.type,
    },
  ];

  const [params, setParams] = useState(defaultParams);

  const handleSearchChange = (value) => {
    setParams([
      ...defaultParams,
      {
        field: "search",
        value: value,
      },
    ]);
  };

  return (
    <Root>
      <div className={classes.tableTitleContainer}>
        <p className={classes.tableTitle}>{`${tableData.title} Entries`}</p>
      </div>
      <ServerToolbar
        actions={toolbarActions}
        onSearchChange={handleSearchChange}
      />
      <ServerTable
        actions={actions}
        columns={getColumns(tableData.type)}
        endpoint={"/blocklistentry"}
        onRowClick={onRowClick}
        refresh={refresh}
        pageSizeOptions={[10, 25, 50, 100, 250]}
        params={params}
        sortable={true}
        uniqueName={`BlockTable${tableData.type}`}
      />
    </Root>
  );
};

BlockTable.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.object).isRequired,
  onRowClick: PropTypes.func,
  refresh: PropTypes.number,
  toolbarActions: PropTypes.arrayOf(PropTypes.object).isRequired,
  tableData: PropTypes.object.isRequired,
};

export default BlockTable;
