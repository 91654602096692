import React from "react";
import PropTypes from "prop-types";
import { Box, Stack } from "@mui/material";
import CheckCircle from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import ActionDialog from "views/Components/ActionDialog";
import { Status } from "views/Components/Finding/ResponseDialog";

const getDisplayName = (logger, fieldMap, evidenceName) => {
  for (const [wfName, evName] of fieldMap.entries()) {
    if (evName === evidenceName) {
      return wfName;
    }
  }
  logger.error(`Unknown evidence name: ${evidenceName}`);
  return null;
};

const ResponseCompleted = ({
  activeWorkflow,
  currentAction,
  currentStatus,
  setCurrentStatus,
  fieldMap,
  logger,
}) => {
  const DescriptionCompleted = () => {
    return (
      <Stack spacing={1.5} sx={{ typography: "body2" }}>
        <Box display="flex">
          <Box sx={{ width: 125 }}>
            <strong>Action</strong>
          </Box>
          <Box>
            {currentStatus === Status.SUCCESS_ACTION ? (
              <CheckCircle color={"success"} />
            ) : (
              <ErrorIcon color={"error"} />
            )}{" "}
            {activeWorkflow.buttonName}{" "}
            {currentStatus === Status.SUCCESS_ACTION ? "succeeded" : "failed"}
          </Box>
        </Box>
        {Object.entries(currentAction).map(([fieldName, value]) => (
          <Box display="flex" key={fieldName + value}>
            <Box sx={{ width: 125 }}>
              <strong>{getDisplayName(logger, fieldMap, fieldName)}</strong>
            </Box>
            <Box>{value}</Box>
          </Box>
        ))}
      </Stack>
    );
  };

  return (
    <ActionDialog
      open={
        currentStatus === Status.SUCCESS_ACTION ||
        currentStatus === Status.FAILED_ACTION
      }
      title={`Response action ${
        currentStatus === Status.SUCCESS_ACTION ? "successful" : "failed"
      }`}
      description={<DescriptionCompleted />}
      actions={[
        {
          title: "Close",
          action: () => setCurrentStatus(Status.NONE),
          variant: "contained",
        },
      ]}
      actionsInfo={
        <a
          href="https://blumira.com/ideas"
          target={"_blank"}
          rel={"noopener noreferrer"}
        >
          Tell us what you think <OpenInNewIcon />
        </a>
      }
    />
  );
};

ResponseCompleted.propTypes = {
  activeWorkflow: PropTypes.object.isRequired,
  currentAction: PropTypes.object.isRequired,
  currentStatus: PropTypes.string.isRequired,
  setCurrentStatus: PropTypes.func.isRequired,
  fieldMap: PropTypes.object.isRequired,
  logger: PropTypes.object.isRequired,
};

export default ResponseCompleted;
