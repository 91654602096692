import React from "react";

import { CheckCircle, InfoOutlined } from "@mui/icons-material";

import { Card, Button, Tooltip } from "@mui/material";

import { Root, rootClasses } from "../styles.js";

const licensesToHideStripePurchase = ["XDR", "SIEM_ENDPOINT"];
const productLedLicenses = ["SIEM_STARTER", "SIEM_STARTER_COMPLIANCE"];

// show the CTA to self-purchase if the plan is Stripe-enabled, the plan is not selected
// and the user's active plan is not one of XDR or SIEM_ENDPOINT or a legacy offering
const showStripeEnabledBuyButton = ({
  isUserOnLegacyPlan,
  displayPlan = {},
  isScheduledLicenseActive,
  activePlanLicenseValue = "FREE",
}) => {
  const { stripeConfig, licenseValue } = displayPlan;
  const shouldHidePurchaseButton =
    !isScheduledLicenseActive &&
    licensesToHideStripePurchase.includes(activePlanLicenseValue);

  return (
    !isUserOnLegacyPlan &&
    stripeConfig &&
    !shouldHidePurchaseButton &&
    activePlanLicenseValue !== licenseValue
  );
};

export const PlanOption = ({
  plan = {},
  changePlan,
  license = {},
  startXDRTrial,
  contactSalesUrl,
  isUserOnLegacyPlan,
  openCheckoutSession,
  activePlan = "ADVANCED",
  isScheduledLicenseActive,
  shouldShowTrialCta = false,
}) => {
  const isActive = () => {
    if (
      plan.optionalUpgradeLicense &&
      plan.optionalUpgradeLicense.licenseValue === activePlan
    )
      return true;
    else return plan.licenseValue === activePlan;
  };

  const shouldNameWrap = () => {
    if (activePlan === "SIEM_STARTER_COMPLIANCE") return true;
  };

  return (
    <Root>
      <Card
        className={rootClasses.newPlanContainer}
        style={{
          minHeight: activePlan === "FREE" && shouldShowTrialCta ? 180 : 140,
        }}
        datacy={"editionPackagingOption"}
      >
        <div
          className={rootClasses.newPlanPrimaryContent}
          style={{ width: plan.optionalUpgradeLicense ? "50%" : "100%" }}
        >
          <div>
            {isActive() && (
              <div className={rootClasses.checkContainer}>
                <CheckCircle className={rootClasses.currentCheck} />
                <p className={rootClasses.currentText}>Current plan</p>
              </div>
            )}
            <p className={rootClasses.planNameText}>{plan.name}</p>
            {plan.price?.text && (
              <div className={rootClasses.priceContainer}>
                <p className={rootClasses.priceText}>{plan.price.text}</p>
                {plan.price?.tooltip && (
                  <Tooltip
                    placement={"bottom-start"}
                    title={plan.price.tooltip}
                  >
                    <InfoOutlined data-pendo-id={plan.price.tooltipPendoId} />
                  </Tooltip>
                )}
              </div>
            )}
            {plan?.description && (
              <div className={rootClasses.descriptionTextContainer}>
                <p className={rootClasses.descriptionText}>
                  {plan.description}
                </p>
              </div>
            )}
            {plan?.bannerText && (
              <div className={rootClasses.bannerTextContainer}>
                <p className={rootClasses.bannerText}>{plan.bannerText}</p>
              </div>
            )}
            <div>
              {plan.listItems?.map(
                ({ bulletPoints = [], sectionTitle = "" }, i) => (
                  <React.Fragment key={i}>
                    {sectionTitle && (
                      <p className={rootClasses.editionSectionTitle}>
                        {sectionTitle}
                      </p>
                    )}
                    <ul className={rootClasses.detailTextContainer}>
                      {bulletPoints.map(
                        ({ text, tooltip = "", tooltipPendoId }, j) => (
                          <li
                            key={`${i}-${j}`}
                            className={rootClasses.detailText}
                          >
                            {text}
                            {tooltip && (
                              <Tooltip
                                placement={"bottom-start"}
                                title={tooltip}
                              >
                                <InfoOutlined data-pendo-id={tooltipPendoId} />
                              </Tooltip>
                            )}
                          </li>
                        )
                      )}
                    </ul>
                  </React.Fragment>
                )
              )}
            </div>
          </div>
          <div className={rootClasses.cardCtaButtonContainer}>
            {/* plan is XDR and user is not currently on XDR */}
            {shouldShowTrialCta &&
              plan?.licenseValue === "XDR" &&
              activePlan !== plan.licenseValue &&
              !license.isTagged("limited-to-xdr") && (
                <Button
                  color={"primary"}
                  variant={"outlined"}
                  onClick={startXDRTrial}
                  className={rootClasses.cardCtaButton}
                >
                  Try XDR free for 30 days
                </Button>
              )}
            {/* plan is direct-sales enabled and user is not currently on the plan */}
            {plan?.contactSales &&
              (activePlan !== plan.licenseValue ||
                (shouldShowTrialCta &&
                  plan?.licenseValue === "XDR" &&
                  activePlan === plan.licenseValue)) && (
                <Button
                  target={"_blank"}
                  color={"primary"}
                  variant={"outlined"}
                  href={contactSalesUrl}
                  rel={"noopener noreferrer"}
                  className={`${rootClasses.cardCtaButton} ${rootClasses.secondCardCtaButton}`}
                >
                  Connect with Sales
                </Button>
              )}
          </div>
        </div>
        {plan.optionalUpgradeLicense && (
          <div
            className={`${rootClasses.newPlanPrimaryContent} ${rootClasses.upgradeContent}`}
          >
            <div>
              {/*
                goofy styling here since 'SIEM Starter + Compliance' is
                long and looks silly when it is the current plan
              */}
              <p
                className={
                  shouldNameWrap()
                    ? `${rootClasses.planNameText} ${rootClasses.wrapPlanNameText}`
                    : rootClasses.planNameText
                }
              >
                {plan.optionalUpgradeLicense.name}
              </p>
              {plan.optionalUpgradeLicense.price?.text && (
                <div className={rootClasses.priceContainer}>
                  <p className={rootClasses.priceText}>
                    {plan.optionalUpgradeLicense.price.text}
                  </p>
                  {plan.optionalUpgradeLicense.price?.tooltip && (
                    <Tooltip
                      placement={"bottom-start"}
                      title={plan.optionalUpgradeLicense.price.tooltip}
                    >
                      <InfoOutlined
                        data-pendo-id={
                          plan.optionalUpgradeLicense.price.tooltipPendoId
                        }
                      />
                    </Tooltip>
                  )}
                </div>
              )}
              {plan.optionalUpgradeLicense?.description && (
                <div className={rootClasses.descriptionTextContainer}>
                  <p className={rootClasses.descriptionText}>
                    {plan.optionalUpgradeLicense.description}
                  </p>
                </div>
              )}
              <div>
                {plan.optionalUpgradeLicense.listItems?.map(
                  ({ bulletPoints = [], sectionTitle = "" }, i) => (
                    <React.Fragment key={i}>
                      {sectionTitle && (
                        <p className={rootClasses.editionSectionTitle}>
                          {sectionTitle}
                        </p>
                      )}
                      <ul className={rootClasses.detailTextContainer}>
                        {bulletPoints.map(
                          ({ text, tooltip = "", tooltipPendoId }, i) => (
                            <li key={i} className={rootClasses.detailText}>
                              {text}
                              {tooltip && (
                                <Tooltip
                                  placement={"bottom-start"}
                                  title={tooltip}
                                >
                                  <InfoOutlined
                                    data-pendo-id={tooltipPendoId}
                                  />
                                </Tooltip>
                              )}
                            </li>
                          )
                        )}
                      </ul>
                    </React.Fragment>
                  )
                )}
              </div>
            </div>
            <div className={rootClasses.cardCtaButtonContainer}>
              {showStripeEnabledBuyButton({
                isUserOnLegacyPlan: isUserOnLegacyPlan,
                displayPlan: plan.optionalUpgradeLicense,
                activePlanLicenseValue: activePlan,
                isScheduledLicenseActive: isScheduledLicenseActive,
              }) && productLedLicenses.includes(activePlan) ? (
                <Button
                  color={"primary"}
                  variant={"outlined"}
                  onClick={() =>
                    changePlan(plan.optionalUpgradeLicense.stripeConfig.priceId)
                  }
                  className={`${rootClasses.cardCtaButton} ${rootClasses.secondCardCtaButton}`}
                >
                  Change to this plan
                </Button>
              ) : (
                showStripeEnabledBuyButton({
                  isUserOnLegacyPlan: isUserOnLegacyPlan,
                  displayPlan: plan.optionalUpgradeLicense,
                  activePlanLicenseValue: activePlan,
                  isScheduledLicenseActive: isScheduledLicenseActive,
                }) && (
                  <Button
                    color={"primary"}
                    variant={"outlined"}
                    onClick={() =>
                      openCheckoutSession(
                        plan.optionalUpgradeLicense.stripeConfig.priceId
                      )
                    }
                    className={`${rootClasses.cardCtaButton} ${rootClasses.secondCardCtaButton}`}
                  >
                    {plan.optionalUpgradeLicense.stripeConfig
                      .purchaseButtonText || "Buy"}
                  </Button>
                )
              )}
            </div>
          </div>
        )}
      </Card>
    </Root>
  );
};
