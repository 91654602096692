import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import moment from "moment-timezone";
import { connect } from "react-redux";
import { resetOrgData } from "redux/actions/Page";
import SimpleTable from "views/Components/SimpleTable";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { RECEIVE_SETTINGS } from "redux/actions/Session";
// INTERNAL COMPONENTS
import AlertMessage, { useAlertMessage } from "views/Components/AlertMessage";
import MSPDetailDialog from "../../Components/MSP/MSPDetailDialog";
import { Org } from "lib/models/Org";
import { Settings } from "lib/models/Settings";
import Request from "lib/api/Request";
import { loadPageData } from "redux/actions/Request";
import BluLicense from "lib/license";
import { LicenseAPI } from "lib/api/LicenseAPI";
import MSPRemoveAccountDialog from "views/Components/MSP/MSPDetailDialog/MSPRemoveAccountDialog";

import { renderConfigCell } from "../../Components/MSP/helpers";
import { SubaccountBillingHistory } from "lib/models/SubaccountBillingHistory";

import {
  getChildOrgs,
  getOrgRoles,
  getShouldDisplayDataRetentionMessage,
  getShouldDisplayAgentSeatCountMessage,
} from "./utils";

import { DEFAULT_FEATURES, hasLegacyLicense } from "utils";

import { get } from "lodash";

const renderDateCell = (column, model) => {
  const timeValue = model[column.field];
  if (!timeValue) {
    return "";
  }
  return moment.utc(timeValue).tz(moment.tz.guess(true)).format("lll z");
};

const MSPAccountsPageView = (props) => {
  const {
    childOrganizationsList,
    currentUserEmail,
    orgId,
    reload,
    routeToOrg,
    users,
  } = props;
  const [alertMessageProps, displayMessage] = useAlertMessage();
  const [persons, setPersons] = useState([]);
  const [loading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [totalAgents, setTotalAgents] = useState({});
  const [openFindings, setOpenFindings] = useState({});
  const [enabledRules, setEnabledRules] = useState({});
  const [adminUsersIds, setAdminUsersIds] = useState([]);
  const [linkForNewTab, setLinkForNewTab] = useState({});
  const [isConfirming, setIsConfirming] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [isolatedAgents, setIsolatedAgents] = useState({});
  const [selectedOrgModel, setSelectedOrgModel] = useState(false);
  const [isAccountDialogOpen, setIsAccountDialogOpen] = useState(false);
  const [userIdsForAccountCreation, setUserIdsForAccountCreation] = useState(
    []
  );
  const [mspBillingPlan, setMspBillingPlan] = useState({});
  const [
    shouldDisplayDataRetentionMessage,
    setShouldDisplayDataRetentionMessage,
  ] = useState(false);
  const [
    licenseValueToDisplayAgentCountHelperText,
    setLicenseValueToDisplayAgentCountHelperText,
  ] = useState("");
  const [creationStep, setCreationStep] = useState(0);
  const [removeAccountDialogOpen, setRemoveAccountDialogOpen] = useState(false);

  const columns = [
    {
      title: "Account Name",
      field: "name",
      searchable: true,
    },
    {
      title: "User Count",
      field: "config.userCount",
      renderValue: renderConfigCell(null, 0),
    },
    {
      title: "Edition",
      field: "config.license",
      searchable: true,
      renderValue: renderConfigCell(null, ""),
    },
    {
      title: "Rules",
      field: "enabledRules",
      searchable: true,
      renderValue: renderConfigCell(enabledRules, 0),
    },
    {
      title: "Unresolved findings",
      field: "openFindings",
      searchable: true,
      renderValue: renderConfigCell(openFindings, 0),
    },
    {
      title: "Account created",
      field: "created",
      searchable: true,
      renderValue: renderDateCell,
    },
  ];

  const blumiraAgentColumns = [
    {
      title: "Agent Count",
      field: "totalAgents",
      searchable: true,
      renderValue: renderConfigCell(totalAgents, 0),
    },
    {
      title: "Agents Isolated",
      field: "isolatedAgents",
      searchable: true,
      renderValue: renderConfigCell(isolatedAgents, 0),
    },
  ];

  useEffect(() => {
    if (childOrganizationsList.length) {
      const childOrgsIdList = childOrganizationsList
        .map((obj) => obj.id)
        .join();

      const openFindingsRequest = new Request(
        "/finding",
        [
          { field: "orgId", operator: "in", value: childOrgsIdList },
          { field: "status", operator: "eq", value: 0 },
        ],
        {
          outputFields: ["orgId"],
          transform: [{ value: "count", operator: "count" }],
        }
      );

      const rulesRequest = new Request(
        "/rule",
        [
          { field: "orgId", operator: "in", value: childOrgsIdList },
          { field: "status", operator: "eq", value: 1 },
        ],
        {
          outputFields: ["orgId"],
          transform: [{ value: "count", operator: "count" }],
        }
      );

      const totalAgentsRequest = new Request(
        "/lcagent",
        [{ field: "orgId", operator: "in", value: childOrgsIdList }],
        {
          outputFields: ["orgId"],
          transform: [{ value: "count", operator: "count" }],
        }
      );

      const isolatedAgentsRequest = new Request(
        "/lcagent",
        [
          { field: "orgId", operator: "in", value: childOrgsIdList },
          { field: "isIsolated", operator: "eq", value: true },
        ],
        {
          outputFields: ["orgId"],
          transform: [{ value: "count", operator: "count" }],
        }
      );

      const mspBillingPlanRequest = new Request("/mspbillingplan");

      // requests that should fire regardless of features
      const promises = [
        openFindingsRequest.get(),
        rulesRequest.get(),
        mspBillingPlanRequest.get().catch((err) => null),
      ];

      // if the feature is enabled, include license restricted
      // requests else only fire off the defaults
      let promisesToExecute = props.isBlumiraAgentEnabled
        ? promises.concat([
            totalAgentsRequest.get(),
            isolatedAgentsRequest.get(),
          ])
        : promises;

      Promise.all(promisesToExecute).then((objList) => {
        const [
          openFindingsResponse,
          rulesResponse,
          mspBillingPlanResponse,
          totalAgentsResponse,
          isolatedAgentsResponse,
        ] = objList;

        const findingsByOrg = Object.fromEntries(
          openFindingsResponse.map((finding) => [finding.orgId, finding.count])
        );
        setOpenFindings(findingsByOrg);

        const rulesByOrg = Object.fromEntries(
          rulesResponse.map((rule) => [rule.orgId, rule.count])
        );
        setEnabledRules(rulesByOrg);

        setMspBillingPlan(
          mspBillingPlanResponse.length === 0 ? null : mspBillingPlanResponse
        );

        if (totalAgentsResponse) {
          const totalAgentsByOrg = Object.fromEntries(
            totalAgentsResponse.map((agent) => [agent.orgId, agent.count])
          );
          setTotalAgents(totalAgentsByOrg);
        }

        if (isolatedAgentsResponse) {
          const isolatedAgentsByOrg = Object.fromEntries(
            isolatedAgentsResponse.map((agent) => [agent.orgId, agent.count])
          );
          setIsolatedAgents(isolatedAgentsByOrg);
        }

        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  }, [childOrganizationsList]);

  useEffect(() => {
    if (users && orgId && !!selectedOrgModel?.id) {
      let usersEnrolledInOrg = users
        .filter((user) =>
          user.orgRoles.find((role) => role.orgId === selectedOrgModel.id)
        )
        .map((user) => user.id);
      setUserIdsForAccountCreation(usersEnrolledInOrg);
    } else {
      let usersEnrolledInOrg = users
        .filter((user) => user.orgRoles.find((role) => role.roleId === 10))
        .map((user) => user.id);
      setUserIdsForAccountCreation(usersEnrolledInOrg);
    }
    const adminUsers = users
      .filter((user) => user.orgRoles.find((role) => role.roleId === 10))
      .map((user) => user.id);
    setAdminUsersIds(adminUsers);
  }, [users, orgId, selectedOrgModel]);

  useEffect(() => {
    const persons = userIdsForAccountCreation.map((userId) => {
      const fullUser = users.find(({ id }) => userId === id);

      const orgRoles = getOrgRoles(fullUser);

      return {
        id: userId,
        orgRoles: orgRoles,
      };
    });

    setPersons(persons);
  }, [userIdsForAccountCreation, users]);

  const handleEditTableRowClick = (rowDetails) => {
    const { model } = rowDetails;

    // if the 'model' in row details has a hash value we have everything
    // we need to populate the detail dialog, otherwise, 'model' in the
    // rowDetails is just JSON so set a new model for editing and saving
    let orgModel = model && model.hash ? model : new Org(model);

    setSelectedOrgModel(orgModel);
    toggleAccountDialog();
  };

  const handleOpenApp = async (rowDetails) => {
    // model here is the selected org
    const { model = {} } = rowDetails;

    const licenseAPI = new LicenseAPI();
    const license = await licenseAPI.get(model.id);
    BluLicense.orgId = model.id;
    BluLicense.activeLicense = license.data;

    routeToOrg({
      type: "PAGE",
      payload: {
        orgId: model.id,
        toplevel: "dashboard",
        secondlevel: "summary",
      },
    });
  };

  const handleOpenInNewTab = (rowDetails) => {
    const { model = {} } = rowDetails;
    setLinkForNewTab({
      type: "PAGE",
      payload: {
        orgId: model.id,
        toplevel: "dashboard",
        secondlevel: "summary",
      },
    });
  };

  const toggleAccountDialog = () => {
    setIsAccountDialogOpen(!isAccountDialogOpen);
    setIsConfirming(false);
    setShouldDisplayDataRetentionMessage(false);
    setErrorMessage(false);
    setLicenseValueToDisplayAgentCountHelperText("");
  };

  const handleAddNewAccount = (event) => {
    // instantiate a new model tying this child to
    // its parent and default the market to MSP
    setSelectedOrgModel(
      new Org({ parentId: orgId, market: 20, config: { license: "FREE" } })
    );
    toggleAccountDialog();
  };

  const getRolesForSaving = (orgRoles) => {
    let rolesToReturn = orgRoles;
    let isUpdating = !!selectedOrgModel.id;
    const alreadyEnrolled = orgRoles.find(
      ({ orgId }) => orgId === selectedOrgModel.id
    );

    // if updating users on the org that
    // are not enrolled in selected org
    if (isUpdating && !alreadyEnrolled) {
      // default to 'responder' only
      let newOrgRole = {
        roleId: 40,
        orgId: selectedOrgModel.id,
      };

      // add new role to user's org roles
      // inside of org's persons array
      rolesToReturn = rolesToReturn.concat([newOrgRole]);
    }

    return rolesToReturn;
  };

  const getAgentSeatCountToSet = (orgModel) => {
    // Retrieve relevant values from the org model
    const userCount = get(orgModel, ["config", "userCount"], 1);
    const currentAgentSeatCount = get(
      orgModel,
      ["config", "agent_seat_count"],
      0
    );
    const license = get(orgModel, ["config", "license"], "FREE");

    let agentSeatCountToReturn;

    if (props.doesAnyChildOrgHaveLegacyLicense) {
      // If *any* child account has a legacy license, use the existing logic:
      // Set to the larger of user count or current agent seat count
      agentSeatCountToReturn = Math.max(userCount, currentAgentSeatCount);
    } else if (license === "M365") {
      // Orgs with an M365 license have a max of 5 agents
      agentSeatCountToReturn = 5;
    } else {
      // For non-legacy licenses, always set agent seat count to 2x user count
      agentSeatCountToReturn = userCount * 2;
    }

    return agentSeatCountToReturn;
  };

  const handleSubmit = (model) => {
    let isUpdating = !!selectedOrgModel.id;

    setIsSaving(true);

    // set up 'persons' value
    // these are the user ids selected in the simple table
    const persons = userIdsForAccountCreation.map((userId) => {
      // get list of user's org roles
      const { orgRoles } = users.find(({ id }) => userId === id);
      return {
        id: userId,
        orgRoles: getRolesForSaving(orgRoles),
      };
    });

    const agentSeatCountToSet = getAgentSeatCountToSet(selectedOrgModel);

    // ensure that any license features set are maintained
    const licenseFeaturesToMaintain = get(
      selectedOrgModel,
      ["config", "license_features"],
      []
    );

    const xdrLicenseFeatures = DEFAULT_FEATURES["XDR"];
    const siemEndpointLicenseFeatures = DEFAULT_FEATURES["SIEM_ENDPOINT"];
    const m365LicenseFeatures = ["LOGSHIPPING"];

    if (selectedOrgModel.config?.license === "SIEM_ENDPOINT") {
      selectedOrgModel.set({
        agentSeatCount: agentSeatCountToSet,
        licenseFeatures: [
          ...new Set([
            ...licenseFeaturesToMaintain,
            ...siemEndpointLicenseFeatures,
          ]),
        ],
        persons: persons,
      });
    } else if (selectedOrgModel.config?.license === "XDR") {
      selectedOrgModel.set({
        agentSeatCount: agentSeatCountToSet,
        licenseFeatures: [
          ...new Set([...licenseFeaturesToMaintain, ...xdrLicenseFeatures]),
        ],
        persons: persons,
      });
    } else if (selectedOrgModel.config?.license === "M365") {
      selectedOrgModel.set({
        agentSeatCount: agentSeatCountToSet,
        licenseFeatures: [
          ...new Set([...licenseFeaturesToMaintain, ...m365LicenseFeatures]),
        ],
        persons: persons,
      });
    } else {
      selectedOrgModel.set({
        agentSeatCount: agentSeatCountToSet,
        licenseFeatures: licenseFeaturesToMaintain,
        persons: persons,
      });
    }

    // if no license set, default to ADVANCED
    if (!selectedOrgModel.config?.license) {
      selectedOrgModel.set({
        config: {
          ...selectedOrgModel.config,
          license: "ADVANCED",
          licenseFeatures: licenseFeaturesToMaintain,
        },
      });
    }

    const func = isUpdating ? "update" : "create";
    selectedOrgModel[func]()
      .then(async (savedOrg) => {
        if (isUpdating && mspBillingPlan) {
          const license = mspBillingPlan.licenses.filter(
            (l) => l.license === selectedOrgModel.config?.license
          );

          if (license[0]) {
            const subaccountBillinHistory = new SubaccountBillingHistory({
              licenseId: license[0].id,
              planId: mspBillingPlan.id,
              subaccountId: selectedOrgModel.id,
              billableUsers: get(selectedOrgModel, ["config", "userCount"], 1),
            });
            await subaccountBillinHistory["create"]();
          }
        }

        reload();
        toggleAccountDialog();
        setCreationStep(0);
        setSelectedOrgModel(false);
        setIsConfirming(false);
        setIsSaving(false);
      })
      .catch((err) => {
        setErrorMessage(err.toString());
        setIsConfirming(false);
        setIsSaving(false);
      });
  };

  // for the second step of creating an account
  // user selects users to be enrolled
  const handleSelectUser = (selectedUserIds) => {
    setUserIdsForAccountCreation(selectedUserIds);
  };

  const handleChange = (value, name) => {
    let names = name.split(".");

    if (names.find((name) => name === "license")) {
      // this is where we can conditionally show a warning
      const newShouldShowMessageBoolean = getShouldDisplayDataRetentionMessage(
        value,
        selectedOrgModel
      );
      setShouldDisplayDataRetentionMessage(newShouldShowMessageBoolean);

      // this is where we can conditionally show information about agent seat count
      const newAgentSeatCountMessageLicense =
        getShouldDisplayAgentSeatCountMessage(value);
      setLicenseValueToDisplayAgentCountHelperText(
        newAgentSeatCountMessageLicense
      );
    }

    // this is to handle nested props
    // i.e. config.license on org model
    if (names.length > 1)
      selectedOrgModel.set({
        [names[0]]: { ...selectedOrgModel[names[0]], [names[1]]: value },
      });
    else selectedOrgModel.set({ [name]: value });
  };

  const handleOpenRemoveAccountModal = (model) => {
    setSelectedOrgModel(model);
    setRemoveAccountDialogOpen(true);
  };

  return (
    <>
      <SimpleTable
        isNorthStar
        isFetching={loading}
        title="Organizations"
        data={childOrganizationsList}
        columns={
          props.isBlumiraAgentEnabled
            ? columns.concat(blumiraAgentColumns)
            : columns
        }
        contextMenuItems={[
          {
            onClick: handleEditTableRowClick,
            text: "Account details",
            datacy: "accountDetailsContextMenuItem",
          },
          {
            onClick: handleEditTableRowClick,
            text: "Change user count",
            datacy: "changeUserCountContextMenuItem",
          },
          {
            onClick: handleOpenApp,
            text: "Open in Blumira App",
            datacy: "openInBlumiraAppContextMenuItem",
          },
          {
            onClick: handleOpenInNewTab,
            link: linkForNewTab,
            text: "Open in New Tab",
            datacy: "openInNewTabContextMenuItem",
          },
        ]}
        toolbarActions={[
          {
            icon: AddCircleIcon,
            tooltip: "Add New Account",
            onClick: handleAddNewAccount,
          },
        ]}
      />
      <MSPDetailDialog
        tableData={users}
        context={"account"}
        isSaving={isSaving}
        model={selectedOrgModel}
        open={isAccountDialogOpen}
        isConfirming={isConfirming}
        errorMessage={errorMessage}
        handleChange={handleChange}
        toggle={toggleAccountDialog}
        handleSubmit={handleSubmit}
        handleSelectTableItem={handleSelectUser}
        selectedTableData={userIdsForAccountCreation}
        disabledTableData={adminUsersIds}
        personsList={persons}
        reloadData={reload}
        shouldDisplayDataRetentionMessage={shouldDisplayDataRetentionMessage}
        licenseValueToDisplayAgentCountHelperText={
          licenseValueToDisplayAgentCountHelperText
        }
        creationStep={creationStep}
        setCreationStep={setCreationStep}
        doesAnyChildOrgHaveLegacyLicense={
          props.doesAnyChildOrgHaveLegacyLicense
        }
        handleOpenRemoveAccountModal={handleOpenRemoveAccountModal}
      />
      <AlertMessage {...alertMessageProps} />
      <MSPRemoveAccountDialog
        currentUserEmail={currentUserEmail}
        displayMessage={displayMessage}
        model={selectedOrgModel}
        onClose={() => setRemoveAccountDialogOpen(false)}
        open={removeAccountDialogOpen}
        reload={reload}
      />
    </>
  );
};

MSPAccountsPageView.propTypes = {
  loading: PropTypes.bool,
  orgId: PropTypes.string.isRequired,
  childOrganizationsList: PropTypes.arrayOf(PropTypes.shape({})),
  currentUserEmail: PropTypes.string.isRequired,
};

MSPAccountsPageView.defaultProps = {
  users: [],
  loading: false,
};

const mapStateToProps = (state) => {
  const { session, location } = state;
  const { orgId: mspOrgId } = location.payload;

  const orgListToFilter = _.get(session, `settings.userOrgs`, []);

  // getChildOrgs expects a a list of orgs and an
  // org id to filter and returns an array of orgs
  const childOrgsList = getChildOrgs(orgListToFilter, mspOrgId);

  const currentOrg = orgListToFilter.find(({ id }) => id === mspOrgId);

  const doesAnyChildOrgHaveLegacyLicense = childOrgsList.some(hasLegacyLicense);

  const currentOrgLicenseFeatures = get(
    currentOrg,
    ["config", "license_features"],
    []
  );

  const isBlumiraAgentEnabled = currentOrgLicenseFeatures.find(
    (feature) => feature === "LOGSHIPPING"
  );

  const currentUserEmail = _.get(session, "settings.user.email");

  return {
    orgId: mspOrgId,
    license: state.license,
    childOrganizationsList: childOrgsList,
    currentUserEmail: currentUserEmail,
    isBlumiraAgentEnabled: !!isBlumiraAgentEnabled,
    doesAnyChildOrgHaveLegacyLicense,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reload: () => {
      // refresh table data
      dispatch(loadPageData(true));

      // refresh settings for license updates
      const settings = new Settings();
      settings.read().then(() => {
        dispatch({ type: RECEIVE_SETTINGS, settings });
      });
    },
    routeToOrg: (props) => {
      dispatch(resetOrgData);
      dispatch({ type: "PAGE", payload: props.payload });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MSPAccountsPageView);
