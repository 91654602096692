import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { connect } from "react-redux";
import { loadPageData } from "redux/actions/Request";
import SimpleTable from "views/Components/SimpleTable";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Select,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Link,
  Tab,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import CloseIcon from "@mui/icons-material/Close";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ActionDialog from "views/Components/ActionDialog";
import AllowlistingFilter from "views/Components/Allowlisting/Filter";
import LicenseRestriction from "views/Components/License";
import { getPriorityIconColor, isRuleEnabled } from "lib/util/RulesUtils";

import { get } from "lodash";
import moment from "moment";

import Codex from "@blumira/blu_constants";

const PREFIX = "RulesPageView";
const rootClasses = {
  showRulesSelectTitle: `${PREFIX}-showRulesSelectTitle`,
  showRulesSelectItem: `${PREFIX}-showRulesSelectItem`,
};

const dialogClasses = {
  dialogHeaderContainer: `${PREFIX}-dialogHeaderContainer`,
  dialogTable: `${PREFIX}-dialogTable`,
  tableRowLabel: `${PREFIX}-tableRowLabel`,
  tableRowText: `${PREFIX}-tableRowText`,
  defaultStateDescrip: `${PREFIX}-defaultStateDescrip`,
  filtersContainer: `${PREFIX}-filtersContainer`,
  buttonWrap: `${PREFIX}-buttonWrap`,
  noFilters: `${PREFIX}-noFilters`,
  priorityContainer: `${PREFIX}-priorityContainer`,
  priorityCircle: `${PREFIX}-priorityCircle`,
  tabs: `${PREFIX}-tabs`,
  tabPanel: `${PREFIX}-tabPanel`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${rootClasses.showRulesSelectItem}`]: {
    marginRight: 10,
    minWidth: 240,
    backgroundColor: theme.palette.background.paper,
  },
}));

const RulesDialog = styled(Dialog)(({ theme }) => ({
  [`& .${dialogClasses.dialogHeaderContainer}`]: {
    display: "flex",
    paddingRight: 8,
    alignItems: "center",
    justifyContent: "space-between",
  },
  [`& .${dialogClasses.dialogTable}`]: {
    marginLeft: 30,
    marginRight: 30,
    marginTop: 30,
    "& td": {
      paddingBottom: 15,
      verticalAlign: "top",
    },
  },
  [`& .${dialogClasses.tableRowLabel}`]: {
    fontWeight: "bold",
    paddingRight: 20,
    whiteSpace: "nowrap",
    fontSize: "14px",
  },
  [`& .${dialogClasses.tableRowText}`]: {
    fontSize: "14px",
  },
  [`& .${dialogClasses.defaultStateDescrip}`]: {
    display: "block",
    marginTop: 10,
  },
  [`& .${dialogClasses.filtersContainer}`]: {
    paddingBottom: 40,
  },
  [`& .${dialogClasses.buttonWrap}`]: {
    borderTop: `1px solid ${theme.palette.divider}`,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingTop: 20,
    paddingRight: 20,
    position: "absolute",
    bottom: 0,
    left: 0,
    paddingBottom: 20,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  [`& .${dialogClasses.noFilters}`]: {
    margin: 20,
  },
  [`& .${dialogClasses.priorityContainer}`]: {
    display: "flex",
    alignItems: "center",
  },
  [`& .${dialogClasses.priorityCircle}`]: {
    width: 15,
    height: 15,
    marginRight: 5,
    borderRadius: "50%",
  },
  [`& .${dialogClasses.tabs}`]: {
    marginLeft: 30,
    marginRight: 30,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  [`& .${dialogClasses.tabPanel}`]: {
    paddingBottom: 30,
    maxHeight: 550,
    overflow: "scroll",
    minHeight: 550,
  },
}));

const RulesView = (props) => {
  const [rules, setRules] = useState([]);
  const [showRulesDropdownVal, setShowRulesDropdownVal] = useState("");
  const [open, setOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [selectedRule, setSelectedRule] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [tabValue, setTabValue] = useState("0");
  const [tableExtras, setTableExtras] = useState({});

  useEffect(() => {
    setIsLoading(true);
    const _tableExtras = {};
    if (
      props.rules?.length &&
      props.conditions?.length &&
      props.actions?.length &&
      props.findingTypes?.length
    ) {
      // TODO use getRuleDisplayInfo from src/lib/util/RulesUtils.js
      let rulesPlus = [];
      const thisOrgsRules = props.rules.filter((r) => r.orgId === props.orgId);
      const promises = thisOrgsRules.map((rule) => {
        const thisCondition = props.conditions?.filter(
          (c) => c.id === rule.conditionSet[0].condition
        )[0];
        const thisAction = props.actions?.filter(
          (a) => a.id === thisCondition?.targetAction
        )[0];
        const thisCategory = props.findingTypes?.filter(
          (t) => t.id === thisAction?.findingType
        )[0];
        const dataTypes = thisCondition?.tags?.filter(
          (t) => t.substring(0, 5) === "type:"
        );
        // These are the specific desired targetAction #s coming from the Condition
        const ahiTargetActionIds = [4, 5, 6, 10, 11, 12];
        _tableExtras[rule.id] = {
          fullAnalysisSummary: thisCondition?.analysis,
          priority: thisAction?.priority,
          category: thisCategory?.name,
          dataType:
            // If a dataType has only one type tag, then display that type.
            // Otherwise, if it has more than one type tag or no type tags, then show Multi-Source.
            dataTypes?.length && dataTypes?.length === 1
              ? props.language[dataTypes[0].replace("type:", "")]?.display_name
              : "Multi-Source",
          // The default state set by IDEs. MSPs can override that default
          // but we only display _our_ recommendation on the rules page.
          blumiraDefaultState: thisCondition?.tags?.some(
            (t) => t === "status:default_disabled"
          )
            ? "Disabled"
            : "Enabled",
          detectionType:
            thisCondition?.method === 500 ? "Real-Time" : "Windowed",
          allowlist: props.allowlistEntry.filter((f) => f.ruleId === rule.id),
          responseWorkflows:
            thisCondition?.responseWorkflows != undefined &&
            thisCondition?.responseWorkflows.length > 0,
          isAHISupported:
            (thisCondition?.tags?.includes("type:windows") ||
              thisCondition?.tags?.includes("type:agent_os") ||
              thisCondition?.tags?.includes("type:agent_os_mac") ||
              thisCondition?.tags?.includes("type:agent_os_linux")) &&
            thisCondition?.method === 500 &&
            !thisCondition?.tags?.includes("AHI:disabled") &&
            ahiTargetActionIds.includes(thisCondition?.targetAction)
              ? true
              : false,
        };
        return rulesPlus.push(rule);
      });
      Promise.all(promises).then(() => {
        setTableExtras(_tableExtras);
        setRules(rulesPlus);
        if (props.query && props.query.isAHISupported) {
          filterRules("isolation", _tableExtras);
        } else {
          filterRules("enabled", _tableExtras);
        }
        setIsLoading(false);
      });
    } else if (props.ready && !props.rules.length)
      // loading finished and no rules
      setIsLoading(false);
  }, [
    props.ready,
    props.rules,
    props.conditions,
    props.actions,
    props.findingTypes,
    props.allowlistEntry,
    props.query,
  ]);

  useEffect(() => {
    filterRules("enabled", tableExtras);
  }, [props.orgId]);

  const replacePre = (html) => {
    return html.replaceAll("<pre>", "<code>").replaceAll("</pre>", "</code>");
  };

  const stripHTML = (html) => {
    let doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  // TODO use getAnalysis from src/lib/util/RulesUtils
  const getAnalysis = (column, model, getFullSummary) => {
    const maxChars = 256;
    if (tableExtras[model.id]?.fullAnalysisSummary) {
      if (getFullSummary) {
        return replacePre(tableExtras[model.id]?.fullAnalysisSummary);
      } else {
        const stripped = stripHTML(tableExtras[model.id]?.fullAnalysisSummary);
        return stripped.length > maxChars
          ? stripped.substring(0, maxChars).trim() + "..."
          : stripped;
      }
    } else {
      return "";
    }
  };

  const getPriority = (column, model) => {
    const displayInfo = tableExtras[model.id];
    const priority = displayInfo?.priority;
    const color = getPriorityIconColor(displayInfo);
    return (
      <span className={dialogClasses.priorityContainer}>
        <div
          className={dialogClasses.priorityCircle}
          style={{ backgroundColor: color }}
        />
        P{priority}
      </span>
    );
  };

  const getColumns = () => {
    let columns;

    // Show Detection Filters column to anyone not on a Free or Cloud license
    if (
      !props.license.isTagged("limited-to-free") &&
      !props.license.isTagged("limited-to-cloud")
    ) {
      columns = [
        {
          title: "Rule name",
          field: "name",
          css: { width: "20%" },
          searchable: true,
        },
        {
          title: "Category",
          field: "category",
          css: { width: "120px" },
          searchable: true,
          renderValue: (column, model) => tableExtras[model.id]?.category,
        },
        {
          title: "Priority",
          field: "priority",
          css: { width: "120px" },
          searchable: false,
          renderValue: (column, model) => `P${tableExtras[model.id]?.priority}`,
          icon: (column, model) => (
            <FiberManualRecordIcon
              style={{ color: getPriorityIconColor(tableExtras[model.id]) }}
            />
          ),
        },
        {
          title: "Data type",
          field: "dataType",
          css: { width: "160px" },
          searchable: true,
          renderValue: (column, model) => tableExtras[model.id]?.[column.field],
        },
        {
          title: "Detection type",
          field: "detectionType",
          css: { width: "160px" },
          searchable: true,
          renderValue: (column, model) => tableExtras[model.id]?.[column.field],
        },
        {
          title: "Analysis summary",
          field: "fullAnalysisSummary",
          searchable: true,
          renderValue: getAnalysis,
        },
        {
          title: "Filters",
          field: "filters",
          css: { width: "120px" },
          searchable: true,
          renderValue: (column, model) =>
            tableExtras[model.id]?.allowlist.length,
        },
      ];
    } else {
      columns = [
        {
          title: "Rule name",
          field: "name",
          css: { width: "20%" },
          searchable: true,
        },
        {
          title: "Category",
          field: "category",
          css: { width: "120px" },
          searchable: true,
          renderValue: (column, model) => tableExtras[model.id]?.category,
        },
        {
          title: "Priority",
          field: "priority",
          css: { width: "120px" },
          searchable: false,
          renderValue: (column, model) => `P${tableExtras[model.id]?.priority}`,
          icon: (column, model) => (
            <FiberManualRecordIcon
              style={{ color: getPriorityIconColor(tableExtras[model.id]) }}
            />
          ),
        },
        {
          title: "Data Type",
          field: "dataType",
          css: { width: "160px" },
          searchable: true,
          renderValue: (column, model) => tableExtras[model.id]?.[column.field],
        },
        {
          title: "Detection type",
          field: "detectionType",
          css: { width: "160px" },
          searchable: true,
          renderValue: (column, model) => tableExtras[model.id]?.[column.field],
        },
        {
          title: "Analysis summary",
          field: "fullAnalysisSummary",
          searchable: true,
          renderValue: getAnalysis,
        },
      ];
    }
    return columns;
  };

  const filterRules = (rulesFilter, tableExtras) => {
    setShowRulesDropdownVal(rulesFilter);
    let filteredRules = props.rules;

    // Windowed detection rules aren't supported on FREE
    if (props.org[0]?.license === "FREE") {
      filteredRules = filteredRules.filter(
        (r) => tableExtras[r.id]?.detectionType !== "Windowed"
      );
    }

    switch (rulesFilter) {
      case "enabled":
        filteredRules = filteredRules.filter(
          (r) => r.orgId === props.orgId && r.status === 1
        );
        break;
      case "disabled":
        filteredRules = filteredRules.filter(
          (r) => r.orgId === props.orgId && r.status !== 1 && r.status !== 101 // 1=enabled, 101=pending_enabled
        );
        break;
      case "isolation":
        filteredRules = filteredRules.filter(
          (r) =>
            r.orgId === props.orgId &&
            tableExtras[r.id]?.isAHISupported === true
        );
        break;
      case "response_actions":
        filteredRules = filteredRules.filter(
          (r) =>
            r.orgId === props.orgId &&
            tableExtras[r.id]?.responseWorkflows === true
        );
        break;
      default:
        filteredRules = filteredRules.filter((r) => r.orgId === props.orgId);
        break;
    }
    setRules(filteredRules);
  };

  const handleRulesModal = (contextMenu) => {
    //model = contextMenu.model if "View details" is clicked from the context menu
    //model = just contextMenu if triggering directly from row click,
    //which happens when/if current user does not have permissions to enable/disable rule and therefore, no context menu shows
    //since currently, the only 2 things in the menu are enable/disable or "View details"
    const model = contextMenu.model || contextMenu;
    setSelectedRule(model);
    setOpen(true);
  };

  const handleClose = (action) => setOpen(false);

  const handleDisable = (theSelectedRule) => {
    theSelectedRule.set({ status: 0 });
    theSelectedRule.update().then((obj) => {
      obj.set({ status: 0 }, false);
      setSelectedRule(obj);
    });
  };

  const handleEnable = (theSelectedRule) => {
    theSelectedRule.set({ status: 1 });
    theSelectedRule.update().then((obj) => {
      obj.set({ status: 1 }, false);
      setSelectedRule(obj);
    });
  };

  const handleSwitchToggle = (obj) => {
    const rule = obj instanceof rules[0].constructor ? obj : obj.model;
    setSelectedRule(rule);
    setConfirmationOpen(true);
  };

  const handleConfirmation = () => {
    if (isRuleEnabled(selectedRule)) {
      handleDisable(selectedRule);
    } else {
      handleEnable(selectedRule);
    }
    setConfirmationOpen(false);
  };

  const handleTabChange = (event, newTabValue) => {
    setTabValue(newTabValue);
  };

  const getContextMenuItems = () => {
    let viewDetailsItem = {
      onClick: handleRulesModal,
      text: "View details",
      datacy: "rulesContextMenuItemViewDetails",
      modalTabValue: "0",
    };

    let viewFiltersItem = {
      onClick: handleRulesModal,
      text: "Filters",
      datacy: "rulesContextMenuItemFilters",
      modalTabValue: "1",
    };

    let toggleRuleItem = {
      onClick: handleSwitchToggle,
      text: "Enable/Disable",
      datacy: "rulesContextMenuItemEnableDisable",
      detectionRule: true,
    };

    // Show Filters option to anyone not on the Free or Cloud editions and SA users
    if (
      props.superadmin ||
      (!props.license.isTagged("limited-to-cloud") &&
        !props.license.isTagged("limited-to-free"))
    ) {
      return [viewDetailsItem, viewFiltersItem, toggleRuleItem];
    }

    return [viewDetailsItem, toggleRuleItem];
  };

  const displayAhiModalInfo = () => {
    const now = moment().utc().toISOString();

    // If org has a scheduled license value and a Free license
    if (
      props.org[0]?.licenseScheduled !== undefined &&
      props.org[0]?.license === "FREE"
    ) {
      // If the scheduled license is currently active, then allow the AHI details row to show if they have the license feature enabled
      if (
        props.org[0]?.licenseScheduled.end > now &&
        props.org[0]?.licenseScheduled.start < now
      ) {
        return (
          <LicenseRestriction
            features={["AUTOBOT"]}
            renderAllow={(children) => children}
            renderDisallow={() => null}
          >
            <tr>
              <td className={dialogClasses.tableRowLabel}>
                Automated host isolation
              </td>
              <td className={dialogClasses.tableRowText}>
                {tableExtras[selectedRule.id]?.isAHISupported
                  ? "Supported"
                  : "Not Supported"}
                <Link
                  href="https://blumira.help/auto-isolation"
                  target={"_blank"}
                  rel={"noopener noreferrer"}
                  style={{ marginLeft: 10 }}
                >
                  Learn more <OpenInNewIcon />
                </Link>
              </td>
            </tr>
          </LicenseRestriction>
        );
        // If the scheduled license is expired or outside the start and end dates, then don't show the AHI details row
      } else {
        return null;
      }
      // If the org doesn't meet the original conditional (having a scheduled license value and/or a Free license), then only show the AHI details row if the license feature is enabled on the org
    } else {
      return (
        <LicenseRestriction
          features={["AUTOBOT"]}
          renderAllow={(children) => children}
          renderDisallow={() => null}
        >
          <tr>
            <td className={dialogClasses.tableRowLabel}>
              Automated host isolation
            </td>
            <td className={dialogClasses.tableRowText}>
              {tableExtras[selectedRule.id]?.isAHISupported
                ? "Supported"
                : "Not Supported"}
              <Link
                href="https://blumira.help/auto-isolation"
                target={"_blank"}
                rel={"noopener noreferrer"}
                style={{ marginLeft: 10 }}
              >
                Learn more <OpenInNewIcon />
              </Link>
            </td>
          </tr>
        </LicenseRestriction>
      );
    }
  };

  return (
    <Root>
      <ActionDialog
        open={confirmationOpen}
        title={`${
          isRuleEnabled(selectedRule)
            ? "Confirm detection rule change"
            : "Are you sure?"
        }`}
        description={
          isRuleEnabled(selectedRule) ? ( //confirm disable
            <span>Are you sure you want to disable this detection rule?</span>
          ) : //else confirm enable
          selectedRule.defaultState === "Disabled" ? (
            <span>
              Blumira's incident detection team has disabled this detection rule
              by default because it is likely to produce noisy findings.
            </span>
          ) : (
            <span>Are you sure you want to enable this detection rule?</span>
          )
        }
        actions={[
          {
            title: "Cancel",
            action: () => setConfirmationOpen(false),
          },
          {
            title: `Yes, ${
              isRuleEnabled(selectedRule) ? "disable" : "enable"
            } it`,
            action: handleConfirmation,
            variant: "contained",
            datacy: "rulesConfirmChangeBtn",
          },
        ]}
      />
      {/*
        Show modal with multiple tabs (Details & Filters) to a user in any org except those with a Free or Cloud license
        or if they are a Blumira superadmin.
      */}
      {props.superadmin ||
      (!props.license.isTagged("limited-to-free") &&
        !props.license.isTagged("limited-to-cloud")) ? (
        <RulesDialog
          open={open}
          onClose={handleClose}
          maxWidth={"md"}
          fullWidth={true}
        >
          <div className={dialogClasses.dialogHeaderContainer}>
            <DialogTitle>Detection Rule</DialogTitle>
            <IconButton
              title="Close"
              onClick={handleClose}
              datacy="rulesDialogCloseBtn"
            >
              <CloseIcon />
            </IconButton>
          </div>
          <TabContext value={tabValue}>
            <TabList
              onChange={handleTabChange}
              className={dialogClasses.tabs}
              aria-label="Detection rules details and filters"
              indicatorColor="primary"
            >
              <Tab label="Details" value="0" />
              <Tab
                label={`Filters (${
                  tableExtras[selectedRule.id]?.allowlist.length
                })`}
                value="1"
              />
            </TabList>
            <TabPanel value="0" className={dialogClasses.tabPanel}>
              <table className={dialogClasses.dialogTable}>
                <tbody>
                  <tr>
                    <td className={dialogClasses.tableRowLabel}>Name</td>
                    <td className={dialogClasses.tableRowText}>
                      {selectedRule.name}
                    </td>
                  </tr>
                  {tableExtras[selectedRule.id]?.dataType !== "" && (
                    <tr>
                      <td className={dialogClasses.tableRowLabel}>Data type</td>
                      <td className={dialogClasses.tableRowText}>
                        {tableExtras[selectedRule.id]?.dataType}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td className={dialogClasses.tableRowLabel}>Category</td>
                    <td className={dialogClasses.tableRowText}>
                      {tableExtras[selectedRule.id]?.category}
                    </td>
                  </tr>
                  <tr>
                    <td className={dialogClasses.tableRowLabel}>Priority</td>
                    <td className={dialogClasses.tableRowText}>
                      {getPriority("priority", selectedRule)}
                    </td>
                  </tr>
                  <tr>
                    <td className={dialogClasses.tableRowLabel}>
                      Analysis summary
                    </td>
                    <td
                      className={dialogClasses.tableRowText}
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: getAnalysis("summary", selectedRule, true),
                      }}
                    />
                  </tr>
                  {displayAhiModalInfo()}
                  <tr>
                    <td className={dialogClasses.tableRowLabel}>
                      Current state
                    </td>
                    <td className={dialogClasses.tableRowText}>
                      {isRuleEnabled(selectedRule) ? "Enabled" : "Disabled"}
                    </td>
                  </tr>
                  <tr>
                    <td className={dialogClasses.tableRowLabel}>
                      Blumira's default state
                    </td>
                    <td className={dialogClasses.tableRowText}>
                      {tableExtras[selectedRule.id]?.blumiraDefaultState}
                      <span className={dialogClasses.defaultStateDescrip}>
                        {tableExtras[selectedRule.id]?.blumiraDefaultState ===
                        "Enabled"
                          ? "Blumira's incident detection team has determined this rule generates findings that are likely to present a threat so it is enabled by default."
                          : "Blumira's incident detection team has determined this rule generates noisy findings that are not likely to present threats so it is disabled by default."}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </TabPanel>
            <TabPanel value="1" className={dialogClasses.tabPanel}>
              <div className={dialogClasses.filtersContainer}>
                {tableExtras[selectedRule.id]?.allowlist.length > 0 ? (
                  tableExtras[selectedRule.id]?.allowlist.map((model) => (
                    <AllowlistingFilter
                      key={`${model.id}-allowlist`}
                      model={model}
                      fields={model.queryParams}
                      canEdit={false}
                      users={props.users}
                      reload={props.reload}
                      closeModal={handleClose}
                    />
                  ))
                ) : (
                  <div className={dialogClasses.noFilters}>No Filters</div>
                )}
              </div>
              <div className={dialogClasses.buttonWrap}>
                <Link
                  href="https://blumira.help/filters"
                  target={"_blank"}
                  rel={"noopener noreferrer"}
                >
                  Get help <OpenInNewIcon />
                </Link>
              </div>
            </TabPanel>
          </TabContext>
        </RulesDialog>
      ) : (
        <RulesDialog open={open} onClose={handleClose} maxWidth={"md"}>
          <div className={dialogClasses.dialogHeaderContainer}>
            <DialogTitle>Detection rule details</DialogTitle>
            <IconButton
              title="Close"
              onClick={handleClose}
              datacy="rulesDialogCloseBtn"
            >
              <CloseIcon />
            </IconButton>
          </div>
          <DialogContent className={dialogClasses.dialogContent}>
            <table className={dialogClasses.dialogTable}>
              <tbody>
                <tr>
                  <td className={dialogClasses.tableRowLabel}>Name</td>
                  <td className={dialogClasses.tableRowText}>
                    {selectedRule.name}
                  </td>
                </tr>
                {tableExtras[selectedRule.id]?.dataType !== "" && (
                  <tr>
                    <td className={dialogClasses.tableRowLabel}>Data type</td>
                    <td className={dialogClasses.tableRowText}>
                      {tableExtras[selectedRule.id]?.dataType}
                    </td>
                  </tr>
                )}
                <tr>
                  <td className={dialogClasses.tableRowLabel}>Category</td>
                  <td className={dialogClasses.tableRowText}>
                    {tableExtras[selectedRule.id]?.category}
                  </td>
                </tr>
                <tr>
                  <td className={dialogClasses.tableRowLabel}>Priority</td>
                  <td className={dialogClasses.tableRowText}>
                    {getPriority("priority", selectedRule)}
                  </td>
                </tr>
                <tr>
                  <td className={dialogClasses.tableRowLabel}>
                    Analysis summary
                  </td>
                  <td
                    className={dialogClasses.tableRowText}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: getAnalysis("summary", selectedRule, true),
                    }}
                  />
                </tr>
                <tr>
                  <td className={dialogClasses.tableRowLabel}>Current state</td>
                  <td className={dialogClasses.tableRowText}>
                    {isRuleEnabled(selectedRule) ? "Enabled" : "Disabled"}
                  </td>
                </tr>
                <tr>
                  <td className={dialogClasses.tableRowLabel}>
                    Blumira's default state
                  </td>
                  <td className={dialogClasses.tableRowText}>
                    {tableExtras[selectedRule.id]?.blumiraDefaultState}
                    <span className={dialogClasses.defaultStateDescrip}>
                      {tableExtras[selectedRule.id]?.blumiraDefaultState ===
                      "Enabled"
                        ? "Blumira's incident detection team has determined this rule generates findings that are likely to present a threat so it is enabled by default."
                        : "Blumira's incident detection team has determined this rule generates noisy findings that are not likely to present threats so it is disabled by default."}
                    </span>
                  </td>
                </tr>
                {displayAhiModalInfo()}
              </tbody>
            </table>
          </DialogContent>
        </RulesDialog>
      )}
      <SimpleTable
        isNorthStar
        title="Rules"
        isFetching={isLoading || !props.ready}
        columns={getColumns()}
        data={
          // if license is FREE, it's possible user
          // has generated sample data, show 'em
          props.license.isTagged("limited-to-free")
            ? rules
            : rules.filter((r) => !r.name.includes("[SAMPLE]"))
        }
        resultsCount={0}
        rowToggleSwitches={props}
        handleSwitchToggle={handleSwitchToggle}
        handleRulesModal={handleRulesModal}
        setTabValue={setTabValue}
        emptyText={
          <span>
            Nothing to see here.
            <br />
            <br />
            NOTE: Detection rules will appear as soon as Blumira processes your
            incoming log data. This can take up to 60 minutes after you set up
            your first Cloud Connector or Sensor.
          </span>
        }
        // Only show "Filters" menu item to a user if they are currently in an org with an advanced license
        // or if they are a Blumira superadmin.
        contextMenuItems={getContextMenuItems()}
        toolbarActions={[
          {
            isPrimary: true,
            component: (
              <FormControl className={rootClasses.showRulesFormControl}>
                <InputLabel id={"rules-select-label"} shrink={true}>
                  Search preset
                </InputLabel>
                <Select
                  id={"rules-select"}
                  label="Search preset"
                  value={showRulesDropdownVal}
                  datacy={"searchPresetButton"}
                  labelId={"rules-select-label"}
                  className={rootClasses.showRulesSelectItem}
                  onChange={(event) => {
                    filterRules(event.target.value, tableExtras);
                  }}
                >
                  <MenuItem value="all" datacy={"allRulesButton"}>
                    All detection rules
                  </MenuItem>
                  <MenuItem value="enabled">Enabled</MenuItem>
                  <MenuItem value="disabled">Disabled</MenuItem>
                  {props.license.hasFeature("AUTOBOT") && (
                    <MenuItem value="isolation" datacy={"autoBotButton"}>
                      Supports Automated Host Isolation
                    </MenuItem>
                  )}
                  <MenuItem value="response_actions">
                    Supports Response Actions
                  </MenuItem>
                </Select>
              </FormControl>
            ),
          },
        ]}
      />
    </Root>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    reload: (force) => dispatch(loadPageData(force)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const query = get(state, ["location", "query"], {});

  return {
    user: state.session.settings.user,
    orgId: state.page.payload.orgId,
    language: Codex.language.models.bq.type,
    license: state.license,
    superadmin: state.session.settings.user.superadmin,
    query,
  };
};

RulesView.defaultProps = {
  ready: false,
  rules: [],
  conditions: [],
  actions: [],
  findingTypes: [],
  users: [],
  allowlistEntry: [],
  superadmin: false,
  org: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(RulesView);
