import { BaseModel } from "./BaseModel";

// const FieldType = {
//   BOOLEAN: 10,
//   TEXT: 20,
//   UUID: 30,
// };

export class ResponseConnectorType extends BaseModel {
  schema = "/response/connectorType";
  constructor(params) {
    return super(params, "/response/connectorType");
  }
}
