export const freeProductPlacholder = {
  name: "Free",
  bannerText: "Blumira's Free Edition connects up to three Cloud Connectors.",
  productId: null,
  priceId: null,
  listItems: [],
};

export const productKeys = ["Microsoft 365", "Cloud", "Advanced"];

const freeSiemPlanJson = {
  name: "Free SIEM",
  price: {
    text: "",
  },
  description: "",
  licenseValue: "FREE",
  listItems: [],
};

const siemStarterPlanJson = {
  name: "SIEM Starter",
  contactSales: true,
  price: {
    text: "",
  },
  description: "",
  licenseValue: "SIEM_STARTER",
  listItems: [],
};

const siemEndpointPlanJson = {
  contactSales: true,
  name: "SIEM+",
  price: {
    text: "",
  },
  description: "",
  licenseValue: "SIEM_ENDPOINT",
  listItems: [],
};

const xdrPlatformPlanJson = {
  contactSales: true,
  name: "XDR Platform",
  price: {
    text: "",
  },
  description: "",
  licenseValue: "XDR",
  listItems: [],
};

export const availablePlans = [
  freeSiemPlanJson,
  siemStarterPlanJson,
  siemEndpointPlanJson,
  xdrPlatformPlanJson,
];
