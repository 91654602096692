import { createTheme } from "@mui/material/styles";

// Primary Colors
const AZURE = "#3E7EF8";
const CULTURED = "#F4F6F8";
const OXFORD_BLUE = "#0D2962";

// Secondary Colors
const MEDIUM_GREEN = "#3BB273";
const LIGHT_BLUE = "#D8E2F7";
const DARK_GREEN = "#244F26";
const BURNT_SIENNA = "#E76F51";
const OLIVE = "#788475";
const BLUE_MOONSTONE = "#50648A";
const DEEP_FUSCHIA = "#D345B7";

// Base Colors
const JET = "#2E2E2E";
const BODY_GRAY = "#3B3B3B";
const DAVYS_GRAY = "#565656";
const GRAY_WEB = "#7D7E7E";
const SILVER = "#A4A6A7";
const LIGHT_GRAY = "#CCCECF";
const BASE = "#F4F6F8";

const MIRAGE = "#19202B";
const EBONY_CLAY = "#1E2533";
const PERIWINKLE_GRAY = "#CAD4E8";
const LIGHT_BLUE_GRAY = "#ACC6FD";
const DARK_BLUE_GRAY = "#405b93";

const COMET = "#57606F";

// Common
const WHITE = "#FFF";
const BLACK = "#000";

// Cloud Connectors SVGs Colors
const AWS_DARK_TXT = "#252F3E";
const AWS_YELLOW_SMILE = "#FF9900";
const DUO_GREEN_1 = "#7bcd54";
const DUO_GREEN_2 = "#63c43f";
const M365_DARK_TXT = "#737373";
const M365_SQ1 = "#f25022";
const M365_SQ2 = "#7fba00";
const M365_SQ3 = "#00a4ef";
const M365_SQ4 = "#ffb900";
const S1_DARK_TXT = "#040404";
const S1_PURPLE = "#6b0aea";
const MIMECAST_DARK = "#080444";
const WEBROOT_GREEN = "#408740";
const UMBRELLA_BLUE = "#0d274d";
const GOOGLE_GRAY = "#5f6368";
const JUMPCLOUD_BLUE = "#202C38";
const ONELOGIN_BLUE = "#1C1F2A";
const HUB_BLUE = "#2c6dd6";
const ONE_PASS_BLUE = "#1b275e";

// Alert Colors
const CHAMBRAY = "#315290";
const TROPICAL_BLUE = "#CEDDF7";
const JORDY_BLUE = "#97B9F7";
const CERULEAN_BLUE = "#305FB7";
const IRISH_COFFEE = "#5C2C20";
const LINEN = "#FCF0ED";
const CELLO = "#1E3560";
const SUNBEAM = "#FCCC7A";
const BISCOTTI = "#FFF1B4";
const BROWN = "#755722";

const blumiraDefault = {
  palette: {
    common: {
      white: WHITE,
      black: BLACK,
    },
    primary: {
      main: AZURE,
      dark: OXFORD_BLUE,
      light: CULTURED,
    },
    secondary: {
      main: OLIVE,
    },
    error: {
      main: BURNT_SIENNA,
    },
    warning: {
      main: SUNBEAM,
    },
    info: {
      main: LIGHT_BLUE,
      secondary: TROPICAL_BLUE,
    },
    success: {
      main: MEDIUM_GREEN,
      dark: DARK_GREEN,
    },
    superadmin: {
      main: "#4D4183",
      light: "#E7E6F7",
    },
    grey: {
      50: BASE,
      100: LIGHT_GRAY,
      200: LIGHT_GRAY,
      300: LIGHT_GRAY,
      400: SILVER,
      500: GRAY_WEB,
      600: DAVYS_GRAY,
      700: BODY_GRAY,
      800: JET,
      900: BLACK,
    },
    purple: DEEP_FUSCHIA,
    text: {
      primary: BODY_GRAY,
      secondary: GRAY_WEB,
      autoIsoControlBar: LIGHT_BLUE_GRAY,
      multiselectControlBar: BASE,
      disabled: BODY_GRAY,
    },
    divider: "rgba(0, 0, 0, 0.12)",
    background: {
      paper: WHITE,
      default: BASE,
      alert: TROPICAL_BLUE,
      autoIsoControlBar: OXFORD_BLUE,
      multiselectControlBar: AZURE,
    },
    action: {
      toggleButtonHover: LIGHT_GRAY,
      disabled: "rgba(86, 86, 86, 0.7)", // DAVYS_GRAY with a slightly decreased opacity
    },
    border: {
      autoIsoControlBar: DARK_BLUE_GRAY,
      alertBorder: JORDY_BLUE,
      xdrTrialCard: LIGHT_GRAY,
    },
  },
  typography: {
    fontFamily: '"Open Sans", sans-serif',
    h1: {
      fontFamily: '"Prompt", sans-serif',
      fontWeight: "bold",
      fontSize: "60px",
      letterSpacing: "16",
    },
    h2: {
      fontFamily: '"Prompt", sans-serif',
      fontWeight: "bold",
      fontSize: "48px",
      letterSpacing: "16",
    },
    h3: {
      fontFamily: '"Prompt", sans-serif',
      fontWeight: "bold",
      fontSize: "40px",
      letterSpacing: "16",
    },
    h4: {
      fontFamily: '"Prompt", sans-serif',
      fontWeight: "bold",
      fontSize: "30px",
      letterSpacing: "16",
    },
    h5: {
      fontFamily: '"Prompt", sans-serif',
      fontWeight: "bold",
      fontSize: "24px",
      letterSpacing: "16",
    },
    h6: {
      fontFamily: '"Prompt", sans-serif',
      fontWeight: "bold",
      fontSize: "18px",
      letterSpacing: "16",
    },
  },
  shape: {
    padding: 15,
    borderRadius: 10,
  },
  components: {
    MuiInputLabel: {
      shrink: true,
    },
    MuiInput: {
      styleOverrides: {
        root: {
          borderRadius: 10,
          padding: "5px 10px",
          backgroundColor: WHITE,
          border: `1px solid lightgray`,
          "& svg": {
            color: "lightgray",
          },
          "&.Mui-focused": {
            borderColor: AZURE,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 13,
          color: BODY_GRAY,
          backgroundColor: WHITE,
          boxShadow: "3px 3px 5px rgb(0, 0, 0, 0.25)",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: AZURE,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            borderColor: "rgba(154, 177, 219, 0.4)",
            color: "rgba(86, 86, 86, 0.7)", // DAVYS_GRAY with a slightly decreased opacity
            cursor: "not-allowed",
          },
        },
        input: {
          "&.Mui-disabled": {
            cursor: "not-allowed",
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color: "rgba(86, 86, 86, 0.7)",
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          paddingLeft: 7,
          "&.Mui-disabled": {
            color: "rgba(86, 86, 86, 0.7)",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        contained: {
          color: WHITE,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        input: {
          cursor: "pointer",
        },
        inputRoot: {
          cursor: "pointer",
          "&.MuiInput-root": {
            minHeight: "44px",
            paddingTop: "2px",
            paddingBottom: "2px",
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          "& svg.MuiChip-deleteIcon": {
            color: BLACK,
            opacity: 0.3,

            "&:hover": {
              opacity: 0.7,
            },
          },

          "& svg.MuiChip-deleteIcon.MuiChip-deleteIconColorPrimary": {
            color: WHITE,
            opacity: 0.6,

            "&:hover": {
              opacity: 1,
            },
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardInfo: {
          color: CHAMBRAY,
          borderColor: JORDY_BLUE,
          borderStyle: "solid",
          borderWidth: 1,
          backgroundColor: TROPICAL_BLUE,
          marginBottom: 20,

          "& .MuiAlert-icon": {
            color: CHAMBRAY,
          },
          "& a": {
            color: CHAMBRAY,
            textDecoration: "underline",
          },
        },
        standardError: {
          color: IRISH_COFFEE,
          backgroundColor: LINEN,

          "& .MuiAlert-icon": {
            color: IRISH_COFFEE,
          },
        },
        standardWarning: {
          color: BROWN,
          backgroundColor: BISCOTTI,
          borderColor: SUNBEAM,
          borderStyle: "solid",
          borderWidth: 1,
          "& p": {
            marginBottom: 0,
            color: BODY_GRAY,
          },
          "& .MuiAlert-icon": {
            color: BROWN,
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        colorSecondary: {
          "&.Mui-checked": {
            color: AZURE,
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          padding: 15,
          backgroundColor: WHITE,
          "&.Mui-disabled": {
            backgroundColor: WHITE,
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          border: `1px solid ${LIGHT_GRAY}`,
          color: BLACK,
          "&.Mui-selected": {
            backgroundColor: LIGHT_GRAY,
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 5,
        },
        bar: {
          borderRadius: 5,
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        select: {
          "&:focus": {
            border: `2px solid ${AZURE}`,
            backgroundColor: BASE,
            borderRadius: 2,
          },
        },
        selectLabel: {
          marginBottom: 0,
        },
        displayedRows: {
          marginBottom: 0,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          "&.Mui-disabled": {
            color: "rgba(86, 86, 86, 0.7)",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: BODY_GRAY,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: WHITE,
        },
        notchedOutline: {
          borderColor: "lightgray",
        },
      },
    },
  },
  svgs: {
    aws: {
      txt: {
        color: AWS_DARK_TXT,
      },
      smile: {
        color: AWS_YELLOW_SMILE,
      },
    },
    duo: {
      green1: {
        color: DUO_GREEN_1,
      },
      green2: {
        color: DUO_GREEN_2,
      },
    },
    m365: {
      txt: {
        color: M365_DARK_TXT,
      },
      sq1: {
        color: M365_SQ1,
      },
      sq2: {
        color: M365_SQ2,
      },
      sq3: {
        color: M365_SQ3,
      },
      sq4: {
        color: M365_SQ4,
      },
    },
    azHub: {
      txt: {
        color: HUB_BLUE,
      },
    },
    sentinel: {
      txt: {
        color: S1_DARK_TXT,
      },
      logo: {
        color: S1_PURPLE,
      },
    },
    mimecast: {
      txt: {
        color: MIMECAST_DARK,
      },
    },
    webroot: {
      txt: {
        color: WEBROOT_GREEN,
      },
    },
    umbrella: {
      color: UMBRELLA_BLUE,
    },
    google: {
      txt: {
        color: GOOGLE_GRAY,
      },
    },
    platform: {
      color: BODY_GRAY,
    },
    carbonBlack: {
      txt: {
        color: BLACK,
      },
    },
    jumpCloud: {
      txt: {
        color: JUMPCLOUD_BLUE,
      },
    },
    oneLogin: {
      txt: {
        color: ONELOGIN_BLUE,
      },
    },
    onePass: {
      txt: {
        color: ONE_PASS_BLUE,
      },
    },
    msCloud: {
      txt: {
        color: M365_DARK_TXT,
      },
    },
    connectWise: {
      txt: {
        color: BLACK,
      },
    },
  },
};

const blumiraDark = {
  ...blumiraDefault,
  palette: {
    ...blumiraDefault["palette"],
    text: {
      primary: PERIWINKLE_GRAY,
      secondary: LIGHT_GRAY,
      autoIsoControlBar: BASE,
      multiselectControlBar: LIGHT_BLUE_GRAY,
      disabled: PERIWINKLE_GRAY,
    },
    divider: "rgba(255, 255, 255, 0.12)",
    background: {
      paper: MIRAGE,
      default: EBONY_CLAY,
      autoIsoControlBar: AZURE,
      multiselectControlBar: OXFORD_BLUE,
      alert: CELLO,
    },
    border: {
      autoIsoControlBar: LIGHT_BLUE_GRAY,
      xdrTrialCard: PERIWINKLE_GRAY,
    },
    action: {
      active: BLUE_MOONSTONE,
      hover: "rgba(154, 177, 219, 0.08)",
      hoverOpacity: "0.08",
      selected: "rgba(154, 177, 219, 0.16)",
      selectedOpacity: "0.16",
      disabled: "rgba(154, 177, 219, 0.7)", // #9ab1db with a slightly decreased opacity
      disabledBackground: "rgba(154, 177, 219, 0.12)",
      disabledOpacity: "0.38",
      focus: "rgba(154, 177, 219, 0.12)",
      focusOpacity: "0.12",
      activatedOpacity: "0.24",
      toggleButtonHover: BLUE_MOONSTONE,
    },
  },
  components: {
    ...blumiraDefault["components"],
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: OXFORD_BLUE,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 13,
          color: PERIWINKLE_GRAY,
          backgroundColor: MIRAGE,
          boxShadow: "3px 3px 5px rgb(0, 0, 0, 0.25)",
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          ...blumiraDefault["components"]["MuiInput"]["styleOverrides"]["root"],
          backgroundColor: MIRAGE,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            borderColor: "rgba(154, 177, 219, 0.4)",
            color: "rgba(154, 177, 219, 0.7)", // #9ab1db with a slightly decreased opacity
            cursor: "not-allowed",
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color: "rgba(154, 177, 219, 0.7)",
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          paddingLeft: 7,
          "&.Mui-disabled": {
            color: "rgba(154, 177, 219, 0.7)",
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardInfo: {
          ...blumiraDefault["components"]["MuiAlert"]["styleOverrides"][
            "standardInfo"
          ],
          color: WHITE,
          borderColor: CERULEAN_BLUE,
          backgroundColor: CELLO,

          "& .MuiAlert-icon": {
            color: WHITE,
          },
          "& a": {
            color: WHITE,
            textDecoration: "underline",
          },
        },
        standardWarning: {
          ...blumiraDefault["components"]["MuiAlert"]["styleOverrides"][
            "standardWarning"
          ],
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        track: {
          backgroundColor: COMET,
          opacity: "1",
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          ...blumiraDefault["components"]["MuiAccordion"]["styleOverrides"][
            "root"
          ],
          backgroundColor: MIRAGE,
          "&.Mui-disabled": {
            backgroundColor: MIRAGE,
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          border: `1px solid ${BLUE_MOONSTONE}`,
          color: WHITE,
          "&.Mui-selected": {
            backgroundColor: BLUE_MOONSTONE,
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        select: {
          "&:focus": {
            border: `2px solid ${AZURE}`,
            backgroundColor: EBONY_CLAY,
            borderRadius: 2,
          },
        },
        selectLabel: {
          ...blumiraDefault["components"]["MuiTablePagination"][
            "styleOverrides"
          ]["selectLabel"],
        },
        displayedRows: {
          ...blumiraDefault["components"]["MuiTablePagination"][
            "styleOverrides"
          ]["displayedRows"],
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          "&.Mui-disabled": {
            color: "rgba(154, 177, 219, 0.7)",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          ...blumiraDefault["components"]["MuiButton"]["styleOverrides"][
            "root"
          ],
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: PERIWINKLE_GRAY,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: MIRAGE,
        },
        notchedOutline: {
          ...blumiraDefault["components"]["MuiOutlinedInput"]["styleOverrides"][
            "notchedOutline"
          ],
        },
      },
    },
  },
  svgs: {
    aws: {
      ...blumiraDefault["svgs"]["aws"],
      txt: {
        color: WHITE,
      },
    },
    duo: {
      ...blumiraDefault["svgs"]["duo"],
    },
    m365: {
      ...blumiraDefault["svgs"]["m365"],
      txt: {
        color: WHITE,
      },
    },
    azHub: {
      txt: {
        color: WHITE,
      },
    },
    sentinel: {
      ...blumiraDefault["svgs"]["sentinel"],
      txt: {
        color: WHITE,
      },
    },
    mimecast: {
      txt: {
        color: WHITE,
      },
    },
    webroot: {
      txt: {
        color: WHITE,
      },
    },
    umbrella: {
      color: WHITE,
    },
    google: {
      txt: {
        color: WHITE,
      },
    },
    platform: {
      color: PERIWINKLE_GRAY,
    },
    carbonBlack: {
      txt: {
        color: WHITE,
      },
    },
    jumpCloud: {
      txt: {
        color: WHITE,
      },
    },
    oneLogin: {
      txt: {
        color: WHITE,
      },
    },
    onePass: {
      txt: {
        color: WHITE,
      },
    },
    msCloud: {
      txt: {
        color: WHITE,
      },
    },
    connectWise: {
      txt: {
        color: WHITE,
      },
    },
  },
};

export const defaultTheme = createTheme(blumiraDefault);
export const shadowTheme = createTheme(blumiraDark);
